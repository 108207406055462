import React from "react";
import DegreeCard from "../../components/degreeCard/DegreeCard.js";
import { degrees } from "../../Models/education";
import { Fade } from "react-reveal";
import "./Educations.css";

function Educations({ theme }) {

    return (
        <div className="main" id="educations">
            <div>
                <Fade bottom duration={2000} distance="20px">
                    <h1 className="educations-header" style={{ color: theme.text }}>
                        Degrees Received
                    </h1>
                </Fade>
            </div>
            <div className="educations-body-div">
                {degrees.degrees.map((degree, index) => <DegreeCard degree={degree} theme={theme} key={index} />)}
            </div>
        </div>
    );
}

export default Educations;
