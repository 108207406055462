import React from "react";

export default function CMSImg() {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="animated"
      version="1.1"
      viewBox="0 0 500 500"
    >
      <g
        className="animable"
        style={{
          WebkitTransformOrigin: 242.551,
          MsTransformOrigin: 242.551,
          transformOrigin: 242.551,
        }}
      >
        <path
          d="M271.69 100.81a2.9 2.9 0 011.28-.15h.43l-.14.14c0-.5.08-.85.14-.85s.12.35.14.85v.13H273a2.9 2.9 0 01-1.31-.12z"
          style={{
            WebkitTransformOrigin: 272.615,
            MsTransformOrigin: 272.615,
            transformOrigin: 272.615,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M268.26 100.81c0-.08.38-.15.86-.15s.85.07.85.15-.38.15-.85.15-.86-.07-.86-.15z"
          style={{
            WebkitTransformOrigin: 269.115,
            MsTransformOrigin: 269.115,
            transformOrigin: 269.115,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M264.83 100.81c0-.08.39-.15.86-.15s.86.07.86.15-.39.15-.86.15-.86-.07-.86-.15z"
          style={{
            WebkitTransformOrigin: 265.69,
            MsTransformOrigin: 265.69,
            transformOrigin: 265.69,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M261.41 100.81c0-.08.38-.15.85-.15s.86.07.86.15-.38.15-.86.15-.85-.07-.85-.15z"
          style={{
            WebkitTransformOrigin: 262.265,
            MsTransformOrigin: 262.265,
            transformOrigin: 262.265,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M258 100.81c0-.08.38-.15.85-.15s.86.07.86.15-.38.15-.86.15-.85-.07-.85-.15z"
          style={{
            WebkitTransformOrigin: 258.855,
            MsTransformOrigin: 258.855,
            transformOrigin: 258.855,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M254.55 100.81c0-.08.38-.15.86-.15s.85.07.85.15-.38.15-.85.15-.86-.07-.86-.15z"
          style={{
            WebkitTransformOrigin: 255.405,
            MsTransformOrigin: 255.405,
            transformOrigin: 255.405,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M251.12 100.81c0-.08.39-.15.86-.15s.86.07.86.15-.39.15-.86.15-.86-.07-.86-.15z"
          style={{
            WebkitTransformOrigin: 251.98,
            MsTransformOrigin: 251.98,
            transformOrigin: 251.98,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M247.7 100.81c0-.08.38-.15.85-.15s.86.07.86.15-.38.15-.86.15-.85-.07-.85-.15z"
          style={{
            WebkitTransformOrigin: 248.555,
            MsTransformOrigin: 248.555,
            transformOrigin: 248.555,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M244.27 100.81c0-.08.38-.15.86-.15s.85.07.85.15-.38.15-.85.15-.86-.07-.86-.15z"
          style={{
            WebkitTransformOrigin: 245.125,
            MsTransformOrigin: 245.125,
            transformOrigin: 245.125,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M240.84 100.81c0-.08.39-.15.86-.15s.86.07.86.15-.39.15-.86.15-.86-.07-.86-.15z"
          style={{
            WebkitTransformOrigin: 241.7,
            MsTransformOrigin: 241.7,
            transformOrigin: 241.7,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M237.42 100.81c0-.08.38-.15.85-.15s.86.07.86.15-.38.15-.86.15-.85-.07-.85-.15z"
          style={{
            WebkitTransformOrigin: 238.275,
            MsTransformOrigin: 238.275,
            transformOrigin: 238.275,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M234 100.81c0-.08.38-.15.86-.15s.85.07.85.15-.38.15-.85.15-.86-.07-.86-.15z"
          style={{
            WebkitTransformOrigin: 234.855,
            MsTransformOrigin: 234.855,
            transformOrigin: 234.855,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M230.56 100.81c0-.08.39-.15.86-.15s.86.07.86.15-.39.15-.86.15-.86-.07-.86-.15z"
          style={{
            WebkitTransformOrigin: 231.42,
            MsTransformOrigin: 231.42,
            transformOrigin: 231.42,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M227.13 100.81c0-.08.39-.15.86-.15s.86.07.86.15-.39.15-.86.15-.86-.07-.86-.15z"
          style={{
            WebkitTransformOrigin: 227.99,
            MsTransformOrigin: 227.99,
            transformOrigin: 227.99,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M223.71 100.81c0-.08.38-.15.85-.15s.86.07.86.15-.38.15-.86.15-.85-.07-.85-.15z"
          style={{
            WebkitTransformOrigin: 224.565,
            MsTransformOrigin: 224.565,
            transformOrigin: 224.565,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M220.28 100.81c0-.08.38-.15.86-.15s.85.07.85.15-.38.15-.85.15-.86-.07-.86-.15z"
          style={{
            WebkitTransformOrigin: 221.135,
            MsTransformOrigin: 221.135,
            transformOrigin: 221.135,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M216.85 100.81c0-.08.39-.15.86-.15s.86.07.86.15-.39.15-.86.15-.86-.07-.86-.15z"
          style={{
            WebkitTransformOrigin: 217.71,
            MsTransformOrigin: 217.71,
            transformOrigin: 217.71,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M213.43 100.81c0-.08.38-.15.85-.15s.86.07.86.15-.38.15-.86.15-.85-.07-.85-.15z"
          style={{
            WebkitTransformOrigin: 214.285,
            MsTransformOrigin: 214.285,
            transformOrigin: 214.285,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M212.36 98.45c.08 0 .15.38.15.86s-.07.85-.15.85-.15-.38-.15-.85.07-.86.15-.86z"
          style={{
            WebkitTransformOrigin: 212.36,
            MsTransformOrigin: 212.36,
            transformOrigin: 212.36,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M212.36 95c.08 0 .15.39.15.86s-.07.86-.15.86-.15-.39-.15-.86.07-.86.15-.86z"
          style={{
            WebkitTransformOrigin: 212.36,
            MsTransformOrigin: 212.36,
            transformOrigin: 212.36,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M212.36 91.6c.08 0 .15.38.15.85s-.07.86-.15.86-.15-.38-.15-.86.07-.85.15-.85z"
          style={{
            WebkitTransformOrigin: 212.36,
            MsTransformOrigin: 212.36,
            transformOrigin: 212.36,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M212.36 88.17c.08 0 .15.38.15.86s-.07.85-.15.85-.15-.38-.15-.85.07-.86.15-.86z"
          style={{
            WebkitTransformOrigin: 212.36,
            MsTransformOrigin: 212.36,
            transformOrigin: 212.36,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M212.36 84.74c.08 0 .15.39.15.86s-.07.86-.15.86-.15-.39-.15-.86.07-.86.15-.86z"
          style={{
            WebkitTransformOrigin: 212.36,
            MsTransformOrigin: 212.36,
            transformOrigin: 212.36,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M212.36 81.31c.08 0 .15.39.15.86s-.07.86-.15.86-.15-.39-.15-.86.07-.86.15-.86z"
          style={{
            WebkitTransformOrigin: 212.36,
            MsTransformOrigin: 212.36,
            transformOrigin: 212.36,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M212.36 77.89c.08 0 .15.38.15.85s-.07.86-.15.86-.15-.38-.15-.86.07-.85.15-.85z"
          style={{
            WebkitTransformOrigin: 212.36,
            MsTransformOrigin: 212.36,
            transformOrigin: 212.36,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M212.36 74.46c.08 0 .15.38.15.86s-.07.85-.15.85-.15-.38-.15-.85.07-.86.15-.86z"
          style={{
            WebkitTransformOrigin: 212.36,
            MsTransformOrigin: 212.36,
            transformOrigin: 212.36,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M212.36 71c.08 0 .15.39.15.86s-.07.86-.15.86-.15-.39-.15-.86.07-.86.15-.86z"
          style={{
            WebkitTransformOrigin: 212.36,
            MsTransformOrigin: 212.36,
            transformOrigin: 212.36,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M212.36 67.61c.08 0 .15.38.15.85s-.07.86-.15.86-.15-.38-.15-.86.07-.85.15-.85z"
          style={{
            WebkitTransformOrigin: 212.36,
            MsTransformOrigin: 212.36,
            transformOrigin: 212.36,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M212.36 64.18c.08 0 .15.38.15.86s-.07.85-.15.85-.15-.38-.15-.85.07-.86.15-.86z"
          style={{
            WebkitTransformOrigin: 212.36,
            MsTransformOrigin: 212.36,
            transformOrigin: 212.36,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M212.36 60.75c.08 0 .15.39.15.86s-.07.86-.15.86-.15-.39-.15-.86.07-.86.15-.86z"
          style={{
            WebkitTransformOrigin: 212.36,
            MsTransformOrigin: 212.36,
            transformOrigin: 212.36,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M212.36 57.33c.08 0 .15.38.15.85s-.07.86-.15.86-.15-.38-.15-.86.07-.85.15-.85z"
          style={{
            WebkitTransformOrigin: 212.36,
            MsTransformOrigin: 212.36,
            transformOrigin: 212.36,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M212.36 53.9c.08 0 .15.38.15.86s-.07.85-.15.85-.15-.38-.15-.85.07-.86.15-.86z"
          style={{
            WebkitTransformOrigin: 212.36,
            MsTransformOrigin: 212.36,
            transformOrigin: 212.36,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M212.36 50.47c.08 0 .15.38.15.86s-.07.85-.15.85-.15-.38-.15-.85.07-.86.15-.86z"
          style={{
            WebkitTransformOrigin: 212.36,
            MsTransformOrigin: 212.36,
            transformOrigin: 212.36,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M212.36 47c.08 0 .15.39.15.86s-.07.86-.15.86-.15-.39-.15-.86.07-.86.15-.86z"
          style={{
            WebkitTransformOrigin: 212.36,
            MsTransformOrigin: 212.36,
            transformOrigin: 212.36,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M212.36 43.62c.08 0 .15.38.15.85s-.07.86-.15.86-.15-.38-.15-.86.07-.85.15-.85z"
          style={{
            WebkitTransformOrigin: 212.36,
            MsTransformOrigin: 212.36,
            transformOrigin: 212.36,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M212.36 40.19c.08 0 .15.38.15.86s-.07.85-.15.85-.15-.38-.15-.85.07-.86.15-.86z"
          style={{
            WebkitTransformOrigin: 212.36,
            MsTransformOrigin: 212.36,
            transformOrigin: 212.36,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M215.36 39.76c0 .09-.38.15-.86.15s-.85-.06-.85-.15.38-.14.85-.14.86.06.86.14z"
          style={{
            WebkitTransformOrigin: 214.505,
            MsTransformOrigin: 214.505,
            transformOrigin: 214.505,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M218.79 39.76c0 .09-.39.15-.86.15s-.86-.06-.86-.15.39-.14.86-.14.86.06.86.14z"
          style={{
            WebkitTransformOrigin: 217.93,
            MsTransformOrigin: 217.93,
            transformOrigin: 217.93,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M222.21 39.76c0 .09-.38.15-.85.15s-.86-.06-.86-.15.38-.14.86-.14.85.06.85.14z"
          style={{
            WebkitTransformOrigin: 221.355,
            MsTransformOrigin: 221.355,
            transformOrigin: 221.355,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M225.64 39.76c0 .09-.38.15-.86.15s-.85-.06-.85-.15.38-.14.85-.14.86.06.86.14z"
          style={{
            WebkitTransformOrigin: 224.785,
            MsTransformOrigin: 224.785,
            transformOrigin: 224.785,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M229.07 39.76c0 .09-.39.15-.86.15s-.86-.06-.86-.15.39-.14.86-.14.86.06.86.14z"
          style={{
            WebkitTransformOrigin: 228.21,
            MsTransformOrigin: 228.21,
            transformOrigin: 228.21,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M232.49 39.76c0 .09-.38.15-.85.15s-.86-.06-.86-.15.38-.14.86-.14.85.06.85.14z"
          style={{
            WebkitTransformOrigin: 231.635,
            MsTransformOrigin: 231.635,
            transformOrigin: 231.635,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M235.92 39.76c0 .09-.38.15-.86.15s-.85-.06-.85-.15.38-.14.85-.14.86.06.86.14z"
          style={{
            WebkitTransformOrigin: 235.065,
            MsTransformOrigin: 235.065,
            transformOrigin: 235.065,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M239.35 39.76c0 .09-.39.15-.86.15s-.86-.06-.86-.15.39-.14.86-.14.86.06.86.14z"
          style={{
            WebkitTransformOrigin: 238.49,
            MsTransformOrigin: 238.49,
            transformOrigin: 238.49,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M242.78 39.76c0 .09-.39.15-.86.15s-.86-.06-.86-.15.39-.14.86-.14.86.06.86.14z"
          style={{
            WebkitTransformOrigin: 241.92,
            MsTransformOrigin: 241.92,
            transformOrigin: 241.92,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M246.2 39.76c0 .09-.38.15-.85.15s-.86-.06-.86-.15.38-.14.86-.14.85.06.85.14z"
          style={{
            WebkitTransformOrigin: 245.345,
            MsTransformOrigin: 245.345,
            transformOrigin: 245.345,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M249.63 39.76c0 .09-.38.15-.86.15s-.85-.06-.85-.15.38-.14.85-.14.86.06.86.14z"
          style={{
            WebkitTransformOrigin: 248.775,
            MsTransformOrigin: 248.775,
            transformOrigin: 248.775,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M253.06 39.76c0 .09-.39.15-.86.15s-.86-.06-.86-.15.39-.14.86-.14.86.06.86.14z"
          style={{
            WebkitTransformOrigin: 252.2,
            MsTransformOrigin: 252.2,
            transformOrigin: 252.2,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M256.48 39.76c0 .09-.38.15-.85.15s-.86-.06-.86-.15.38-.14.86-.14.85.06.85.14z"
          style={{
            WebkitTransformOrigin: 255.625,
            MsTransformOrigin: 255.625,
            transformOrigin: 255.625,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M259.91 39.76c0 .09-.38.15-.86.15s-.85-.06-.85-.15.38-.14.85-.14.86.06.86.14z"
          style={{
            WebkitTransformOrigin: 259.055,
            MsTransformOrigin: 259.055,
            transformOrigin: 259.055,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M263.34 39.76c0 .09-.39.15-.86.15s-.86-.06-.86-.15.39-.14.86-.14.86.06.86.14z"
          style={{
            WebkitTransformOrigin: 262.48,
            MsTransformOrigin: 262.48,
            transformOrigin: 262.48,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M266.76 39.76c0 .09-.38.15-.85.15s-.86-.06-.86-.15.38-.14.86-.14.85.06.85.14z"
          style={{
            WebkitTransformOrigin: 265.905,
            MsTransformOrigin: 265.905,
            transformOrigin: 265.905,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M270.19 39.76c0 .09-.38.15-.86.15s-.85-.06-.85-.15.38-.14.85-.14.86.06.86.14z"
          style={{
            WebkitTransformOrigin: 269.335,
            MsTransformOrigin: 269.335,
            transformOrigin: 269.335,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M273.4 40s-.07-.08-.1-.22l.1.1a3.52 3.52 0 01-.64 0c-.47 0-.86-.06-.86-.15s.39-.14.86-.14a3.52 3.52 0 01.64.05h.11v.07c-.04.19-.07.29-.11.29z"
          style={{
            WebkitTransformOrigin: 272.705,
            MsTransformOrigin: 272.705,
            transformOrigin: 272.705,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M273.4 43.41c-.08 0-.15-.38-.15-.86s.07-.85.15-.85.15.38.15.85-.07.86-.15.86z"
          style={{
            WebkitTransformOrigin: 273.4,
            MsTransformOrigin: 273.4,
            transformOrigin: 273.4,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M273.4 46.84c-.08 0-.15-.39-.15-.86s.07-.86.15-.86.15.39.15.86-.07.86-.15.86z"
          style={{
            WebkitTransformOrigin: 273.4,
            MsTransformOrigin: 273.4,
            transformOrigin: 273.4,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M273.4 50.26c-.08 0-.15-.38-.15-.85s.07-.86.15-.86.15.38.15.86-.07.85-.15.85z"
          style={{
            WebkitTransformOrigin: 273.4,
            MsTransformOrigin: 273.4,
            transformOrigin: 273.4,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M273.4 53.69c-.08 0-.15-.38-.15-.86s.07-.85.15-.85.15.38.15.85-.07.86-.15.86z"
          style={{
            WebkitTransformOrigin: 273.4,
            MsTransformOrigin: 273.4,
            transformOrigin: 273.4,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M273.4 57.12c-.08 0-.15-.38-.15-.86s.07-.85.15-.85.15.38.15.85-.07.86-.15.86z"
          style={{
            WebkitTransformOrigin: 273.4,
            MsTransformOrigin: 273.4,
            transformOrigin: 273.4,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M273.4 60.55c-.08 0-.15-.39-.15-.86s.07-.86.15-.86.15.39.15.86-.07.86-.15.86z"
          style={{
            WebkitTransformOrigin: 273.4,
            MsTransformOrigin: 273.4,
            transformOrigin: 273.4,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M273.4 64c-.08 0-.15-.38-.15-.85s.07-.86.15-.86.15.38.15.86-.07.85-.15.85z"
          style={{
            WebkitTransformOrigin: 273.4,
            MsTransformOrigin: 273.4,
            transformOrigin: 273.4,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <ellipse
          cx="273.4"
          cy="66.54"
          fill="#E0E0E0"
          rx="0.15"
          ry="0.86"
          style={{
            WebkitTransformOrigin: 273.4,
            MsTransformOrigin: 273.4,
            transformOrigin: 273.4,
          }}
          className="animable"
        ></ellipse>
        <path
          d="M273.4 70.83c-.08 0-.15-.39-.15-.86s.07-.86.15-.86.15.39.15.86-.07.86-.15.86z"
          style={{
            WebkitTransformOrigin: 273.4,
            MsTransformOrigin: 273.4,
            transformOrigin: 273.4,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M273.4 74.25c-.08 0-.15-.38-.15-.85s.07-.86.15-.86.15.38.15.86-.07.85-.15.85z"
          style={{
            WebkitTransformOrigin: 273.4,
            MsTransformOrigin: 273.4,
            transformOrigin: 273.4,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <ellipse
          cx="273.4"
          cy="76.82"
          fill="#E0E0E0"
          rx="0.15"
          ry="0.86"
          style={{
            WebkitTransformOrigin: 273.4,
            MsTransformOrigin: 273.4,
            transformOrigin: 273.4,
          }}
          className="animable"
        ></ellipse>
        <path
          d="M273.4 81.11c-.08 0-.15-.39-.15-.86s.07-.86.15-.86.15.39.15.86-.07.86-.15.86z"
          style={{
            WebkitTransformOrigin: 273.4,
            MsTransformOrigin: 273.4,
            transformOrigin: 273.4,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M273.4 84.53c-.08 0-.15-.38-.15-.85s.07-.86.15-.86.15.38.15.86-.07.85-.15.85z"
          style={{
            WebkitTransformOrigin: 273.4,
            MsTransformOrigin: 273.4,
            transformOrigin: 273.4,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M273.4 88c-.08 0-.15-.38-.15-.85s.07-.86.15-.86.15.38.15.86-.07.85-.15.85z"
          style={{
            WebkitTransformOrigin: 273.4,
            MsTransformOrigin: 273.4,
            transformOrigin: 273.4,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M273.4 91.39c-.08 0-.15-.38-.15-.86s.07-.85.15-.85.15.38.15.85-.07.86-.15.86z"
          style={{
            WebkitTransformOrigin: 273.4,
            MsTransformOrigin: 273.4,
            transformOrigin: 273.4,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <ellipse
          cx="273.4"
          cy="93.96"
          fill="#E0E0E0"
          rx="0.15"
          ry="0.86"
          style={{
            WebkitTransformOrigin: 273.4,
            MsTransformOrigin: 273.4,
            transformOrigin: 273.4,
          }}
          className="animable"
        ></ellipse>
        <path
          d="M273.4 98.24c-.08 0-.15-.38-.15-.85s.07-.86.15-.86.15.38.15.86-.07.85-.15.85z"
          style={{
            WebkitTransformOrigin: 273.4,
            MsTransformOrigin: 273.4,
            transformOrigin: 273.4,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 273.4,
            MsTransformOrigin: 273.4,
            transformOrigin: 273.4,
          }}
          fill="#E0E0E0"
          d="M271.14 97.93H275.65999999999997V102.45H271.14z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 212.36,
            MsTransformOrigin: 212.36,
            transformOrigin: 212.36,
          }}
          fill="#E0E0E0"
          d="M210.1 98.55H214.62V103.07H210.1z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 273.4,
            MsTransformOrigin: 273.4,
            transformOrigin: 273.4,
          }}
          fill="#E0E0E0"
          d="M271.14 37.06H275.65999999999997V41.58H271.14z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 212.36,
            MsTransformOrigin: 212.36,
            transformOrigin: 212.36,
          }}
          fill="#E0E0E0"
          d="M210.1 37.5H214.62V42.019999999999996H210.1z"
          className="animable"
        ></path>
        <path
          d="M156.58 466.67c0-.15.69-.27 1.55-.27s1.56.12 1.56.27-.69.27-1.56.27-1.55-.12-1.55-.27z"
          style={{
            WebkitTransformOrigin: 158.135,
            MsTransformOrigin: 158.135,
            transformOrigin: 158.135,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M150.34 466.67c0-.15.7-.27 1.56-.27s1.56.12 1.56.27-.7.27-1.56.27-1.56-.12-1.56-.27z"
          style={{
            WebkitTransformOrigin: 151.9,
            MsTransformOrigin: 151.9,
            transformOrigin: 151.9,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M144.1 466.67c0-.15.7-.27 1.56-.27s1.56.12 1.56.27-.7.27-1.56.27-1.56-.12-1.56-.27z"
          style={{
            WebkitTransformOrigin: 145.66,
            MsTransformOrigin: 145.66,
            transformOrigin: 145.66,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M137.87 466.67c0-.15.7-.27 1.56-.27s1.56.12 1.56.27-.7.27-1.56.27-1.56-.12-1.56-.27z"
          style={{
            WebkitTransformOrigin: 139.43,
            MsTransformOrigin: 139.43,
            transformOrigin: 139.43,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M131.63 466.67c0-.15.7-.27 1.56-.27s1.56.12 1.56.27-.7.27-1.56.27-1.56-.12-1.56-.27z"
          style={{
            WebkitTransformOrigin: 133.19,
            MsTransformOrigin: 133.19,
            transformOrigin: 133.19,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M125.4 466.67c0-.15.7-.27 1.56-.27s1.56.12 1.56.27-.7.27-1.56.27-1.56-.12-1.56-.27z"
          style={{
            WebkitTransformOrigin: 126.96,
            MsTransformOrigin: 126.96,
            transformOrigin: 126.96,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M119.16 466.67c0-.15.7-.27 1.56-.27s1.56.12 1.56.27-.7.27-1.56.27-1.56-.12-1.56-.27z"
          style={{
            WebkitTransformOrigin: 120.72,
            MsTransformOrigin: 120.72,
            transformOrigin: 120.72,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M112.93 466.67c0-.15.69-.27 1.55-.27s1.56.12 1.56.27-.69.27-1.56.27-1.55-.12-1.55-.27z"
          style={{
            WebkitTransformOrigin: 114.485,
            MsTransformOrigin: 114.485,
            transformOrigin: 114.485,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M106.69 466.67c0-.15.7-.27 1.56-.27s1.56.12 1.56.27-.7.27-1.56.27-1.56-.12-1.56-.27z"
          style={{
            WebkitTransformOrigin: 108.25,
            MsTransformOrigin: 108.25,
            transformOrigin: 108.25,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M100.45 466.67c0-.15.7-.27 1.56-.27s1.56.12 1.56.27-.7.27-1.56.27-1.56-.12-1.56-.27z"
          style={{
            WebkitTransformOrigin: 102.01,
            MsTransformOrigin: 102.01,
            transformOrigin: 102.01,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M94.22 466.67c0-.15.7-.27 1.56-.27s1.56.12 1.56.27-.7.27-1.56.27-1.56-.12-1.56-.27z"
          style={{
            WebkitTransformOrigin: 95.78,
            MsTransformOrigin: 95.78,
            transformOrigin: 95.78,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M88 466.67c0-.15.7-.27 1.56-.27s1.56.12 1.56.27-.7.27-1.56.27-1.56-.12-1.56-.27z"
          style={{
            WebkitTransformOrigin: 89.56,
            MsTransformOrigin: 89.56,
            transformOrigin: 89.56,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M83.54 464.88c.15 0 .27.69.27 1.55v.24l-.27-.27c.75 0 1.33.13 1.33.27s-.58.25-1.33.27h-.26v-.51c-.01-.86.11-1.55.26-1.55z"
          style={{
            WebkitTransformOrigin: 84.0747,
            MsTransformOrigin: 84.0747,
            transformOrigin: 84.0747,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M83.54 458.64c.15 0 .27.7.27 1.56s-.12 1.56-.27 1.56-.27-.7-.27-1.56.12-1.56.27-1.56z"
          style={{
            WebkitTransformOrigin: 83.54,
            MsTransformOrigin: 83.54,
            transformOrigin: 83.54,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M83.54 452.4c.15 0 .27.7.27 1.56s-.12 1.56-.27 1.56-.27-.7-.27-1.56.12-1.56.27-1.56z"
          style={{
            WebkitTransformOrigin: 83.54,
            MsTransformOrigin: 83.54,
            transformOrigin: 83.54,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M83.54 446.17c.15 0 .27.7.27 1.56s-.12 1.56-.27 1.56-.27-.7-.27-1.56.12-1.56.27-1.56z"
          style={{
            WebkitTransformOrigin: 83.54,
            MsTransformOrigin: 83.54,
            transformOrigin: 83.54,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M83.54 439.93c.15 0 .27.7.27 1.56s-.12 1.56-.27 1.56-.27-.7-.27-1.56.12-1.56.27-1.56z"
          style={{
            WebkitTransformOrigin: 83.54,
            MsTransformOrigin: 83.54,
            transformOrigin: 83.54,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M83.54 433.7c.15 0 .27.7.27 1.56s-.12 1.56-.27 1.56-.27-.7-.27-1.56.12-1.56.27-1.56z"
          style={{
            WebkitTransformOrigin: 83.54,
            MsTransformOrigin: 83.54,
            transformOrigin: 83.54,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M83.54 427.46c.15 0 .27.7.27 1.56s-.12 1.56-.27 1.56-.27-.7-.27-1.56.12-1.56.27-1.56z"
          style={{
            WebkitTransformOrigin: 83.54,
            MsTransformOrigin: 83.54,
            transformOrigin: 83.54,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M83.54 421.23c.15 0 .27.69.27 1.56s-.12 1.55-.27 1.55-.27-.69-.27-1.55.12-1.56.27-1.56z"
          style={{
            WebkitTransformOrigin: 83.54,
            MsTransformOrigin: 83.54,
            transformOrigin: 83.54,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M83.54 415c.15 0 .27.7.27 1.56s-.12 1.56-.27 1.56-.27-.7-.27-1.56.12-1.56.27-1.56z"
          style={{
            WebkitTransformOrigin: 83.54,
            MsTransformOrigin: 83.54,
            transformOrigin: 83.54,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M83.54 408.76c.15 0 .27.69.27 1.55s-.12 1.56-.27 1.56-.27-.69-.27-1.56.12-1.55.27-1.55z"
          style={{
            WebkitTransformOrigin: 83.54,
            MsTransformOrigin: 83.54,
            transformOrigin: 83.54,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M83.54 402.52c.15 0 .27.7.27 1.56s-.12 1.56-.27 1.56-.27-.7-.27-1.56.12-1.56.27-1.56z"
          style={{
            WebkitTransformOrigin: 83.54,
            MsTransformOrigin: 83.54,
            transformOrigin: 83.54,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M83.54 396.28c.15 0 .27.7.27 1.56s-.12 1.56-.27 1.56-.27-.7-.27-1.56.12-1.56.27-1.56z"
          style={{
            WebkitTransformOrigin: 83.54,
            MsTransformOrigin: 83.54,
            transformOrigin: 83.54,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M84 390.51c0 .08-.18.15-.47.2l.19-.19a6.49 6.49 0 01.08 1.09c0 .86-.12 1.56-.27 1.56s-.27-.7-.27-1.56a6.49 6.49 0 01.08-1.09v-.22h.16c.33.07.5.14.5.21z"
          style={{
            WebkitTransformOrigin: 83.6298,
            MsTransformOrigin: 83.6298,
            transformOrigin: 83.6298,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M90.24 390.51c0 .15-.7.28-1.56.28s-1.56-.13-1.56-.28.7-.27 1.56-.27 1.56.13 1.56.27z"
          style={{
            WebkitTransformOrigin: 88.68,
            MsTransformOrigin: 88.68,
            transformOrigin: 88.68,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M96.48 390.51c0 .15-.7.28-1.56.28s-1.56-.13-1.56-.28.7-.27 1.56-.27 1.56.13 1.56.27z"
          style={{
            WebkitTransformOrigin: 94.92,
            MsTransformOrigin: 94.92,
            transformOrigin: 94.92,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M102.71 390.51c0 .15-.69.28-1.56.28s-1.55-.13-1.55-.28.69-.27 1.55-.27 1.56.13 1.56.27z"
          style={{
            WebkitTransformOrigin: 101.155,
            MsTransformOrigin: 101.155,
            transformOrigin: 101.155,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M109 390.51c0 .15-.7.28-1.56.28s-1.56-.13-1.56-.28.7-.27 1.56-.27 1.56.13 1.56.27z"
          style={{
            WebkitTransformOrigin: 107.44,
            MsTransformOrigin: 107.44,
            transformOrigin: 107.44,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M115.19 390.51c0 .15-.7.28-1.56.28s-1.56-.13-1.56-.28.7-.27 1.56-.27 1.56.13 1.56.27z"
          style={{
            WebkitTransformOrigin: 113.63,
            MsTransformOrigin: 113.63,
            transformOrigin: 113.63,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M121.42 390.51c0 .15-.7.28-1.56.28s-1.56-.13-1.56-.28.7-.27 1.56-.27 1.56.13 1.56.27z"
          style={{
            WebkitTransformOrigin: 119.86,
            MsTransformOrigin: 119.86,
            transformOrigin: 119.86,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M127.66 390.51c0 .15-.7.28-1.56.28s-1.56-.13-1.56-.28.7-.27 1.56-.27 1.56.13 1.56.27z"
          style={{
            WebkitTransformOrigin: 126.1,
            MsTransformOrigin: 126.1,
            transformOrigin: 126.1,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M133.89 390.51c0 .15-.7.28-1.56.28s-1.56-.13-1.56-.28.7-.27 1.56-.27 1.56.13 1.56.27z"
          style={{
            WebkitTransformOrigin: 132.33,
            MsTransformOrigin: 132.33,
            transformOrigin: 132.33,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M140.13 390.51c0 .15-.7.28-1.56.28s-1.56-.13-1.56-.28.7-.27 1.56-.27 1.56.13 1.56.27z"
          style={{
            WebkitTransformOrigin: 138.57,
            MsTransformOrigin: 138.57,
            transformOrigin: 138.57,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M146.36 390.51c0 .15-.69.28-1.56.28s-1.55-.13-1.55-.28.69-.27 1.55-.27 1.56.13 1.56.27z"
          style={{
            WebkitTransformOrigin: 144.805,
            MsTransformOrigin: 144.805,
            transformOrigin: 144.805,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M152.6 390.51c0 .15-.7.28-1.56.28s-1.56-.13-1.56-.28.7-.27 1.56-.27 1.56.13 1.56.27z"
          style={{
            WebkitTransformOrigin: 151.04,
            MsTransformOrigin: 151.04,
            transformOrigin: 151.04,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M158.83 390.51c0 .15-.69.28-1.55.28s-1.56-.13-1.56-.28.69-.27 1.56-.27 1.55.13 1.55.27z"
          style={{
            WebkitTransformOrigin: 157.275,
            MsTransformOrigin: 157.275,
            transformOrigin: 157.275,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M159.69 395.89c-.15 0-.27-.7-.27-1.56s.12-1.56.27-1.56.27.7.27 1.56-.12 1.56-.27 1.56z"
          style={{
            WebkitTransformOrigin: 159.69,
            MsTransformOrigin: 159.69,
            transformOrigin: 159.69,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M159.69 402.13c-.15 0-.27-.7-.27-1.56s.12-1.56.27-1.56.27.7.27 1.56-.12 1.56-.27 1.56z"
          style={{
            WebkitTransformOrigin: 159.69,
            MsTransformOrigin: 159.69,
            transformOrigin: 159.69,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M159.69 408.36c-.15 0-.27-.69-.27-1.56s.12-1.55.27-1.55.27.69.27 1.55-.12 1.56-.27 1.56z"
          style={{
            WebkitTransformOrigin: 159.69,
            MsTransformOrigin: 159.69,
            transformOrigin: 159.69,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M159.69 414.6c-.15 0-.27-.7-.27-1.56s.12-1.56.27-1.56.27.7.27 1.56-.12 1.56-.27 1.56z"
          style={{
            WebkitTransformOrigin: 159.69,
            MsTransformOrigin: 159.69,
            transformOrigin: 159.69,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M159.69 420.83c-.15 0-.27-.69-.27-1.55s.12-1.56.27-1.56.27.7.27 1.56-.12 1.55-.27 1.55z"
          style={{
            WebkitTransformOrigin: 159.69,
            MsTransformOrigin: 159.69,
            transformOrigin: 159.69,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M159.69 427.07c-.15 0-.27-.7-.27-1.56s.12-1.56.27-1.56.27.7.27 1.56-.12 1.56-.27 1.56z"
          style={{
            WebkitTransformOrigin: 159.69,
            MsTransformOrigin: 159.69,
            transformOrigin: 159.69,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M159.69 433.31c-.15 0-.27-.7-.27-1.56s.12-1.56.27-1.56.27.7.27 1.56-.12 1.56-.27 1.56z"
          style={{
            WebkitTransformOrigin: 159.69,
            MsTransformOrigin: 159.69,
            transformOrigin: 159.69,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M159.69 439.54c-.15 0-.27-.7-.27-1.56s.12-1.56.27-1.56.27.7.27 1.56-.12 1.56-.27 1.56z"
          style={{
            WebkitTransformOrigin: 159.69,
            MsTransformOrigin: 159.69,
            transformOrigin: 159.69,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M159.69 445.78c-.15 0-.27-.7-.27-1.56s.12-1.56.27-1.56.27.7.27 1.56-.12 1.56-.27 1.56z"
          style={{
            WebkitTransformOrigin: 159.69,
            MsTransformOrigin: 159.69,
            transformOrigin: 159.69,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M159.69 452c-.15 0-.27-.69-.27-1.56s.12-1.55.27-1.55.27.69.27 1.55-.12 1.56-.27 1.56z"
          style={{
            WebkitTransformOrigin: 159.69,
            MsTransformOrigin: 159.69,
            transformOrigin: 159.69,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M159.69 458.25c-.15 0-.27-.7-.27-1.56s.12-1.56.27-1.56.27.7.27 1.56-.12 1.56-.27 1.56z"
          style={{
            WebkitTransformOrigin: 159.69,
            MsTransformOrigin: 159.69,
            transformOrigin: 159.69,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M159.69 464.48c-.15 0-.27-.69-.27-1.55s.12-1.56.27-1.56.27.69.27 1.56-.12 1.55-.27 1.55z"
          style={{
            WebkitTransformOrigin: 159.69,
            MsTransformOrigin: 159.69,
            transformOrigin: 159.69,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 159.69,
            MsTransformOrigin: 159.69,
            transformOrigin: 159.69,
          }}
          fill="#E0E0E0"
          d="M156.87 463.08H162.51V468.71999999999997H156.87z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 83.54,
            MsTransformOrigin: 83.54,
            transformOrigin: 83.54,
          }}
          fill="#E0E0E0"
          d="M80.72 463.85H86.36V469.49H80.72z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 159.69,
            MsTransformOrigin: 159.69,
            transformOrigin: 159.69,
          }}
          fill="#E0E0E0"
          d="M156.87 387.15H162.51V392.78999999999996H156.87z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 83.54,
            MsTransformOrigin: 83.54,
            transformOrigin: 83.54,
          }}
          fill="#E0E0E0"
          d="M80.72 387.7H86.36V393.34H80.72z"
          className="animable"
        ></path>
        <path
          d="M127.1 403c21.69-17.53 24.79-47.46 22-74-.46-4.43-.3-9.14-2.68-12.89s-7.5-6.06-11.34-3.91c-3.19 1.78-4.35 5.72-6 9a25.92 25.92 0 01-9.71 10.67c-2.72 1.67-6.15 2.81-9 1.46-4-1.84-4.8-7.1-5.08-11.51q-.78-12.34-1.57-24.69c-.28-4.41-.58-8.92-2.25-13s-5-7.77-9.25-8.66-9.3 1.9-9.88 6.29c-.25 1.82.25 3.66.25 5.5s-.7 3.93-2.41 4.56-3.58-.49-5-1.69c-4.85-4.13-8.34-9.66-11.63-15.13s-6.5-11.15-11-15.72-10.46-8-16.81-7.94-12.81 4.46-14 10.77 2.91 12.39 7.21 17.15a98.28 98.28 0 0021.88 18.12c1.46.89 3 1.85 3.68 3.45 1.17 2.88-1.42 6-4.25 7.16-3.22 1.33-6.81 1.24-10.22 1.88s-7 2.43-8.11 5.77c-1.49 4.64 2.61 9.1 6.54 11.94a84.87 84.87 0 0026 12.67c3.33 1 6.82 1.76 9.6 3.86s4.63 5.95 3.27 9.17-5.36 4.5-8.8 4.11-6.62-2-9.94-3c-6.23-1.87-14-.9-17.55 4.61-2.31 3.63-2.2 8.44-.62 12.46s4.44 7.37 7.45 10.44c10.52 10.72 23.6 19 38 22.93s27.54 4.3 41.17-1.79"
          style={{
            WebkitTransformOrigin: 85.7487,
            MsTransformOrigin: 85.7487,
            transformOrigin: 85.7487,
          }}
          fill="#F5F5F5"
          className="animable"
        ></path>
        <path
          d="M124 405.15c-1.7-10.47 0-5.23-4.88-18.36a394.58 394.58 0 00-18.68-41.47l-5.5-10.62c-1.8-3.49-3.58-6.94-5.53-10.21a198.57 198.57 0 00-12.3-18.14c-4.19-5.59-8.37-10.73-12.31-15.51s-7.72-9.16-11.33-13A156.37 156.37 0 0035.12 261c-2.32-1.82-4.16-3.17-5.42-4.06l-1.44-1a3.72 3.72 0 00-.51-.32 2.94 2.94 0 00.46.39l1.39.99c1.23.94 3 2.33 5.32 4.18a166.33 166.33 0 0118.13 17c3.57 3.86 7.31 8.26 11.23 13s8.07 10 12.24 15.53a200.91 200.91 0 0112.2 18.1c1.94 3.26 3.7 6.68 5.5 10.17l5.45 10.62a406.06 406.06 0 0118.7 41.37c4.9 13.07 3.25 7.77 5 18.18"
          style={{
            WebkitTransformOrigin: 75.875,
            MsTransformOrigin: 75.875,
            transformOrigin: 75.875,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M89.17 324.48a8.68 8.68 0 00.3-1.92c.15-1.23.3-3 .46-5.25.32-4.43.54-10.58.77-17.35s.54-12.9.92-17.32c.19-2.21.36-4 .49-5.23a8.12 8.12 0 00.11-1.94 9.57 9.57 0 00-.42 1.89c-.23 1.22-.49 3-.75 5.22-.53 4.42-.94 10.56-1.16 17.35s-.39 12.73-.54 17.35c-.07 2.09-.14 3.85-.19 5.26a10.11 10.11 0 00.01 1.94z"
          style={{
            WebkitTransformOrigin: 90.6772,
            MsTransformOrigin: 90.6772,
            transformOrigin: 90.6772,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M31.69 317.84A12.34 12.34 0 0034 318c1.46 0 3.58.06 6.2.18 5.23.2 12.46.72 20.4 1.65s15.1 2.11 20.25 3.09c2.58.48 4.65.92 6.09 1.22a13.28 13.28 0 002.25.39 10.68 10.68 0 00-2.18-.7 104 104 0 00-6-1.48c-5.13-1.14-12.3-2.39-20.27-3.34a198.08 198.08 0 00-20.48-1.41c-2.63-.05-4.75 0-6.22.08a11.74 11.74 0 00-2.35.16z"
          style={{
            WebkitTransformOrigin: 60.44,
            MsTransformOrigin: 60.44,
            transformOrigin: 60.44,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M118.3 385.8a3.47 3.47 0 00.34-.72l.83-2.12c.71-1.85 1.66-4.54 2.8-7.89 2.28-6.69 5.15-16 8.17-26.38s5.79-19.71 7.93-26.44c1-3.28 1.92-6 2.56-8 .27-.87.5-1.58.68-2.17a2.73 2.73 0 00.19-.78 3.17 3.17 0 00-.34.73c-.23.57-.5 1.27-.83 2.11-.71 1.85-1.67 4.54-2.81 7.89-2.28 6.69-5.14 16-8.16 26.39s-5.79 19.71-7.93 26.44c-1.06 3.27-1.92 6-2.56 8-.28.87-.5 1.59-.69 2.17a3 3 0 00-.18.77z"
          style={{
            WebkitTransformOrigin: 130.05,
            MsTransformOrigin: 130.05,
            transformOrigin: 130.05,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M41.2 366.77a2.9 2.9 0 00.8.23l2.24.58 8.26 2.05c7 1.73 16.57 4.24 27.18 7s20.24 5.21 27.25 6.8c3.5.79 6.35 1.41 8.32 1.79l2.27.43a3.07 3.07 0 00.81.1 2.9 2.9 0 00-.77-.26l-2.24-.58-8.26-2c-7-1.73-16.57-4.24-27.18-7s-20.24-5.21-27.25-6.8c-3.5-.79-6.35-1.41-8.32-1.79l-2.31-.46a2.75 2.75 0 00-.8-.09z"
          style={{
            WebkitTransformOrigin: 79.765,
            MsTransformOrigin: 79.765,
            transformOrigin: 79.765,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M452.29 282a15.14 15.14 0 012.47 11.59 26.57 26.57 0 01-5 11c-4.6 6.37-12.34 11.06-19.56 14.16-2.92-8.23.67-17.7 2.8-22.38 3.36-7.41 13.92-18.77 19.28-14.37"
          style={{
            WebkitTransformOrigin: 442.07,
            MsTransformOrigin: 442.07,
            transformOrigin: 442.07,
          }}
          fill="#F5F5F5"
          className="animable"
        ></path>
        <path
          d="M430.55 333.76a12.9 12.9 0 019.65-8.65 17.14 17.14 0 0113 2.76c1.75 1.17 3.42 2.81 3.62 4.9a5.55 5.55 0 01-2.67 4.92 11.19 11.19 0 01-5.62 1.52c-6.72.32-13.28-.6-17.94-5.45"
          style={{
            WebkitTransformOrigin: 443.686,
            MsTransformOrigin: 443.686,
            transformOrigin: 443.686,
          }}
          fill="#F5F5F5"
          className="animable"
        ></path>
        <path
          d="M414.9 345.69a25.39 25.39 0 013.29-4 24.14 24.14 0 014.34-3.67 52.43 52.43 0 015.91-3.6 25.77 25.77 0 0112.22-2.9 18.4 18.4 0 013.8.53c.87.25 1.33.43 1.31.49s-1.95-.43-5.11-.4a27.69 27.69 0 00-11.82 3.07 59.69 59.69 0 00-5.86 3.49 26.28 26.28 0 00-4.35 3.43 37.62 37.62 0 01-3.73 3.56z"
          style={{
            WebkitTransformOrigin: 430.335,
            MsTransformOrigin: 430.335,
            transformOrigin: 430.335,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M445 291.39a14.17 14.17 0 01-1 2.41c-.73 1.52-1.82 3.71-3.19 6.41-2.73 5.39-6.59 12.79-11.05 20.86s-8.72 15.27-11.9 20.41c-1.59 2.57-2.9 4.63-3.84 6a15.2 15.2 0 01-1.52 2.14 15.81 15.81 0 011.23-2.32l3.6-6.18c3-5.22 7.2-12.45 11.66-20.51s8.31-15.29 11.27-20.73l3.43-6.28a15.57 15.57 0 011.31-2.21z"
          style={{
            WebkitTransformOrigin: 428.75,
            MsTransformOrigin: 428.75,
            transformOrigin: 428.75,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M420.42 331.51a30.22 30.22 0 00-7.24-26.63c-1.66-1.78-4.08-3.47-6.36-2.61s-3 3.74-3 6.18a25.79 25.79 0 0016.22 23.6"
          style={{
            WebkitTransformOrigin: 412.448,
            MsTransformOrigin: 412.448,
            transformOrigin: 412.448,
          }}
          fill="#F5F5F5"
          className="animable"
        ></path>
        <path
          d="M412.07 311.93c.05 0 .38.34.92 1s1.27 1.76 2.14 3.09a34.09 34.09 0 014.87 11.49 21.24 21.24 0 01.18 6.9 20.72 20.72 0 01-1.59 5.4 21 21 0 01-1.85 3.29c-.51.73-.82 1.11-.87 1.08s1-1.72 2.15-4.61a22.66 22.66 0 001.36-5.27 22 22 0 00-.24-6.63 38 38 0 00-4.5-11.29 46.75 46.75 0 01-2.57-4.45z"
          style={{
            WebkitTransformOrigin: 416.225,
            MsTransformOrigin: 416.225,
            transformOrigin: 416.225,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M456.46 263.82c0-.18.84-.33 1.87-.33s1.87.15 1.87.33-.84.32-1.87.32-1.87-.14-1.87-.32z"
          style={{
            WebkitTransformOrigin: 458.33,
            MsTransformOrigin: 458.33,
            transformOrigin: 458.33,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M449 263.82c0-.18.84-.33 1.87-.33s1.87.15 1.87.33-.84.32-1.87.32-1.87-.14-1.87-.32z"
          style={{
            WebkitTransformOrigin: 450.87,
            MsTransformOrigin: 450.87,
            transformOrigin: 450.87,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M441.52 263.82c0-.18.84-.33 1.87-.33s1.87.15 1.87.33-.84.32-1.87.32-1.87-.14-1.87-.32z"
          style={{
            WebkitTransformOrigin: 443.39,
            MsTransformOrigin: 443.39,
            transformOrigin: 443.39,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M434.06 263.82c0-.18.83-.33 1.86-.33s1.87.15 1.87.33-.84.32-1.87.32-1.86-.14-1.86-.32z"
          style={{
            WebkitTransformOrigin: 435.925,
            MsTransformOrigin: 435.925,
            transformOrigin: 435.925,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M426.59 263.82c0-.18.83-.33 1.87-.33s1.86.15 1.86.33-.83.32-1.86.32-1.87-.14-1.87-.32z"
          style={{
            WebkitTransformOrigin: 428.455,
            MsTransformOrigin: 428.455,
            transformOrigin: 428.455,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M419.12 263.82c0-.18.84-.33 1.87-.33s1.86.15 1.86.33-.83.32-1.86.32-1.87-.14-1.87-.32z"
          style={{
            WebkitTransformOrigin: 420.985,
            MsTransformOrigin: 420.985,
            transformOrigin: 420.985,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M411.65 263.82c0-.18.84-.33 1.87-.33s1.87.15 1.87.33-.84.32-1.87.32-1.87-.14-1.87-.32z"
          style={{
            WebkitTransformOrigin: 413.52,
            MsTransformOrigin: 413.52,
            transformOrigin: 413.52,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M404.18 263.82c0-.18.84-.33 1.87-.33s1.87.15 1.87.33-.84.32-1.87.32-1.87-.14-1.87-.32z"
          style={{
            WebkitTransformOrigin: 406.05,
            MsTransformOrigin: 406.05,
            transformOrigin: 406.05,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M396.72 263.82c0-.18.83-.33 1.86-.33s1.87.15 1.87.33-.84.32-1.87.32-1.86-.14-1.86-.32z"
          style={{
            WebkitTransformOrigin: 398.585,
            MsTransformOrigin: 398.585,
            transformOrigin: 398.585,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M389.25 263.82c0-.18.83-.33 1.86-.33s1.87.15 1.87.33-.83.32-1.87.32-1.86-.14-1.86-.32z"
          style={{
            WebkitTransformOrigin: 391.115,
            MsTransformOrigin: 391.115,
            transformOrigin: 391.115,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M381.78 263.82c0-.18.83-.33 1.87-.33s1.86.15 1.86.33-.83.32-1.86.32-1.87-.14-1.87-.32z"
          style={{
            WebkitTransformOrigin: 383.645,
            MsTransformOrigin: 383.645,
            transformOrigin: 383.645,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M374.31 263.82c0-.18.84-.33 1.87-.33s1.86.15 1.86.33-.83.32-1.86.32-1.87-.14-1.87-.32z"
          style={{
            WebkitTransformOrigin: 376.175,
            MsTransformOrigin: 376.175,
            transformOrigin: 376.175,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M369 261.67c.18 0 .32.84.32 1.87v.28l-.32-.32c.9 0 1.59.15 1.59.32s-.69.29-1.59.32h-.32v-.6c-.01-1.03.13-1.87.32-1.87z"
          style={{
            WebkitTransformOrigin: 369.635,
            MsTransformOrigin: 369.635,
            transformOrigin: 369.635,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M369 254.2c.18 0 .32.84.32 1.87s-.14 1.87-.32 1.87-.32-.84-.32-1.87.13-1.87.32-1.87z"
          style={{
            WebkitTransformOrigin: 369,
            MsTransformOrigin: 369,
            transformOrigin: 369,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M369 246.73c.18 0 .32.84.32 1.87s-.14 1.87-.32 1.87-.32-.84-.32-1.87.13-1.87.32-1.87z"
          style={{
            WebkitTransformOrigin: 369,
            MsTransformOrigin: 369,
            transformOrigin: 369,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M369 239.26c.18 0 .32.84.32 1.87s-.15 1.87-.32 1.87-.32-.84-.32-1.87.13-1.87.32-1.87z"
          style={{
            WebkitTransformOrigin: 369,
            MsTransformOrigin: 369,
            transformOrigin: 369,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M369 231.8c.18 0 .32.83.32 1.86s-.14 1.87-.32 1.87-.32-.84-.32-1.87.13-1.86.32-1.86z"
          style={{
            WebkitTransformOrigin: 369,
            MsTransformOrigin: 369,
            transformOrigin: 369,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M369 224.33c.18 0 .32.83.32 1.87s-.14 1.86-.32 1.86-.32-.83-.32-1.86.13-1.87.32-1.87z"
          style={{
            WebkitTransformOrigin: 369,
            MsTransformOrigin: 369,
            transformOrigin: 369,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M369 216.86c.18 0 .32.84.32 1.87s-.14 1.86-.32 1.86-.32-.83-.32-1.86.13-1.87.32-1.87z"
          style={{
            WebkitTransformOrigin: 369,
            MsTransformOrigin: 369,
            transformOrigin: 369,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M369 209.39c.18 0 .32.84.32 1.87s-.14 1.87-.32 1.87-.32-.84-.32-1.87.13-1.87.32-1.87z"
          style={{
            WebkitTransformOrigin: 369,
            MsTransformOrigin: 369,
            transformOrigin: 369,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M369 201.92c.18 0 .32.84.32 1.87s-.14 1.87-.32 1.87-.32-.84-.32-1.87.13-1.87.32-1.87z"
          style={{
            WebkitTransformOrigin: 369,
            MsTransformOrigin: 369,
            transformOrigin: 369,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M369 194.46c.18 0 .32.83.32 1.86s-.14 1.87-.32 1.87-.32-.84-.32-1.87.13-1.86.32-1.86z"
          style={{
            WebkitTransformOrigin: 369,
            MsTransformOrigin: 369,
            transformOrigin: 369,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M369 187c.18 0 .32.83.32 1.86s-.14 1.87-.32 1.87-.32-.83-.32-1.87.13-1.86.32-1.86z"
          style={{
            WebkitTransformOrigin: 369,
            MsTransformOrigin: 369,
            transformOrigin: 369,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M369 179.52c.18 0 .32.84.32 1.87s-.14 1.86-.32 1.86-.32-.83-.32-1.86.13-1.87.32-1.87z"
          style={{
            WebkitTransformOrigin: 369,
            MsTransformOrigin: 369,
            transformOrigin: 369,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M369.55 172.61c0 .09-.22.17-.56.23l.23-.23a8 8 0 01.09 1.31c0 1-.14 1.87-.32 1.87s-.32-.84-.32-1.87a8 8 0 01.09-1.31v-.26h.19c.38.09.6.17.6.26z"
          style={{
            WebkitTransformOrigin: 369.11,
            MsTransformOrigin: 369.11,
            transformOrigin: 369.11,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M377 172.61c0 .18-.84.32-1.87.32s-1.87-.14-1.87-.32.84-.32 1.87-.32 1.87.14 1.87.32z"
          style={{
            WebkitTransformOrigin: 375.13,
            MsTransformOrigin: 375.13,
            transformOrigin: 375.13,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M384.48 172.61c0 .18-.83.32-1.86.32s-1.87-.14-1.87-.32.84-.32 1.87-.32 1.86.14 1.86.32z"
          style={{
            WebkitTransformOrigin: 382.615,
            MsTransformOrigin: 382.615,
            transformOrigin: 382.615,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M392 172.61c0 .18-.83.32-1.86.32s-1.87-.14-1.87-.32.83-.32 1.87-.32 1.86.14 1.86.32z"
          style={{
            WebkitTransformOrigin: 390.135,
            MsTransformOrigin: 390.135,
            transformOrigin: 390.135,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M399.42 172.61c0 .18-.83.32-1.87.32s-1.86-.14-1.86-.32.83-.32 1.86-.32 1.87.14 1.87.32z"
          style={{
            WebkitTransformOrigin: 397.555,
            MsTransformOrigin: 397.555,
            transformOrigin: 397.555,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M406.89 172.61c0 .18-.84.32-1.87.32s-1.86-.14-1.86-.32.83-.32 1.86-.32 1.87.14 1.87.32z"
          style={{
            WebkitTransformOrigin: 405.025,
            MsTransformOrigin: 405.025,
            transformOrigin: 405.025,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M414.36 172.61c0 .18-.84.32-1.87.32s-1.87-.14-1.87-.32.84-.32 1.87-.32 1.87.14 1.87.32z"
          style={{
            WebkitTransformOrigin: 412.49,
            MsTransformOrigin: 412.49,
            transformOrigin: 412.49,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M421.83 172.61c0 .18-.84.32-1.87.32s-1.87-.14-1.87-.32.84-.32 1.87-.32 1.87.14 1.87.32z"
          style={{
            WebkitTransformOrigin: 419.96,
            MsTransformOrigin: 419.96,
            transformOrigin: 419.96,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M429.29 172.61c0 .18-.83.32-1.86.32s-1.87-.14-1.87-.32.84-.32 1.87-.32 1.86.14 1.86.32z"
          style={{
            WebkitTransformOrigin: 427.425,
            MsTransformOrigin: 427.425,
            transformOrigin: 427.425,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M436.76 172.61c0 .18-.83.32-1.86.32s-1.87-.14-1.87-.32.83-.32 1.87-.32 1.86.14 1.86.32z"
          style={{
            WebkitTransformOrigin: 434.895,
            MsTransformOrigin: 434.895,
            transformOrigin: 434.895,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M444.23 172.61c0 .18-.84.32-1.87.32s-1.86-.14-1.86-.32.83-.32 1.86-.32 1.87.14 1.87.32z"
          style={{
            WebkitTransformOrigin: 442.365,
            MsTransformOrigin: 442.365,
            transformOrigin: 442.365,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M451.7 172.61c0 .18-.84.32-1.87.32s-1.87-.14-1.87-.32.84-.32 1.87-.32 1.87.14 1.87.32z"
          style={{
            WebkitTransformOrigin: 449.83,
            MsTransformOrigin: 449.83,
            transformOrigin: 449.83,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M459.17 172.61c0 .18-.84.32-1.87.32s-1.87-.14-1.87-.32.84-.32 1.87-.32 1.87.14 1.87.32z"
          style={{
            WebkitTransformOrigin: 457.3,
            MsTransformOrigin: 457.3,
            transformOrigin: 457.3,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M460.2 179.05c-.18 0-.33-.84-.33-1.87s.15-1.86.33-1.86.32.83.32 1.86-.15 1.87-.32 1.87z"
          style={{
            WebkitTransformOrigin: 460.195,
            MsTransformOrigin: 460.195,
            transformOrigin: 460.195,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M460.2 186.52c-.18 0-.33-.84-.33-1.87s.15-1.87.33-1.87.32.84.32 1.87-.15 1.87-.32 1.87z"
          style={{
            WebkitTransformOrigin: 460.195,
            MsTransformOrigin: 460.195,
            transformOrigin: 460.195,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M460.2 194c-.18 0-.33-.84-.33-1.87s.15-1.87.33-1.87.32.84.32 1.87-.15 1.87-.32 1.87z"
          style={{
            WebkitTransformOrigin: 460.195,
            MsTransformOrigin: 460.195,
            transformOrigin: 460.195,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M460.2 201.45c-.18 0-.33-.83-.33-1.86s.15-1.87.33-1.87.32.84.32 1.87-.15 1.86-.32 1.86z"
          style={{
            WebkitTransformOrigin: 460.195,
            MsTransformOrigin: 460.195,
            transformOrigin: 460.195,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M460.2 208.92c-.18 0-.33-.83-.33-1.86s.15-1.87.33-1.87.32.83.32 1.87-.15 1.86-.32 1.86z"
          style={{
            WebkitTransformOrigin: 460.195,
            MsTransformOrigin: 460.195,
            transformOrigin: 460.195,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M460.2 216.39c-.18 0-.33-.83-.33-1.87s.15-1.86.33-1.86.32.83.32 1.86-.15 1.87-.32 1.87z"
          style={{
            WebkitTransformOrigin: 460.195,
            MsTransformOrigin: 460.195,
            transformOrigin: 460.195,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M460.2 223.86c-.18 0-.33-.84-.33-1.87s.15-1.86.33-1.86.32.83.32 1.86-.15 1.87-.32 1.87z"
          style={{
            WebkitTransformOrigin: 460.195,
            MsTransformOrigin: 460.195,
            transformOrigin: 460.195,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M460.2 231.33c-.18 0-.33-.84-.33-1.87s.15-1.87.33-1.87.32.84.32 1.87-.15 1.87-.32 1.87z"
          style={{
            WebkitTransformOrigin: 460.195,
            MsTransformOrigin: 460.195,
            transformOrigin: 460.195,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M460.2 238.8c-.18 0-.33-.84-.33-1.87s.15-1.87.33-1.87.32.84.32 1.87-.15 1.87-.32 1.87z"
          style={{
            WebkitTransformOrigin: 460.195,
            MsTransformOrigin: 460.195,
            transformOrigin: 460.195,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M460.2 246.26c-.18 0-.33-.83-.33-1.86s.15-1.87.33-1.87.32.84.32 1.87-.15 1.86-.32 1.86z"
          style={{
            WebkitTransformOrigin: 460.195,
            MsTransformOrigin: 460.195,
            transformOrigin: 460.195,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M460.2 253.73c-.18 0-.33-.83-.33-1.86s.15-1.87.33-1.87.32.83.32 1.87-.15 1.86-.32 1.86z"
          style={{
            WebkitTransformOrigin: 460.195,
            MsTransformOrigin: 460.195,
            transformOrigin: 460.195,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          d="M460.2 261.2c-.18 0-.33-.84-.33-1.87s.15-1.86.33-1.86.32.83.32 1.86-.15 1.87-.32 1.87z"
          style={{
            WebkitTransformOrigin: 460.195,
            MsTransformOrigin: 460.195,
            transformOrigin: 460.195,
          }}
          fill="#E0E0E0"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 460.195,
            MsTransformOrigin: 460.195,
            transformOrigin: 460.195,
          }}
          fill="#E0E0E0"
          d="M456.82 259.51H463.57V266.26H456.82z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 368.985,
            MsTransformOrigin: 368.985,
            transformOrigin: 368.985,
          }}
          fill="#E0E0E0"
          d="M365.61 260.44H372.36V267.19H365.61z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 460.195,
            MsTransformOrigin: 460.195,
            transformOrigin: 460.195,
          }}
          fill="#E0E0E0"
          d="M456.82 168.58H463.57V175.33H456.82z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 368.985,
            MsTransformOrigin: 368.985,
            transformOrigin: 368.985,
          }}
          fill="#E0E0E0"
          d="M365.61 169.23H372.36V175.98H365.61z"
          className="animable"
        ></path>
      </g>
      <g
        className="animable"
        style={{
          WebkitTransformOrigin: 246.2,
          MsTransformOrigin: 246.2,
          transformOrigin: 246.2,
        }}
      >
        <path
          d="M446.91 144.29c0-.12.57-.22 1.27-.22s1.26.1 1.26.22-.57.22-1.26.22-1.27-.1-1.27-.22z"
          style={{
            WebkitTransformOrigin: 448.175,
            MsTransformOrigin: 448.175,
            transformOrigin: 448.175,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M441.86 144.29c0-.12.57-.22 1.27-.22s1.26.1 1.26.22-.57.22-1.26.22-1.27-.1-1.27-.22z"
          style={{
            WebkitTransformOrigin: 443.125,
            MsTransformOrigin: 443.125,
            transformOrigin: 443.125,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M436.81 144.29c0-.12.57-.22 1.27-.22s1.26.1 1.26.22-.57.22-1.26.22-1.27-.1-1.27-.22z"
          style={{
            WebkitTransformOrigin: 438.075,
            MsTransformOrigin: 438.075,
            transformOrigin: 438.075,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M431.76 144.29c0-.12.57-.22 1.27-.22s1.26.1 1.26.22-.57.22-1.26.22-1.27-.1-1.27-.22z"
          style={{
            WebkitTransformOrigin: 433.025,
            MsTransformOrigin: 433.025,
            transformOrigin: 433.025,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M426.71 144.29c0-.12.57-.22 1.26-.22s1.27.1 1.27.22-.57.22-1.27.22-1.26-.1-1.26-.22z"
          style={{
            WebkitTransformOrigin: 427.975,
            MsTransformOrigin: 427.975,
            transformOrigin: 427.975,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M421.66 144.29c0-.12.57-.22 1.26-.22s1.27.1 1.27.22-.57.22-1.27.22-1.26-.1-1.26-.22z"
          style={{
            WebkitTransformOrigin: 422.925,
            MsTransformOrigin: 422.925,
            transformOrigin: 422.925,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M416.61 144.29c0-.12.57-.22 1.26-.22s1.27.1 1.27.22-.57.22-1.27.22-1.26-.1-1.26-.22z"
          style={{
            WebkitTransformOrigin: 417.875,
            MsTransformOrigin: 417.875,
            transformOrigin: 417.875,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M411.56 144.29c0-.12.56-.22 1.26-.22s1.27.1 1.27.22-.57.22-1.27.22-1.26-.1-1.26-.22z"
          style={{
            WebkitTransformOrigin: 412.825,
            MsTransformOrigin: 412.825,
            transformOrigin: 412.825,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M406.51 144.29c0-.12.56-.22 1.26-.22s1.26.1 1.26.22-.56.22-1.26.22-1.26-.1-1.26-.22z"
          style={{
            WebkitTransformOrigin: 407.77,
            MsTransformOrigin: 407.77,
            transformOrigin: 407.77,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M401.46 144.29c0-.12.56-.22 1.26-.22s1.26.1 1.26.22-.56.22-1.26.22-1.26-.1-1.26-.22z"
          style={{
            WebkitTransformOrigin: 402.72,
            MsTransformOrigin: 402.72,
            transformOrigin: 402.72,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M396.41 144.29c0-.12.56-.22 1.26-.22s1.26.1 1.26.22-.56.22-1.26.22-1.26-.1-1.26-.22z"
          style={{
            WebkitTransformOrigin: 397.67,
            MsTransformOrigin: 397.67,
            transformOrigin: 397.67,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M391.36 144.29c0-.12.56-.22 1.26-.22s1.26.1 1.26.22-.56.22-1.26.22-1.26-.1-1.26-.22z"
          style={{
            WebkitTransformOrigin: 392.62,
            MsTransformOrigin: 392.62,
            transformOrigin: 392.62,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M387.76 142.84c.12 0 .22.56.22 1.26v.19l-.21-.22c.61 0 1.07.11 1.07.22s-.46.2-1.07.22h-.22v-.41c-.01-.7.09-1.26.21-1.26z"
          style={{
            WebkitTransformOrigin: 388.195,
            MsTransformOrigin: 388.195,
            transformOrigin: 388.195,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M387.76 137.79c.12 0 .22.56.22 1.26s-.1 1.26-.22 1.26-.22-.56-.22-1.26.1-1.26.22-1.26z"
          style={{
            WebkitTransformOrigin: 387.76,
            MsTransformOrigin: 387.76,
            transformOrigin: 387.76,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M387.76 132.74c.12 0 .22.56.22 1.26s-.1 1.26-.22 1.26-.22-.56-.22-1.26.1-1.26.22-1.26z"
          style={{
            WebkitTransformOrigin: 387.76,
            MsTransformOrigin: 387.76,
            transformOrigin: 387.76,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M387.76 127.69c.12 0 .22.56.22 1.26s-.1 1.26-.22 1.26-.22-.56-.22-1.26.1-1.26.22-1.26z"
          style={{
            WebkitTransformOrigin: 387.76,
            MsTransformOrigin: 387.76,
            transformOrigin: 387.76,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M387.76 122.63c.12 0 .22.57.22 1.27s-.1 1.26-.22 1.26-.22-.57-.22-1.26.1-1.27.22-1.27z"
          style={{
            WebkitTransformOrigin: 387.76,
            MsTransformOrigin: 387.76,
            transformOrigin: 387.76,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M387.76 117.58c.12 0 .22.57.22 1.27s-.1 1.26-.22 1.26-.22-.57-.22-1.26.1-1.27.22-1.27z"
          style={{
            WebkitTransformOrigin: 387.76,
            MsTransformOrigin: 387.76,
            transformOrigin: 387.76,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M387.76 112.53c.12 0 .22.57.22 1.27s-.1 1.26-.22 1.26-.22-.57-.22-1.26.1-1.27.22-1.27z"
          style={{
            WebkitTransformOrigin: 387.76,
            MsTransformOrigin: 387.76,
            transformOrigin: 387.76,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M387.76 107.48c.12 0 .22.57.22 1.27s-.1 1.26-.22 1.26-.22-.57-.22-1.26.1-1.27.22-1.27z"
          style={{
            WebkitTransformOrigin: 387.76,
            MsTransformOrigin: 387.76,
            transformOrigin: 387.76,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M387.76 102.43c.12 0 .22.57.22 1.26s-.1 1.27-.22 1.27-.22-.57-.22-1.27.1-1.26.22-1.26z"
          style={{
            WebkitTransformOrigin: 387.76,
            MsTransformOrigin: 387.76,
            transformOrigin: 387.76,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M387.76 97.38c.12 0 .22.57.22 1.26s-.1 1.27-.22 1.27-.22-.57-.22-1.27.1-1.26.22-1.26z"
          style={{
            WebkitTransformOrigin: 387.76,
            MsTransformOrigin: 387.76,
            transformOrigin: 387.76,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M387.76 92.33c.12 0 .22.57.22 1.26s-.1 1.27-.22 1.27-.22-.57-.22-1.27.1-1.26.22-1.26z"
          style={{
            WebkitTransformOrigin: 387.76,
            MsTransformOrigin: 387.76,
            transformOrigin: 387.76,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M387.76 87.28c.12 0 .22.57.22 1.26s-.1 1.27-.22 1.27-.22-.57-.22-1.27.1-1.26.22-1.26z"
          style={{
            WebkitTransformOrigin: 387.76,
            MsTransformOrigin: 387.76,
            transformOrigin: 387.76,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M388.14 82.61c0 .06-.15.11-.38.15l.15-.15a5.22 5.22 0 01.07.88c0 .7-.1 1.26-.22 1.26s-.22-.56-.22-1.26a5.35 5.35 0 01.06-.88v-.18h.13c.27.06.41.12.41.18z"
          style={{
            WebkitTransformOrigin: 387.84,
            MsTransformOrigin: 387.84,
            transformOrigin: 387.84,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M393.19 82.61c0 .12-.57.22-1.27.22s-1.26-.1-1.26-.22.57-.22 1.26-.22 1.27.1 1.27.22z"
          style={{
            WebkitTransformOrigin: 391.925,
            MsTransformOrigin: 391.925,
            transformOrigin: 391.925,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M398.24 82.61c0 .12-.57.22-1.27.22s-1.26-.1-1.26-.22.57-.22 1.26-.22 1.27.1 1.27.22z"
          style={{
            WebkitTransformOrigin: 396.975,
            MsTransformOrigin: 396.975,
            transformOrigin: 396.975,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M403.29 82.61c0 .12-.57.22-1.26.22s-1.27-.1-1.27-.22.57-.22 1.27-.22 1.26.1 1.26.22z"
          style={{
            WebkitTransformOrigin: 402.025,
            MsTransformOrigin: 402.025,
            transformOrigin: 402.025,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M408.34 82.61c0 .12-.57.22-1.26.22s-1.27-.1-1.27-.22.57-.22 1.27-.22 1.26.1 1.26.22z"
          style={{
            WebkitTransformOrigin: 407.075,
            MsTransformOrigin: 407.075,
            transformOrigin: 407.075,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M413.39 82.61c0 .12-.57.22-1.26.22s-1.27-.1-1.27-.22.57-.22 1.27-.22 1.26.1 1.26.22z"
          style={{
            WebkitTransformOrigin: 412.125,
            MsTransformOrigin: 412.125,
            transformOrigin: 412.125,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M418.44 82.61c0 .12-.57.22-1.26.22s-1.27-.1-1.27-.22.57-.22 1.27-.22 1.26.1 1.26.22z"
          style={{
            WebkitTransformOrigin: 417.175,
            MsTransformOrigin: 417.175,
            transformOrigin: 417.175,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M423.49 82.61c0 .12-.56.22-1.26.22s-1.26-.1-1.26-.22.56-.22 1.26-.22 1.26.1 1.26.22z"
          style={{
            WebkitTransformOrigin: 422.23,
            MsTransformOrigin: 422.23,
            transformOrigin: 422.23,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M428.54 82.61c0 .12-.56.22-1.26.22s-1.26-.1-1.26-.22.56-.22 1.26-.22 1.26.1 1.26.22z"
          style={{
            WebkitTransformOrigin: 427.28,
            MsTransformOrigin: 427.28,
            transformOrigin: 427.28,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M433.59 82.61c0 .12-.56.22-1.26.22s-1.26-.1-1.26-.22.56-.22 1.26-.22 1.26.1 1.26.22z"
          style={{
            WebkitTransformOrigin: 432.33,
            MsTransformOrigin: 432.33,
            transformOrigin: 432.33,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M438.64 82.61c0 .12-.56.22-1.26.22s-1.26-.1-1.26-.22.56-.22 1.26-.22 1.26.1 1.26.22z"
          style={{
            WebkitTransformOrigin: 437.38,
            MsTransformOrigin: 437.38,
            transformOrigin: 437.38,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M443.69 82.61c0 .12-.56.22-1.26.22s-1.26-.1-1.26-.22.56-.22 1.26-.22 1.26.1 1.26.22z"
          style={{
            WebkitTransformOrigin: 442.43,
            MsTransformOrigin: 442.43,
            transformOrigin: 442.43,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M448.74 82.61c0 .12-.56.22-1.26.22s-1.26-.1-1.26-.22.56-.22 1.26-.22 1.26.1 1.26.22z"
          style={{
            WebkitTransformOrigin: 447.48,
            MsTransformOrigin: 447.48,
            transformOrigin: 447.48,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M449.44 87c-.12 0-.22-.56-.22-1.26s.1-1.26.22-1.26.22.56.22 1.26-.1 1.26-.22 1.26z"
          style={{
            WebkitTransformOrigin: 449.44,
            MsTransformOrigin: 449.44,
            transformOrigin: 449.44,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M449.44 92c-.12 0-.22-.56-.22-1.26s.1-1.26.22-1.26.22.56.22 1.26-.1 1.26-.22 1.26z"
          style={{
            WebkitTransformOrigin: 449.44,
            MsTransformOrigin: 449.44,
            transformOrigin: 449.44,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M449.44 97.06c-.12 0-.22-.56-.22-1.26s.1-1.26.22-1.26.22.56.22 1.26-.1 1.26-.22 1.26z"
          style={{
            WebkitTransformOrigin: 449.44,
            MsTransformOrigin: 449.44,
            transformOrigin: 449.44,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M449.44 102.11c-.12 0-.22-.56-.22-1.26s.1-1.26.22-1.26.22.56.22 1.26-.1 1.26-.22 1.26z"
          style={{
            WebkitTransformOrigin: 449.44,
            MsTransformOrigin: 449.44,
            transformOrigin: 449.44,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M449.44 107.17c-.12 0-.22-.57-.22-1.27s.1-1.26.22-1.26.22.57.22 1.26-.1 1.27-.22 1.27z"
          style={{
            WebkitTransformOrigin: 449.44,
            MsTransformOrigin: 449.44,
            transformOrigin: 449.44,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M449.44 112.22c-.12 0-.22-.57-.22-1.27s.1-1.26.22-1.26.22.57.22 1.26-.1 1.27-.22 1.27z"
          style={{
            WebkitTransformOrigin: 449.44,
            MsTransformOrigin: 449.44,
            transformOrigin: 449.44,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M449.44 117.27c-.12 0-.22-.57-.22-1.27s.1-1.26.22-1.26.22.57.22 1.26-.1 1.27-.22 1.27z"
          style={{
            WebkitTransformOrigin: 449.44,
            MsTransformOrigin: 449.44,
            transformOrigin: 449.44,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M449.44 122.32c-.12 0-.22-.57-.22-1.27s.1-1.26.22-1.26.22.57.22 1.26-.1 1.27-.22 1.27z"
          style={{
            WebkitTransformOrigin: 449.44,
            MsTransformOrigin: 449.44,
            transformOrigin: 449.44,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M449.44 127.37c-.12 0-.22-.57-.22-1.26s.1-1.27.22-1.27.22.57.22 1.27-.1 1.26-.22 1.26z"
          style={{
            WebkitTransformOrigin: 449.44,
            MsTransformOrigin: 449.44,
            transformOrigin: 449.44,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M449.44 132.42c-.12 0-.22-.57-.22-1.26s.1-1.27.22-1.27.22.57.22 1.27-.1 1.26-.22 1.26z"
          style={{
            WebkitTransformOrigin: 449.44,
            MsTransformOrigin: 449.44,
            transformOrigin: 449.44,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M449.44 137.47c-.12 0-.22-.57-.22-1.26s.1-1.27.22-1.27.22.57.22 1.27-.1 1.26-.22 1.26z"
          style={{
            WebkitTransformOrigin: 449.44,
            MsTransformOrigin: 449.44,
            transformOrigin: 449.44,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M449.44 142.52c-.12 0-.22-.57-.22-1.26s.1-1.27.22-1.27.22.57.22 1.27-.1 1.26-.22 1.26z"
          style={{
            WebkitTransformOrigin: 449.44,
            MsTransformOrigin: 449.44,
            transformOrigin: 449.44,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 449.445,
            MsTransformOrigin: 449.445,
            transformOrigin: 449.445,
          }}
          fill="#263238"
          d="M447.16 141.38H451.73V145.95H447.16z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 387.755,
            MsTransformOrigin: 387.755,
            transformOrigin: 387.755,
          }}
          fill="#263238"
          d="M385.47 142.01H390.04V146.57999999999998H385.47z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 449.445,
            MsTransformOrigin: 449.445,
            transformOrigin: 449.445,
          }}
          fill="#263238"
          d="M447.16 79.88H451.73V84.44999999999999H447.16z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 387.755,
            MsTransformOrigin: 387.755,
            transformOrigin: 387.755,
          }}
          fill="#263238"
          d="M385.47 80.32H390.04V84.88999999999999H385.47z"
          className="animable"
        ></path>
        <path
          d="M102.11 233.4c0-.12.56-.22 1.26-.22s1.26.1 1.26.22-.56.22-1.26.22-1.26-.1-1.26-.22z"
          style={{
            WebkitTransformOrigin: 103.37,
            MsTransformOrigin: 103.37,
            transformOrigin: 103.37,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M97.06 233.4c0-.12.56-.22 1.26-.22s1.26.1 1.26.22-.56.22-1.26.22-1.26-.1-1.26-.22z"
          style={{
            WebkitTransformOrigin: 98.32,
            MsTransformOrigin: 98.32,
            transformOrigin: 98.32,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M92 233.4c0-.12.56-.22 1.26-.22s1.26.1 1.26.22-.56.22-1.26.22-1.26-.1-1.26-.22z"
          style={{
            WebkitTransformOrigin: 93.26,
            MsTransformOrigin: 93.26,
            transformOrigin: 93.26,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M87 233.4c0-.12.56-.22 1.26-.22s1.26.1 1.26.22-.56.22-1.26.22-1.26-.1-1.26-.22z"
          style={{
            WebkitTransformOrigin: 88.26,
            MsTransformOrigin: 88.26,
            transformOrigin: 88.26,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M81.9 233.4c0-.12.57-.22 1.27-.22s1.26.1 1.26.22-.57.22-1.26.22-1.27-.1-1.27-.22z"
          style={{
            WebkitTransformOrigin: 83.165,
            MsTransformOrigin: 83.165,
            transformOrigin: 83.165,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M76.85 233.4c0-.12.57-.22 1.27-.22s1.26.1 1.26.22-.57.22-1.26.22-1.27-.1-1.27-.22z"
          style={{
            WebkitTransformOrigin: 78.115,
            MsTransformOrigin: 78.115,
            transformOrigin: 78.115,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M71.8 233.4c0-.12.57-.22 1.27-.22s1.26.1 1.26.22-.57.22-1.26.22-1.27-.1-1.27-.22z"
          style={{
            WebkitTransformOrigin: 73.065,
            MsTransformOrigin: 73.065,
            transformOrigin: 73.065,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M66.75 233.4c0-.12.57-.22 1.27-.22s1.26.1 1.26.22-.57.22-1.26.22-1.27-.1-1.27-.22z"
          style={{
            WebkitTransformOrigin: 68.015,
            MsTransformOrigin: 68.015,
            transformOrigin: 68.015,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M61.7 233.4c0-.12.57-.22 1.26-.22s1.27.1 1.27.22-.57.22-1.27.22-1.26-.1-1.26-.22z"
          style={{
            WebkitTransformOrigin: 62.965,
            MsTransformOrigin: 62.965,
            transformOrigin: 62.965,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M56.65 233.4c0-.12.57-.22 1.26-.22s1.27.1 1.27.22-.57.22-1.27.22-1.26-.1-1.26-.22z"
          style={{
            WebkitTransformOrigin: 57.915,
            MsTransformOrigin: 57.915,
            transformOrigin: 57.915,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M51.6 233.4c0-.12.57-.22 1.26-.22s1.27.1 1.27.22-.57.22-1.27.22-1.26-.1-1.26-.22z"
          style={{
            WebkitTransformOrigin: 52.865,
            MsTransformOrigin: 52.865,
            transformOrigin: 52.865,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M46.55 233.4c0-.12.57-.22 1.26-.22s1.27.1 1.27.22-.57.22-1.27.22-1.26-.1-1.26-.22z"
          style={{
            WebkitTransformOrigin: 47.815,
            MsTransformOrigin: 47.815,
            transformOrigin: 47.815,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M43 232c.12 0 .22.57.22 1.26v.19l-.22-.21c.61 0 1.07.1 1.07.21s-.46.2-1.07.22h-.21v-.41c-.06-.74.04-1.26.21-1.26z"
          style={{
            WebkitTransformOrigin: 43.4212,
            MsTransformOrigin: 43.4212,
            transformOrigin: 43.4212,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M43 226.9c.12 0 .22.57.22 1.26s-.1 1.27-.22 1.27-.22-.57-.22-1.27.05-1.26.22-1.26z"
          style={{
            WebkitTransformOrigin: 43,
            MsTransformOrigin: 43,
            transformOrigin: 43,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M43 221.85c.12 0 .22.56.22 1.26s-.1 1.26-.22 1.26-.22-.56-.22-1.26.05-1.26.22-1.26z"
          style={{
            WebkitTransformOrigin: 43,
            MsTransformOrigin: 43,
            transformOrigin: 43,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M43 216.8c.12 0 .22.56.22 1.26s-.1 1.26-.22 1.26-.22-.56-.22-1.26.05-1.26.22-1.26z"
          style={{
            WebkitTransformOrigin: 43,
            MsTransformOrigin: 43,
            transformOrigin: 43,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M43 211.75c.12 0 .22.56.22 1.26s-.1 1.26-.22 1.26-.22-.56-.22-1.26.05-1.26.22-1.26z"
          style={{
            WebkitTransformOrigin: 43,
            MsTransformOrigin: 43,
            transformOrigin: 43,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M43 206.7c.12 0 .22.56.22 1.26s-.1 1.26-.22 1.26-.22-.56-.22-1.26.05-1.26.22-1.26z"
          style={{
            WebkitTransformOrigin: 43,
            MsTransformOrigin: 43,
            transformOrigin: 43,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M43 201.65c.12 0 .22.56.22 1.26s-.1 1.26-.22 1.26-.22-.56-.22-1.26.05-1.26.22-1.26z"
          style={{
            WebkitTransformOrigin: 43,
            MsTransformOrigin: 43,
            transformOrigin: 43,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M43 196.6c.12 0 .22.56.22 1.26s-.1 1.26-.22 1.26-.22-.56-.22-1.26.05-1.26.22-1.26z"
          style={{
            WebkitTransformOrigin: 43,
            MsTransformOrigin: 43,
            transformOrigin: 43,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M43 191.54c.12 0 .22.57.22 1.27s-.1 1.26-.22 1.26-.22-.57-.22-1.26.05-1.27.22-1.27z"
          style={{
            WebkitTransformOrigin: 43,
            MsTransformOrigin: 43,
            transformOrigin: 43,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M43 186.49c.12 0 .22.57.22 1.27S43.07 189 43 189s-.22-.57-.22-1.26.05-1.25.22-1.25z"
          style={{
            WebkitTransformOrigin: 43,
            MsTransformOrigin: 43,
            transformOrigin: 43,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M43 181.44c.12 0 .22.57.22 1.27S43.07 184 43 184s-.22-.57-.22-1.26.05-1.3.22-1.3z"
          style={{
            WebkitTransformOrigin: 43,
            MsTransformOrigin: 43,
            transformOrigin: 43,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M43 176.39c.12 0 .22.57.22 1.27s-.1 1.26-.22 1.26-.22-.57-.22-1.26.05-1.27.22-1.27z"
          style={{
            WebkitTransformOrigin: 43,
            MsTransformOrigin: 43,
            transformOrigin: 43,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M43.33 171.72c0 .06-.15.12-.38.16l.16-.16a5.44 5.44 0 01.06.88c0 .7-.1 1.27-.22 1.27s-.22-.57-.22-1.27a6.88 6.88 0 01.06-.88v-.18h.12c.27.06.42.12.42.18z"
          style={{
            WebkitTransformOrigin: 43.03,
            MsTransformOrigin: 43.03,
            transformOrigin: 43.03,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M48.38 171.72c0 .12-.57.22-1.26.22s-1.27-.1-1.27-.22.57-.22 1.27-.22 1.26.1 1.26.22z"
          style={{
            WebkitTransformOrigin: 47.115,
            MsTransformOrigin: 47.115,
            transformOrigin: 47.115,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M53.43 171.72c0 .12-.56.22-1.26.22s-1.26-.1-1.26-.22.56-.22 1.26-.22 1.26.1 1.26.22z"
          style={{
            WebkitTransformOrigin: 52.17,
            MsTransformOrigin: 52.17,
            transformOrigin: 52.17,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M58.48 171.72c0 .12-.56.22-1.26.22s-1.26-.1-1.26-.22.56-.22 1.26-.22 1.26.1 1.26.22z"
          style={{
            WebkitTransformOrigin: 57.22,
            MsTransformOrigin: 57.22,
            transformOrigin: 57.22,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M63.53 171.72c0 .12-.56.22-1.26.22s-1.26-.1-1.26-.22.56-.22 1.26-.22 1.26.1 1.26.22z"
          style={{
            WebkitTransformOrigin: 62.27,
            MsTransformOrigin: 62.27,
            transformOrigin: 62.27,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M68.58 171.72c0 .12-.56.22-1.26.22s-1.26-.1-1.26-.22.56-.22 1.26-.22 1.26.1 1.26.22z"
          style={{
            WebkitTransformOrigin: 67.32,
            MsTransformOrigin: 67.32,
            transformOrigin: 67.32,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M73.63 171.72c0 .12-.56.22-1.26.22s-1.26-.1-1.26-.22.56-.22 1.26-.22 1.26.1 1.26.22z"
          style={{
            WebkitTransformOrigin: 72.37,
            MsTransformOrigin: 72.37,
            transformOrigin: 72.37,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M78.68 171.72c0 .12-.56.22-1.26.22s-1.26-.1-1.26-.22.56-.22 1.26-.22 1.26.1 1.26.22z"
          style={{
            WebkitTransformOrigin: 77.42,
            MsTransformOrigin: 77.42,
            transformOrigin: 77.42,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M83.73 171.72c0 .12-.56.22-1.26.22s-1.26-.1-1.26-.22.56-.22 1.26-.22 1.26.1 1.26.22z"
          style={{
            WebkitTransformOrigin: 82.47,
            MsTransformOrigin: 82.47,
            transformOrigin: 82.47,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M88.79 171.72c0 .12-.57.22-1.27.22s-1.26-.1-1.26-.22.57-.22 1.26-.22 1.27.1 1.27.22z"
          style={{
            WebkitTransformOrigin: 87.525,
            MsTransformOrigin: 87.525,
            transformOrigin: 87.525,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M93.84 171.72c0 .12-.57.22-1.27.22s-1.26-.1-1.26-.22.57-.22 1.26-.22 1.27.1 1.27.22z"
          style={{
            WebkitTransformOrigin: 92.575,
            MsTransformOrigin: 92.575,
            transformOrigin: 92.575,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M98.89 171.72c0 .12-.57.22-1.27.22s-1.26-.1-1.26-.22.57-.22 1.26-.22 1.27.1 1.27.22z"
          style={{
            WebkitTransformOrigin: 97.625,
            MsTransformOrigin: 97.625,
            transformOrigin: 97.625,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M103.94 171.72c0 .12-.57.22-1.27.22s-1.26-.1-1.26-.22.57-.22 1.26-.22 1.27.1 1.27.22z"
          style={{
            WebkitTransformOrigin: 102.675,
            MsTransformOrigin: 102.675,
            transformOrigin: 102.675,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M104.63 176.08c-.12 0-.22-.57-.22-1.27s.1-1.26.22-1.26.22.57.22 1.26-.1 1.27-.22 1.27z"
          style={{
            WebkitTransformOrigin: 104.63,
            MsTransformOrigin: 104.63,
            transformOrigin: 104.63,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M104.63 181.13c-.12 0-.22-.57-.22-1.27s.1-1.26.22-1.26.22.57.22 1.26-.1 1.27-.22 1.27z"
          style={{
            WebkitTransformOrigin: 104.63,
            MsTransformOrigin: 104.63,
            transformOrigin: 104.63,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M104.63 186.18c-.12 0-.22-.57-.22-1.27s.1-1.26.22-1.26.22.57.22 1.26-.1 1.27-.22 1.27z"
          style={{
            WebkitTransformOrigin: 104.63,
            MsTransformOrigin: 104.63,
            transformOrigin: 104.63,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M104.63 191.23c-.12 0-.22-.57-.22-1.26s.1-1.27.22-1.27.22.57.22 1.27-.1 1.26-.22 1.26z"
          style={{
            WebkitTransformOrigin: 104.63,
            MsTransformOrigin: 104.63,
            transformOrigin: 104.63,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M104.63 196.28c-.12 0-.22-.57-.22-1.26s.1-1.27.22-1.27.22.57.22 1.27-.1 1.26-.22 1.26z"
          style={{
            WebkitTransformOrigin: 104.63,
            MsTransformOrigin: 104.63,
            transformOrigin: 104.63,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M104.63 201.33c-.12 0-.22-.57-.22-1.26s.1-1.27.22-1.27.22.57.22 1.27-.1 1.26-.22 1.26z"
          style={{
            WebkitTransformOrigin: 104.63,
            MsTransformOrigin: 104.63,
            transformOrigin: 104.63,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M104.63 206.38c-.12 0-.22-.57-.22-1.26s.1-1.27.22-1.27.22.57.22 1.27-.1 1.26-.22 1.26z"
          style={{
            WebkitTransformOrigin: 104.63,
            MsTransformOrigin: 104.63,
            transformOrigin: 104.63,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M104.63 211.43c-.12 0-.22-.56-.22-1.26s.1-1.26.22-1.26.22.56.22 1.26-.1 1.26-.22 1.26z"
          style={{
            WebkitTransformOrigin: 104.63,
            MsTransformOrigin: 104.63,
            transformOrigin: 104.63,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M104.63 216.48c-.12 0-.22-.56-.22-1.26s.1-1.26.22-1.26.22.56.22 1.26-.1 1.26-.22 1.26z"
          style={{
            WebkitTransformOrigin: 104.63,
            MsTransformOrigin: 104.63,
            transformOrigin: 104.63,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M104.63 221.53c-.12 0-.22-.56-.22-1.26s.1-1.26.22-1.26.22.56.22 1.26-.1 1.26-.22 1.26z"
          style={{
            WebkitTransformOrigin: 104.63,
            MsTransformOrigin: 104.63,
            transformOrigin: 104.63,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M104.63 226.58c-.12 0-.22-.56-.22-1.26s.1-1.26.22-1.26.22.56.22 1.26-.1 1.26-.22 1.26z"
          style={{
            WebkitTransformOrigin: 104.63,
            MsTransformOrigin: 104.63,
            transformOrigin: 104.63,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M104.63 231.63c-.12 0-.22-.56-.22-1.26s.1-1.26.22-1.26.22.56.22 1.26-.1 1.26-.22 1.26z"
          style={{
            WebkitTransformOrigin: 104.63,
            MsTransformOrigin: 104.63,
            transformOrigin: 104.63,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 104.635,
            MsTransformOrigin: 104.635,
            transformOrigin: 104.635,
          }}
          fill="#263238"
          d="M102.35 230.49H106.91999999999999V235.06H102.35z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 42.955,
            MsTransformOrigin: 42.955,
            transformOrigin: 42.955,
          }}
          fill="#263238"
          d="M40.67 231.12H45.24V235.69H40.67z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 104.635,
            MsTransformOrigin: 104.635,
            transformOrigin: 104.635,
          }}
          fill="#263238"
          d="M102.35 168.99H106.91999999999999V173.56H102.35z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 42.955,
            MsTransformOrigin: 42.955,
            transformOrigin: 42.955,
          }}
          fill="#263238"
          d="M40.67 169.44H45.24V174.01H40.67z"
          className="animable"
        ></path>
      </g>
      <g
        className="animable"
        style={{
          WebkitTransformOrigin: 277.034,
          MsTransformOrigin: 277.034,
          transformOrigin: 277.034,
        }}
      >
        <path
          style={{
            WebkitTransformOrigin: 263.105,
            MsTransformOrigin: 263.105,
            transformOrigin: 263.105,
          }}
          fill="#FFF"
          d="M125.3 190.49H400.91V447.94H125.3z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 263.105,
            MsTransformOrigin: 263.105,
            transformOrigin: 263.105,
          }}
          fill="#455A64"
          d="M125.3 190.22H400.91V207.96H125.3z"
          className="animable"
        ></path>
        <path
          d="M138.05 200.49a2.57 2.57 0 11-2.57-2.71 2.65 2.65 0 012.57 2.71z"
          style={{
            WebkitTransformOrigin: 135.482,
            MsTransformOrigin: 135.482,
            transformOrigin: 135.482,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M147.35 200.49a2.57 2.57 0 11-2.57-2.71 2.65 2.65 0 012.57 2.71z"
          style={{
            WebkitTransformOrigin: 144.782,
            MsTransformOrigin: 144.782,
            transformOrigin: 144.782,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M155.9 198.57a2.82 2.82 0 010 3.83 2.47 2.47 0 01-3.63 0 2.8 2.8 0 010-3.83 2.49 2.49 0 013.63 0z"
          style={{
            WebkitTransformOrigin: 154.081,
            MsTransformOrigin: 154.081,
            transformOrigin: 154.081,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M400.91 447.67c0-1.35-.07-102.73-.16-257.45l.16.16H125.33l.19-.19v257.45l-.16-.16 275.61.16-275.61.16h-.16v-.16V190H401.16v.16c-.16 154.78-.25 256.16-.25 257.51z"
          style={{
            WebkitTransformOrigin: 263.18,
            MsTransformOrigin: 263.18,
            transformOrigin: 263.18,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 249.52,
            MsTransformOrigin: 249.52,
            transformOrigin: 249.52,
          }}
          fill="#E34160"
          d="M242.64 221.31H256.4V235.07H242.64z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 267.06,
            MsTransformOrigin: 267.06,
            transformOrigin: 267.06,
          }}
          fill="#E34160"
          d="M260.18 221.31H273.94V235.07H260.18z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 284.59,
            MsTransformOrigin: 284.59,
            transformOrigin: 284.59,
          }}
          fill="#E34160"
          d="M277.71 221.31H291.46999999999997V235.07H277.71z"
          className="animable"
        ></path>
        <g>
          <path
            style={{
              WebkitTransformOrigin: 284.59,
              MsTransformOrigin: 284.59,
              transformOrigin: 284.59,
            }}
            fill="#FFF"
            d="M277.71 221.31H291.46999999999997V235.07H277.71z"
            className="animable"
            opacity="0.4"
          ></path>
        </g>
        <g>
          <path
            style={{
              WebkitTransformOrigin: 249.52,
              MsTransformOrigin: 249.52,
              transformOrigin: 249.52,
            }}
            d="M242.64 221.31H256.4V235.07H242.64z"
            className="animable"
            opacity="0.2"
          ></path>
        </g>
        <path
          d="M328.21 316.19v1.21c0 .8 0 2-.12 3.51a62.76 62.76 0 01-2.45 13.14c-.47 1.37-.86 2.85-1.51 4.32-.3.75-.6 1.51-.92 2.29s-.73 1.53-1.1 2.32c-.72 1.6-1.67 3.16-2.61 4.81-.47.82-1.05 1.6-1.58 2.42s-1.09 1.66-1.73 2.45a59.28 59.28 0 01-8.83 9.47 61.57 61.57 0 01-11.91 8.12l.11-.18c-.13 5.14-.27 10.59-.42 16.24v.22h-53.58v-.26-15.28l.15.23c-1.78-.94-3.75-1.85-5.58-3a60.5 60.5 0 01-29.29-51.1 60.16 60.16 0 015.43-26 61 61 0 0113.49-18.9 63.6 63.6 0 017.93-6.37 66.28 66.28 0 017.89-4.48 61.37 61.37 0 0113.76-4.57 65 65 0 019.1-1.12h3.2s-.27 0-.81.05l-2.38.09a67.57 67.57 0 00-9.07 1.21 61.61 61.61 0 00-13.67 4.64 64.18 64.18 0 00-7.83 4.48 62.37 62.37 0 00-7.86 6.37 60.78 60.78 0 00-13.36 18.8 59.85 59.85 0 00-5.34 25.86 60.08 60.08 0 0029.08 50.66c1.81 1.18 3.77 2.09 5.53 3l.15.08v15.38l-.26-.26h53.11l-.23.22c.16-5.65.31-11.09.46-16.23v-.13l.11-.05a61.66 61.66 0 0011.86-8 58.22 58.22 0 008.8-9.39c.64-.78 1.2-1.61 1.74-2.42s1.11-1.59 1.58-2.41c.94-1.64 1.89-3.18 2.61-4.77.38-.79.79-1.54 1.11-2.31s.63-1.53.93-2.27c.66-1.45 1.05-2.93 1.53-4.29A64.33 64.33 0 00328 320.9c.11-1.54.13-2.72.18-3.51s.03-1.2.03-1.2z"
          style={{
            WebkitTransformOrigin: 267.523,
            MsTransformOrigin: 267.523,
            transformOrigin: 267.523,
          }}
          fill="#E34160"
          className="animable"
        ></path>
        <path
          d="M275.7 386.05a7.09 7.09 0 01.06-.75c.06-.52.14-1.25.24-2.18.24-1.92.58-4.75 1.12-8.39s1.25-8.07 2.41-13.17A89.74 89.74 0 01285 344.7a57.43 57.43 0 015-9.07 11 11 0 014.14-3.72 6.25 6.25 0 015.75.19 4.08 4.08 0 011.92 2.4 4.24 4.24 0 01-.25 3.11 8.64 8.64 0 01-4.68 4 16.58 16.58 0 01-12.6-.89 16.82 16.82 0 01-8.56-10.11 12.65 12.65 0 01-.35-7 5.43 5.43 0 015.5-4.21 3.91 3.91 0 013.09 2.13 5.51 5.51 0 01.48 3.74c-.58 2.53-2.4 4.42-4.26 6a18.05 18.05 0 01-14.12 4.1 15.75 15.75 0 01-11.72-8.74 9.16 9.16 0 01-.41-7.34 4.68 4.68 0 012.66-2.68 4.54 4.54 0 013.73.35 6 6 0 012.56 2.66 9.37 9.37 0 01.92 3.49 17.36 17.36 0 01-.92 6.92 18 18 0 01-8.54 10.16 18.38 18.38 0 01-12.53 1.81 9.62 9.62 0 01-5.45-2.9 4.69 4.69 0 01-.34-5.83 3.92 3.92 0 012.72-1.33 5.76 5.76 0 012.9.56 8.8 8.8 0 013.7 4.16c1.53 3.28 2.75 6.48 4 9.53 4.84 12.21 8.28 22.27 10.58 29.24 1.15 3.49 2 6.21 2.57 8.06l.63 2.11c.14.47.2.72.2.72a7.13 7.13 0 01-.27-.7l-.7-2.08-2.7-8c-2.39-6.95-5.9-17-10.8-29.15-1.25-3-2.5-6.25-4-9.46a8.21 8.21 0 00-3.45-3.88c-1.55-.77-3.72-.76-4.88.62a4.08 4.08 0 00.35 5 9 9 0 005.11 2.66 17.7 17.7 0 0012.02-1.68 17.41 17.41 0 008.19-9.79 16.69 16.69 0 00.87-6.63c-.16-2.22-1.08-4.53-3.1-5.57a3.68 3.68 0 00-5.37 2 8.43 8.43 0 00.41 6.75 15 15 0 0011.16 8.3 17.34 17.34 0 0013.53-3.93c1.79-1.54 3.51-3.37 4-5.61a4.68 4.68 0 00-.41-3.23 3.19 3.19 0 00-2.51-1.76 4.32 4.32 0 00-3 1 5.25 5.25 0 00-1.73 2.72 12.09 12.09 0 00.34 6.58 16.1 16.1 0 008.18 9.7 15.93 15.93 0 0012.06.9 8 8 0 004.34-3.69 3.57 3.57 0 00-1.4-4.71 5.67 5.67 0 00-5.19-.18 10.65 10.65 0 00-3.93 3.52 57.73 57.73 0 00-5 8.95 90 90 0 00-5.57 16.74c-1.19 5.07-1.95 9.49-2.53 13.12s-1 6.41-1.25 8.36c-.13.93-.24 1.65-.32 2.17s-.07.69-.07.69z"
          style={{
            WebkitTransformOrigin: 268.58,
            MsTransformOrigin: 268.58,
            transformOrigin: 268.58,
          }}
          fill="#E34160"
          className="animable"
        ></path>
        <path
          d="M292.26 396.13a2.39 2.39 0 001.08-.38 2 2 0 00.75-1.1 10.7 10.7 0 000-1.92v-1.15a2.21 2.21 0 00-.22-1.18 1.91 1.91 0 00-.95-.84 3.65 3.65 0 00-1.43-.12h-7l-40.72.08a1.72 1.72 0 00-1.71 1.59v2.91a1.73 1.73 0 001.43 1.8h13.61l18.3.06 12.35.08 3.35.05h1.17-1.17l-3.35.05-12.35.08-18.3.05H243.41a2.27 2.27 0 01-1.87-2.31v-2.94a2.25 2.25 0 012.23-2.08l40.72.08h7a4 4 0 011.53.17 2.14 2.14 0 011.07 1 2.4 2.4 0 01.23 1.29v1.15a11.93 11.93 0 01-.1 2 2 2 0 01-.85 1.15 1.91 1.91 0 01-.83.3.86.86 0 01-.28.13z"
          style={{
            WebkitTransformOrigin: 267.935,
            MsTransformOrigin: 267.935,
            transformOrigin: 267.935,
          }}
          fill="#E34160"
          className="animable"
        ></path>
        <path
          d="M290.81 399.12a2.6 2.6 0 011 .09 3.49 3.49 0 012.55 3.72 3.54 3.54 0 01-1.08 2.2 3.64 3.64 0 01-2.65 1h-3.18l.14-.09-4.49 8.87-1.29 2.53-.67 1.31a4.57 4.57 0 01-3.88 2.43H274c-4.34 0-8.9.05-13.77 0a4.67 4.67 0 01-3.25-1.87 18.46 18.46 0 01-.93-1.65l-.85-1.66-3.55-6.76-1.65-3.15.23.14h-4.76a4 4 0 01-1.63-.28 3.67 3.67 0 011.06-7.06h8.65l19.49.05L286 399l3.54.05h1.23a1.2 1.2 0 01-.31 0h-.92l-3.54.05-13 .2-19.49.06h-8.55a3.17 3.17 0 00-2.32 1.34 3.15 3.15 0 001.4 4.74 3.44 3.44 0 001.44.24h4.92l.07.14 1.66 3.15 3.55 6.76.87 1.66a15.58 15.58 0 00.88 1.58 4.16 4.16 0 002.89 1.68c4.76.05 9.41 0 13.72 0h3.18a4.13 4.13 0 002.65-1.07 7.8 7.8 0 001.55-2.4c.44-.86.87-1.7 1.3-2.52 1.69-3.28 3.22-6.24 4.57-8.84v-.08h.1c2.19-.11 4.4.45 5.66-.87a3.31 3.31 0 00.7-3.94 3.41 3.41 0 00-2-1.7 9.1 9.1 0 00-.94-.11z"
          style={{
            WebkitTransformOrigin: 267.961,
            MsTransformOrigin: 267.961,
            transformOrigin: 267.961,
          }}
          fill="#E34160"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 295.205,
            MsTransformOrigin: 295.205,
            transformOrigin: 295.205,
          }}
          fill="#E34160"
          d="M293.81 369.62H296.6V372.41H293.81z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 294.915,
            MsTransformOrigin: 294.915,
            transformOrigin: 294.915,
          }}
          fill="#E34160"
          d="M293.52 384.46H296.31V387.25H293.52z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 241.795,
            MsTransformOrigin: 241.795,
            transformOrigin: 241.795,
          }}
          fill="#E34160"
          d="M240.4 384.46H243.19V387.25H240.4z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 241.795,
            MsTransformOrigin: 241.795,
            transformOrigin: 241.795,
          }}
          fill="#E34160"
          d="M240.4 369.62H243.19V372.41H240.4z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 267.615,
            MsTransformOrigin: 267.615,
            transformOrigin: 267.615,
          }}
          fill="#E34160"
          d="M266.22 254.2H269.01000000000005V256.99H266.22z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 328.205,
            MsTransformOrigin: 328.205,
            transformOrigin: 328.205,
          }}
          fill="#E34160"
          d="M326.81 314.8H329.6V317.59000000000003H326.81z"
          className="animable"
        ></path>
        <path
          d="M290.4 255.22c0 .14-10.59.26-23.64.26s-23.64-.12-23.64-.26 10.58-.26 23.64-.26 23.64.11 23.64.26z"
          style={{
            WebkitTransformOrigin: 266.76,
            MsTransformOrigin: 266.76,
            transformOrigin: 266.76,
          }}
          fill="#E34160"
          className="animable"
        ></path>
        <path
          d="M290.93 255.22a.83.83 0 11-.83-.83.83.83 0 01.83.83z"
          style={{
            WebkitTransformOrigin: 290.1,
            MsTransformOrigin: 290.1,
            transformOrigin: 290.1,
          }}
          fill="#E34160"
          className="animable"
        ></path>
        <circle
          cx="243.12"
          cy="255.22"
          r="0.83"
          style={{
            WebkitTransformOrigin: 243.12,
            MsTransformOrigin: 243.12,
            transformOrigin: 243.12,
          }}
          fill="#E34160"
          className="animable"
        ></circle>
        <path
          d="M330.12 283.38c-.06.14-13.89-6.06-30.88-13.84s-30.72-14.19-30.66-14.32 13.88 6.06 30.88 13.84 30.72 14.19 30.66 14.32z"
          style={{
            WebkitTransformOrigin: 299.35,
            MsTransformOrigin: 299.35,
            transformOrigin: 299.35,
          }}
          fill="#E34160"
          className="animable"
        ></path>
        <path
          d="M210 156.07c0 .1-1.49-.38-3.92-.71a28.9 28.9 0 00-4.28-.23 30.37 30.37 0 00-5.22.61 31 31 0 00-5 1.59 27.24 27.24 0 00-3.81 2c-2.08 1.29-3.22 2.33-3.28 2.25s.24-.3.75-.77c.26-.23.58-.52 1-.81s.83-.66 1.36-1a24.5 24.5 0 013.82-2.11 29.24 29.24 0 015.09-1.64 28.57 28.57 0 015.32-.59 25.11 25.11 0 014.35.35 16.73 16.73 0 011.64.34 9.88 9.88 0 011.21.34 4.71 4.71 0 01.97.38z"
          style={{
            WebkitTransformOrigin: 197.241,
            MsTransformOrigin: 197.241,
            transformOrigin: 197.241,
          }}
          fill="#FAFAFA"
          className="animable"
        ></path>
        <path
          d="M336.67 129.05l-27.87-9.26c-19.32-6.89-30.1-5.12-48.56 3.8-1.2.58-3.26 1.48-5.22 2.35l-32.57 14.38-23.29 10.17c-15.69 6.84-23.91 22.45-24.66 39.55l76.73.07 101.07-.05 17.56-31c-4.27-21.59-26.03-26.63-33.19-30.01z"
          style={{
            WebkitTransformOrigin: 272.18,
            MsTransformOrigin: 272.18,
            transformOrigin: 272.18,
          }}
          fill="#E34160"
          className="animable"
        ></path>
        <path
          d="M357.17 139.19l63.56 58.36a25.19 25.19 0 01-.91 37.88l-33.16 27.63-23.38-13.24 27.38-35.42-38.36-24.34z"
          style={{
            WebkitTransformOrigin: 390.584,
            MsTransformOrigin: 390.584,
            transformOrigin: 390.584,
          }}
          fill="#E34160"
          className="animable"
        ></path>
        <path
          d="M273.75 189.66a1.18 1.18 0 010-.28c0-.21 0-.47-.07-.8 0-.76-.11-1.8-.19-3.12-.15-2.75-.36-6.65-.62-11.46l.15.18a316.76 316.76 0 01-32.92-9.26c-6.54-2.17-12.6-4.75-18.3-6.42a45.4 45.4 0 00-8.25-1.67 28.33 28.33 0 00-7.31.19 21.48 21.48 0 00-9.71 4 18.89 18.89 0 00-2.13 1.95l-.5.56c-.12.13-.18.19-.19.18a13.21 13.21 0 012.69-2.85 21.2 21.2 0 019.78-4.21 28.08 28.08 0 017.41-.26 45 45 0 018.34 1.64c5.76 1.66 11.81 4.22 18.34 6.39 12.55 4.28 24.21 7.38 32.83 9.38h.14v.2c.19 4.81.34 8.72.45 11.47 0 1.32.07 2.37.09 3.12v.81a1.16 1.16 0 01-.03.26z"
          style={{
            WebkitTransformOrigin: 233.745,
            MsTransformOrigin: 233.745,
            transformOrigin: 233.745,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M260.26 170.81c-.14 0-.09-4.86.13-10.85s.5-10.85.64-10.85.09 4.87-.12 10.86-.5 10.85-.65 10.84z"
          style={{
            WebkitTransformOrigin: 260.645,
            MsTransformOrigin: 260.645,
            transformOrigin: 260.645,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M352 190.51a5.14 5.14 0 010-1.23c0-.89.08-2 .13-3.32.1-2.8.16-6.67.19-11s.11-8.16.25-11c.07-1.4.14-2.54.21-3.32a6.17 6.17 0 01.16-1.22 5.49 5.49 0 01.05 1.23c0 .89 0 2-.05 3.32 0 2.92-.06 6.74-.1 11s-.15 8.17-.35 11c-.09 1.4-.19 2.54-.29 3.32a5.42 5.42 0 01-.2 1.22z"
          style={{
            WebkitTransformOrigin: 352.484,
            MsTransformOrigin: 352.484,
            transformOrigin: 352.484,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M305.39 140.91a13.09 13.09 0 01-2.07 1.38c-1.12.74-1.94 1.49-2.05 1.39s.58-1 1.77-1.83a4.19 4.19 0 012.35-.94z"
          style={{
            WebkitTransformOrigin: 303.324,
            MsTransformOrigin: 303.324,
            transformOrigin: 303.324,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M308.4 150a4.45 4.45 0 01.64-2.8c.74-1.45 1.8-2.28 1.89-2.17s-.74 1-1.42 2.41-.96 2.56-1.11 2.56z"
          style={{
            WebkitTransformOrigin: 309.656,
            MsTransformOrigin: 309.656,
            transformOrigin: 309.656,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M318.61 151.55a7.55 7.55 0 01.29-5 8.66 8.66 0 010 2.5 8.77 8.77 0 01-.29 2.5z"
          style={{
            WebkitTransformOrigin: 318.653,
            MsTransformOrigin: 318.653,
            transformOrigin: 318.653,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M329.42 150a21.4 21.4 0 01-.8-2.82 20.53 20.53 0 01-.88-2.79 5.51 5.51 0 011.38 2.64 5.4 5.4 0 01.3 2.97z"
          style={{
            WebkitTransformOrigin: 328.623,
            MsTransformOrigin: 328.623,
            transformOrigin: 328.623,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M336.36 142.52c-.14.06-.63-.78-1.35-1.72s-1.39-1.64-1.3-1.76 1 .44 1.72 1.44 1.06 1.99.93 2.04z"
          style={{
            WebkitTransformOrigin: 335.045,
            MsTransformOrigin: 335.045,
            transformOrigin: 335.045,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M340.93 136.5c-.13.08-.88-1-2.2-1.91s-2.58-1.25-2.55-1.4a4.31 4.31 0 012.85 1 4.16 4.16 0 011.9 2.31z"
          style={{
            WebkitTransformOrigin: 338.555,
            MsTransformOrigin: 338.555,
            transformOrigin: 338.555,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M301.57 134c0 .15-1 .35-2 .91s-1.81 1.22-1.92 1.12.5-1 1.68-1.59a3 3 0 012.24-.44z"
          style={{
            WebkitTransformOrigin: 299.604,
            MsTransformOrigin: 299.604,
            transformOrigin: 299.604,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M300.46 127.79c-.08.12-.85-.25-1.86-.43s-1.87-.09-1.9-.24.85-.47 2-.27 1.85.83 1.76.94z"
          style={{
            WebkitTransformOrigin: 298.584,
            MsTransformOrigin: 298.584,
            transformOrigin: 298.584,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M273.46 185.63a13.23 13.23 0 01-3.65.26 13.39 13.39 0 01-3.65-.27 13.17 13.17 0 013.65-.26 13.45 13.45 0 013.65.27z"
          style={{
            WebkitTransformOrigin: 269.81,
            MsTransformOrigin: 269.81,
            transformOrigin: 269.81,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M273.25 180.62a12.09 12.09 0 01-3.46.4 11.63 11.63 0 01-3.48-.13 23.75 23.75 0 016.94-.27z"
          style={{
            WebkitTransformOrigin: 269.78,
            MsTransformOrigin: 269.78,
            transformOrigin: 269.78,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M273 176.36a12.19 12.19 0 01-3.56.13 12.47 12.47 0 01-3.54-.39 12.19 12.19 0 013.56-.13 12.47 12.47 0 013.54.39z"
          style={{
            WebkitTransformOrigin: 269.45,
            MsTransformOrigin: 269.45,
            transformOrigin: 269.45,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M408.13 218c-.07.06-.8-.71-2.21-1.68a17.38 17.38 0 00-2.6-1.45 18.49 18.49 0 00-3.46-1.11 18.88 18.88 0 00-3.62-.36 17.46 17.46 0 00-3 .3c-1.67.33-2.66.75-2.69.66s.22-.16.66-.36a10.09 10.09 0 011.95-.66 14.75 14.75 0 013-.42 17.13 17.13 0 013.74.33 16.62 16.62 0 013.55 1.19 15.08 15.08 0 012.62 1.58 11.43 11.43 0 011.53 1.38c.4.37.56.6.53.6z"
          style={{
            WebkitTransformOrigin: 399.341,
            MsTransformOrigin: 399.341,
            transformOrigin: 399.341,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M387 255.76a14.68 14.68 0 01-2 2.81 14 14 0 01-2.28 2.6 10 10 0 011.87-2.92 9.69 9.69 0 012.41-2.49z"
          style={{
            WebkitTransformOrigin: 384.86,
            MsTransformOrigin: 384.86,
            transformOrigin: 384.86,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M380.41 250.57a23.5 23.5 0 01-5.05 6.34 20.4 20.4 0 012.39-3.27 19.27 19.27 0 012.66-3.07z"
          style={{
            WebkitTransformOrigin: 377.885,
            MsTransformOrigin: 377.885,
            transformOrigin: 377.885,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M374.5 246.36a18.61 18.61 0 01-2.84 3.32 19 19 0 01-3.22 3 18.86 18.86 0 012.84-3.33 19.12 19.12 0 013.22-2.99z"
          style={{
            WebkitTransformOrigin: 371.47,
            MsTransformOrigin: 371.47,
            transformOrigin: 371.47,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <g>
          <g
            style={{
              WebkitTransformOrigin: 399.931,
              MsTransformOrigin: 399.931,
              transformOrigin: 399.931,
            }}
            className="animable"
            opacity="0.3"
          >
            <path
              d="M390.18 214.23a17.91 17.91 0 0114.91-3.63c1.8.39 3.74 1.25 4.4 3a2.25 2.25 0 01-.94 2.86c-1 .43-2.14-.29-3.09-.87a16.66 16.66 0 00-14.72-1.26l1.52-.28"
              className="animable"
              style={{
                WebkitTransformOrigin: 399.931,
                MsTransformOrigin: 399.931,
                transformOrigin: 399.931,
              }}
            ></path>
          </g>
        </g>
        <g>
          <g
            style={{
              WebkitTransformOrigin: 230.74,
              MsTransformOrigin: 230.74,
              transformOrigin: 230.74,
            }}
            className="animable"
            opacity="0.2"
          >
            <path
              d="M202 158.27c4.79-4 11.38-5.28 17.59-4.73s12.13 2.78 17.89 5.14c4 1.65 8 3.38 12.12 4.72a22.18 22.18 0 019.84 6.44l.85 1c-15.73-4.94-17.85-5.77-33.58-10.71A70 70 0 00214 157a23.45 23.45 0 00-12.81 1.72"
              className="animable"
              style={{
                WebkitTransformOrigin: 230.74,
                MsTransformOrigin: 230.74,
                transformOrigin: 230.74,
              }}
            ></path>
          </g>
        </g>
        <path
          d="M345.42 132.75s0 .08-.05.24l-.2.69a22.17 22.17 0 01-.94 2.58 32.57 32.57 0 01-5.28 8.61 29.58 29.58 0 01-12.16 8.63 28.07 28.07 0 01-18.16.65 25.66 25.66 0 01-14.16-11.3 22.29 22.29 0 01-2.79-7.54 23 23 0 01-.1-7 21.88 21.88 0 013.57-9.41 15.1 15.1 0 012.39-2.84 1.2 1.2 0 01-.15.2l-.46.55a9.16 9.16 0 00-.71.9 15 15 0 00-.91 1.3 22.32 22.32 0 00-3.37 9.35 22.61 22.61 0 00.17 6.88 22 22 0 002.77 7.37 25.25 25.25 0 0013.91 11 27.77 27.77 0 0017.83-.59 29.6 29.6 0 0012.05-8.43 34 34 0 005.38-8.45c.48-1.08.82-2 1-2.54.1-.27.19-.49.25-.67a2 2 0 01.12-.18z"
          style={{
            WebkitTransformOrigin: 318.39,
            MsTransformOrigin: 318.39,
            transformOrigin: 318.39,
          }}
          fill="#FAFAFA"
          className="animable"
        ></path>
        <path
          d="M376.83 157.64a1.38 1.38 0 01-.07.36 3.28 3.28 0 01-.52.95 4.76 4.76 0 01-3.72 1.63 9.39 9.39 0 01-3-.54c-1.05-.33-2.14-.8-3.35-1.16a25.7 25.7 0 00-8.5-1 45.52 45.52 0 00-10.27 1.74c-3.63 1-7.42 2.33-11.49 3.55a77.36 77.36 0 01-13 2.8 52.77 52.77 0 01-14.3-.12 40.51 40.51 0 01-13.56-4.54 33.91 33.91 0 01-5.61-3.89 39.48 39.48 0 01-4.57-4.68c-2.73-3.27-4.95-6.65-7.14-9.71a62.36 62.36 0 00-6.61-8.08 27.49 27.49 0 00-6.88-5.1 20.1 20.1 0 00-6.14-2 15.74 15.74 0 00-4.09-.11c-.94.09-1.42.21-1.43.18a8.78 8.78 0 011.42-.29 15.23 15.23 0 014.13 0 19.92 19.92 0 016.24 2 27.09 27.09 0 017 5.1 61.18 61.18 0 016.7 8.09c2.21 3.06 4.44 6.42 7.16 9.65a34.81 34.81 0 0010.06 8.44 40.37 40.37 0 0013.39 4.46 52.55 52.55 0 0014.16.14 77.62 77.62 0 0012.95-2.76c4.06-1.2 7.86-2.54 11.52-3.51a45.38 45.38 0 0110.37-1.69 26.11 26.11 0 018.59 1.08c1.23.38 2.32.87 3.35 1.2a9.39 9.39 0 002.88.58 4.77 4.77 0 003.64-1.49 3.7 3.7 0 00.69-1.28z"
          style={{
            WebkitTransformOrigin: 314.705,
            MsTransformOrigin: 314.705,
            transformOrigin: 314.705,
          }}
          fill="#FAFAFA"
          className="animable"
        ></path>
        <path
          d="M259.94 162.14a1.19 1.19 0 01-.45 0 7.59 7.59 0 01-1.28-.14 11.73 11.73 0 01-4.37-1.85 21.9 21.9 0 01-5.09-4.87c-1.66-2.06-3.24-4.5-5.22-6.77a23.28 23.28 0 00-6.61-5.25 24.68 24.68 0 00-6.5-2.35 33.22 33.22 0 00-6.39-.53 1.62 1.62 0 01.45-.08c.29 0 .72-.09 1.28-.1a21.35 21.35 0 014.73.35 23.51 23.51 0 0113.43 7.62c2 2.31 3.59 4.76 5.21 6.82a22.87 22.87 0 004.91 4.86 12.51 12.51 0 004.21 1.95c1.07.28 1.69.29 1.69.34z"
          style={{
            WebkitTransformOrigin: 241.985,
            MsTransformOrigin: 241.985,
            transformOrigin: 241.985,
          }}
          fill="#FAFAFA"
          className="animable"
        ></path>
        <path
          d="M350.78 189.76s0-.07-.13-.22l-.33-.66c-.14-.3-.33-.65-.57-1.06a14.34 14.34 0 00-.87-1.4 25.78 25.78 0 00-7.44-7.25 32.37 32.37 0 00-6.42-3.26 39.53 39.53 0 00-8-2 80.75 80.75 0 00-19-.1c-6.74.59-13.18 1.35-19.07 1.34a49.77 49.77 0 01-15.49-2.15 31.58 31.58 0 01-9.41-4.7 19.66 19.66 0 01-2.9-2.55s.08 0 .21.15l.56.48c.24.22.55.48.93.77s.81.64 1.32 1a32.38 32.38 0 009.4 4.51 50.26 50.26 0 0015.34 2c5.87 0 12.28-.78 19-1.38a79.47 79.47 0 0119.12.15 39 39 0 018.1 2.1 32.77 32.77 0 016.48 3.35 25.26 25.26 0 017.4 7.45 16.64 16.64 0 011.77 3.43z"
          style={{
            WebkitTransformOrigin: 305.965,
            MsTransformOrigin: 305.965,
            transformOrigin: 305.965,
          }}
          fill="#FAFAFA"
          className="animable"
        ></path>
        <path
          d="M391.31 171c0 .12-2.3.29-5.86 1.37l-1.38.44c-.49.16-1 .37-1.47.57-1 .37-2.07.91-3.17 1.43a37.62 37.62 0 00-12.22 10.05c-.73 1-1.46 1.9-2 2.83-.29.47-.59.9-.84 1.34s-.48.86-.7 1.27c-1.74 3.28-2.36 5.53-2.48 5.49a11.33 11.33 0 01.44-1.57 15.36 15.36 0 01.66-1.79 24.31 24.31 0 011.05-2.3l.68-1.3c.24-.46.54-.9.83-1.37a34.43 34.43 0 012-2.89 36 36 0 0112.38-10.18 33.44 33.44 0 013.23-1.41c.52-.19 1-.4 1.5-.55l1.41-.42a23.65 23.65 0 012.46-.58 13.8 13.8 0 011.88-.3 8.86 8.86 0 011.6-.13z"
          style={{
            WebkitTransformOrigin: 376.25,
            MsTransformOrigin: 376.25,
            transformOrigin: 376.25,
          }}
          fill="#FAFAFA"
          className="animable"
        ></path>
        <path
          d="M409.62 188.49a7.06 7.06 0 01-1.41.39c-.92.21-2.24.55-3.85 1s-3.5 1.14-5.55 2a62.69 62.69 0 00-6.48 3.16 64.55 64.55 0 00-6 3.94c-1.77 1.33-3.3 2.61-4.54 3.75s-2.2 2.09-2.85 2.77a8.25 8.25 0 01-1.06 1 7.23 7.23 0 01.91-1.16 39.51 39.51 0 012.75-2.89 49.77 49.77 0 014.46-3.85 57.91 57.91 0 016.06-4 59.38 59.38 0 016.56-3.14 51.44 51.44 0 015.63-1.88c1.63-.44 3-.73 3.9-.88a9.51 9.51 0 011.47-.21z"
          style={{
            WebkitTransformOrigin: 393.75,
            MsTransformOrigin: 393.75,
            transformOrigin: 393.75,
          }}
          fill="#FAFAFA"
          className="animable"
        ></path>
        <path
          d="M428.55 222.75a9.57 9.57 0 01-1.48.66 15.39 15.39 0 01-1.78.63 22.29 22.29 0 01-2.42.66l-1.43.31c-.5.11-1 .16-1.56.24a33.55 33.55 0 01-3.49.31 38.67 38.67 0 01-8-.59 39.26 39.26 0 01-7.65-2.33c-1.12-.52-2.19-1-3.13-1.55-.48-.27-.95-.51-1.38-.79l-1.22-.8a24.45 24.45 0 01-2-1.5 13.41 13.41 0 01-1.44-1.23 9.15 9.15 0 01-1.13-1.15c.08-.09 1.7 1.56 4.79 3.57l1.22.77c.43.26.9.49 1.37.75.94.54 2 1 3.12 1.49a37.32 37.32 0 0015.43 2.88 33 33 0 003.44-.26c.54-.07 1.06-.11 1.55-.2l1.42-.28c3.62-.76 5.72-1.7 5.77-1.59z"
          style={{
            WebkitTransformOrigin: 409.495,
            MsTransformOrigin: 409.495,
            transformOrigin: 409.495,
          }}
          fill="#FAFAFA"
          className="animable"
        ></path>
        <path
          d="M393.69 255.8a6.07 6.07 0 01-.31-1.17c-.19-.87-.43-1.94-.7-3.22a47 47 0 00-3.32-10.28 21.31 21.31 0 00-6.8-8.19 24 24 0 00-4-2.07 4.19 4.19 0 011.18.3 13 13 0 013 1.46 20.56 20.56 0 017.07 8.27 41.91 41.91 0 013.19 10.44c.25 1.37.43 2.48.54 3.25a5.47 5.47 0 01.15 1.21z"
          style={{
            WebkitTransformOrigin: 386.125,
            MsTransformOrigin: 386.125,
            transformOrigin: 386.125,
          }}
          fill="#FAFAFA"
          className="animable"
        ></path>
        <path
          d="M244.09 190.17a3 3 0 01-.13-.91 20.09 20.09 0 010-2.5 27.7 27.7 0 015.57-15.34 17.55 17.55 0 011.62-1.91c.42-.42.66-.65.68-.62s-.81 1-2 2.74a31.28 31.28 0 00-5.51 15.15c-.19 2.09-.14 3.39-.23 3.39z"
          style={{
            WebkitTransformOrigin: 247.876,
            MsTransformOrigin: 247.876,
            transformOrigin: 247.876,
          }}
          fill="#FAFAFA"
          className="animable"
        ></path>
        <path
          d="M205.87 190a7.23 7.23 0 010-1.47 14.78 14.78 0 01.12-1.71c.08-.66.14-1.43.31-2.25s.32-1.74.59-2.7.57-2 .92-3a39.38 39.38 0 013.09-6.57 40.47 40.47 0 014.32-5.83c.77-.81 1.49-1.6 2.24-2.25a24.52 24.52 0 012.1-1.79 20.4 20.4 0 011.84-1.34 13.8 13.8 0 011.45-.92 8.08 8.08 0 011.29-.69c.06.1-1.77 1.11-4.35 3.24-.64.53-1.34 1.12-2 1.82s-1.42 1.44-2.17 2.25a40.74 40.74 0 00-4.23 5.78 41.41 41.41 0 00-3.09 6.46c-.36 1-.72 2-1 3s-.47 1.84-.64 2.66c-.66 3.2-.66 5.31-.79 5.31z"
          style={{
            WebkitTransformOrigin: 214.987,
            MsTransformOrigin: 214.987,
            transformOrigin: 214.987,
          }}
          fill="#FAFAFA"
          className="animable"
        ></path>
        <path
          d="M302 122.67c-.1.11-.87-.52-1.94-1s-2-.79-2-.94a3.15 3.15 0 012.23.48c1.13.52 1.79 1.37 1.71 1.46z"
          style={{
            WebkitTransformOrigin: 300.033,
            MsTransformOrigin: 300.033,
            transformOrigin: 300.033,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M312.07 67.16a53.52 53.52 0 01-.79-10.48 18 18 0 013.26-9.85 10.86 10.86 0 019-4.5c2.16.14 4.26 1.07 6.42.87 2.5-.23 4.58-1.91 6.79-3.09a18.26 18.26 0 0111.38-1.93 10.9 10.9 0 015.59 2.39 6.12 6.12 0 012.12 5.51 29 29 0 017.56.18 9.78 9.78 0 016.31 3.86 6 6 0 01-.18 7.05 4.84 4.84 0 015.57 3.3 6.25 6.25 0 01-2.1 6.41 11.64 11.64 0 01-7.73 2.12c-2.37-.3-5.09-.78-7.08-2.1"
          style={{
            WebkitTransformOrigin: 343.283,
            MsTransformOrigin: 343.283,
            transformOrigin: 343.283,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M315.26 146.41a18.16 18.16 0 0021.22-14.32c1.08-5.47 1.95-10 1.95-10s14.2 1.51 18-12.07 9.78-45.5 9.78-45.5A49 49 0 00317 57.76l-2.43 1-13.71 66.09a18.16 18.16 0 0014.4 21.56z"
          style={{
            WebkitTransformOrigin: 333.343,
            MsTransformOrigin: 333.343,
            transformOrigin: 333.343,
          }}
          fill="#B78876"
          className="animable"
        ></path>
        <path
          d="M338.51 121.85a32.82 32.82 0 01-16.42-9.79s1.58 10.93 15.51 13.33z"
          style={{
            WebkitTransformOrigin: 330.3,
            MsTransformOrigin: 330.3,
            transformOrigin: 330.3,
          }}
          fill="#AA6550"
          className="animable"
        ></path>
        <path
          d="M312.51 73.34c-.21-.16-8.63-5.26-10.81 4.13s7.69 9.65 7.77 9.39 3.04-13.52 3.04-13.52z"
          style={{
            WebkitTransformOrigin: 306.948,
            MsTransformOrigin: 306.948,
            transformOrigin: 306.948,
          }}
          fill="#B78876"
          className="animable"
        ></path>
        <path
          d="M307.43 82.63s-.19.08-.5.14a1.65 1.65 0 01-1.22-.26c-.91-.59-1.38-2.36-1-4.07a5.53 5.53 0 011-2.24 2 2 0 011.52-1 .86.86 0 01.86.68c.07.29 0 .46 0 .49s.24-.11.26-.51a1.11 1.11 0 00-.24-.68 1.23 1.23 0 00-.85-.45 2.38 2.38 0 00-2.05 1.06 5.7 5.7 0 00-1.21 2.52c-.38 1.9.17 3.9 1.44 4.61A1.82 1.82 0 00307 83c.36-.15.46-.34.43-.37z"
          style={{
            WebkitTransformOrigin: 306.116,
            MsTransformOrigin: 306.116,
            transformOrigin: 306.116,
          }}
          fill="#AA6550"
          className="animable"
        ></path>
        <path
          d="M356.23 86.31a2 2 0 01-2.4 1.47 1.94 1.94 0 01-1.54-2.29 2 2 0 012.4-1.47 1.92 1.92 0 011.54 2.29z"
          style={{
            WebkitTransformOrigin: 354.262,
            MsTransformOrigin: 354.262,
            transformOrigin: 354.262,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M358.52 83c-.31.19-1.49-1.3-3.59-1.85s-3.91.09-4.07-.23c-.08-.14.29-.54 1.09-.85a5.47 5.47 0 013.34-.12 5.28 5.28 0 012.77 1.78c.51.62.61 1.14.46 1.27z"
          style={{
            WebkitTransformOrigin: 354.717,
            MsTransformOrigin: 354.717,
            transformOrigin: 354.717,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M334.85 80.88a2 2 0 01-2.4 1.46 1.92 1.92 0 01-1.54-2.29 2 2 0 012.4-1.46 1.93 1.93 0 011.54 2.29z"
          style={{
            WebkitTransformOrigin: 332.879,
            MsTransformOrigin: 332.879,
            transformOrigin: 332.879,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M337.83 77.85c-.31.19-1.5-1.29-3.59-1.85s-3.91.09-4.07-.23c-.08-.14.29-.53 1.09-.84a5.55 5.55 0 013.34-.13 5.29 5.29 0 012.77 1.79c.51.66.63 1.18.46 1.26z"
          style={{
            WebkitTransformOrigin: 334.031,
            MsTransformOrigin: 334.031,
            transformOrigin: 334.031,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M341.29 95.41a14.46 14.46 0 013.56.29c.55.09 1.09.12 1.28-.22a2.93 2.93 0 00.06-1.68c-.16-1.43-.32-2.93-.49-4.5-.66-6.38-1-11.57-.72-11.6s1 5.13 1.65 11.52c.14 1.57.29 3.07.42 4.5a3.24 3.24 0 01-.28 2.17 1.39 1.39 0 01-1.09.55 3.64 3.64 0 01-.94-.12 14.36 14.36 0 01-3.45-.91z"
          style={{
            WebkitTransformOrigin: 344.208,
            MsTransformOrigin: 344.208,
            transformOrigin: 344.208,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M334.74 95.21c.35.07-.25 2.35 1.26 4.48s4 2.53 3.94 2.85c0 .15-.66.3-1.69 0a5.83 5.83 0 01-3.32-2.25 5.11 5.11 0 01-.91-3.71c.16-.93.57-1.42.72-1.37z"
          style={{
            WebkitTransformOrigin: 336.955,
            MsTransformOrigin: 336.955,
            transformOrigin: 336.955,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M358.73 79.06c-.5.37-1.81-.5-3.53-1s-3.29-.53-3.5-1.12c-.08-.28.28-.7 1-1a5.31 5.31 0 015.66 1.7c.52.68.64 1.23.37 1.42z"
          style={{
            WebkitTransformOrigin: 355.279,
            MsTransformOrigin: 355.279,
            transformOrigin: 355.279,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M337.12 69.56c-.3.19-.81-.06-1.47-.38a16.06 16.06 0 00-2.49-1 15.51 15.51 0 00-2.65-.52c-.73-.1-1.29-.18-1.44-.51s.23-.84 1.12-1.25a5.68 5.68 0 016.7 2c.52.83.52 1.48.23 1.66z"
          style={{
            WebkitTransformOrigin: 333.177,
            MsTransformOrigin: 333.177,
            transformOrigin: 333.177,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M365.77 68.83a33.08 33.08 0 01-19.61-6.31C339.81 58 332 55 330 56.09s-3.52 8.15-10.69 9.12c0 0-1.71 13.65-7.05 12.29s1.44-21.82 1.44-21.82l14.3-4.33 14.9-.35 13.53 2.18L368.38 59z"
          style={{
            WebkitTransformOrigin: 339.295,
            MsTransformOrigin: 339.295,
            transformOrigin: 339.295,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M382.51 260.71s-15 23-18.46 23.34c-.87.1-1.73.78-3.88 1.19-.45.08-2.24-.43-2.74-.37-8 1-19.74 2.15-20-.25-.41-3.68 3.42-3.91 6.75-4.47a63.14 63.14 0 006.26-1.49 3.57 3.57 0 002.54-3c.47-3.49 1.34-9.95 1.62-11.48.39-2.11 10.29-10.35 10.29-10.35z"
          style={{
            WebkitTransformOrigin: 359.955,
            MsTransformOrigin: 359.955,
            transformOrigin: 359.955,
          }}
          fill="#B78876"
          className="animable"
        ></path>
        <path
          d="M340.13 277.37l32.79-8.15A2.54 2.54 0 01376 271a2.54 2.54 0 01-1.81 3.15l-32.62 8.4a1.8 1.8 0 01-2.19-1.22l-.52-1.64a1.82 1.82 0 011.27-2.32z"
          style={{
            WebkitTransformOrigin: 357.434,
            MsTransformOrigin: 357.434,
            transformOrigin: 357.434,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M359.17 272.64s-8.46 3-15 5.79a6.15 6.15 0 01-2.7.53 2.6 2.6 0 01-2.28-1.32c-.56-1.17.91-1.7 1.92-2.51s2.54-1.94 3.82-2.94a7.57 7.57 0 002.85-4.24c1.83-7.41 9.64-15.87 17.06-14.08l1.84-2.1 15.79 8.94"
          style={{
            WebkitTransformOrigin: 360.768,
            MsTransformOrigin: 360.768,
            transformOrigin: 360.768,
          }}
          fill="#B78876"
          className="animable"
        ></path>
        <path
          d="M335.32 277.55l-5.4 5.19.13.43 5.06-1.55a.58.58 0 01.4-.62.59.59 0 01.35 1.13.58.58 0 01-.68-.29l-5.06 1.54.12.42 7.55 1.34a3 3 0 011.67-3.46l-.71-2.38c-2.37.7-3.43-1.75-3.43-1.75z"
          style={{
            WebkitTransformOrigin: 334.69,
            MsTransformOrigin: 334.69,
            transformOrigin: 334.69,
          }}
          fill="#E34160"
          className="animable"
        ></path>
        <path
          d="M360.7 281.85a1.06 1.06 0 01-.25.77 6 6 0 01-1.59 1.55 5.14 5.14 0 01-1.38.74 12.6 12.6 0 01-1.73.35c-1.22.19-2.56.24-4 .34-2.81.17-5.35.29-7.19.36a17.92 17.92 0 01-3 0 16.64 16.64 0 013-.37c1.83-.16 4.37-.34 7.18-.51 1.4-.1 2.74-.14 3.93-.29a12.64 12.64 0 001.66-.3 5 5 0 001.3-.62 7.25 7.25 0 001.63-1.36c.35-.39.39-.67.44-.66z"
          style={{
            WebkitTransformOrigin: 351.132,
            MsTransformOrigin: 351.132,
            transformOrigin: 351.132,
          }}
          fill="#AA6550"
          className="animable"
        ></path>
        <path
          d="M365.55 281.43c.05 0-.17.52-.67 1.22a7.28 7.28 0 01-2.53 2.23 5.09 5.09 0 01-3.34.49 3.85 3.85 0 01-1-.29c-.22-.1-.33-.16-.32-.2s.15 0 .38 0a9.27 9.27 0 001 .13 5.68 5.68 0 003.06-.59 8.82 8.82 0 002.48-2c.54-.63.88-1.03.94-.99z"
          style={{
            WebkitTransformOrigin: 361.623,
            MsTransformOrigin: 361.623,
            transformOrigin: 361.623,
          }}
          fill="#AA6550"
          className="animable"
        ></path>
        <path
          d="M359.48 272.15a14.79 14.79 0 01-2.47 1.23c-1.54.7-3.69 1.63-6.09 2.61s-4.59 1.82-6.2 2.36a7 7 0 01-2.72.65s.25-.08.69-.25 1.08-.4 1.87-.71l6.13-2.49c2.4-1 4.57-1.86 6.15-2.47a15 15 0 012.64-.93z"
          style={{
            WebkitTransformOrigin: 350.74,
            MsTransformOrigin: 350.74,
            transformOrigin: 350.74,
          }}
          fill="#AA6550"
          className="animable"
        ></path>
        <path
          d="M273.25 175.56s35.26-.12 39.91 14.49l-39.25.13z"
          style={{
            WebkitTransformOrigin: 293.205,
            MsTransformOrigin: 293.205,
            transformOrigin: 293.205,
          }}
          fill="#B78876"
          className="animable"
        ></path>
      </g>
      <g
        className="animable"
        style={{
          WebkitTransformOrigin: 264.35,
          MsTransformOrigin: 264.35,
          transformOrigin: 264.35,
        }}
      >
        <path
          style={{
            WebkitTransformOrigin: 403.8,
            MsTransformOrigin: 403.8,
            transformOrigin: 403.8,
          }}
          fill="#FFF"
          d="M342.12 343.31H465.48V391.26H342.12z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 403.8,
            MsTransformOrigin: 403.8,
            transformOrigin: 403.8,
          }}
          fill="#E34160"
          d="M342.12 343.31H465.48V353.59H342.12z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 355.165,
            MsTransformOrigin: 355.165,
            transformOrigin: 355.165,
          }}
          fill="#FFF"
          d="M349.07 366.41H361.26V378.6H349.07z"
          className="animable"
        ></path>
        <path
          d="M361.26 378.6a5.45 5.45 0 01-.05-.92c0-.65 0-1.48-.06-2.5 0-2.2-.07-5.2-.12-8.77l.23.23h-12.19c-.06.06.49-.49.26-.25v12.16l-.23-.22 8.76.11 2.51.06c.58 0 .9 0 .92.05a3.85 3.85 0 01-.83 0l-2.45.06c-2.13 0-5.2.1-8.91.12h-.22v-.23-5.75-6.41c-.23.23.32-.32.26-.27h12.41v.22c0 3.71-.07 6.79-.11 8.91 0 1-.05 1.82-.06 2.45a6.74 6.74 0 01-.12.95z"
          style={{
            WebkitTransformOrigin: 355.187,
            MsTransformOrigin: 355.187,
            transformOrigin: 355.187,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 355.165,
            MsTransformOrigin: 355.165,
            transformOrigin: 355.165,
          }}
          fill="#FAFAFA"
          d="M361.26 366.41L349.07 378.6"
          className="animable"
        ></path>
        <path
          d="M349.07 378.6a71.91 71.91 0 015.91-6.28 73.83 73.83 0 016.28-5.91 70.47 70.47 0 01-5.91 6.28 71.91 71.91 0 01-6.28 5.91z"
          style={{
            WebkitTransformOrigin: 355.165,
            MsTransformOrigin: 355.165,
            transformOrigin: 355.165,
          }}
          fill="#E34160"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 371.025,
            MsTransformOrigin: 371.025,
            transformOrigin: 371.025,
          }}
          fill="#263238"
          d="M364.93 366.41H377.12V378.6H364.93z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 386.885,
            MsTransformOrigin: 386.885,
            transformOrigin: 386.885,
          }}
          fill="#455A64"
          d="M380.79 366.41H392.98V378.6H380.79z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 402.745,
            MsTransformOrigin: 402.745,
            transformOrigin: 402.745,
          }}
          fill="#E34160"
          d="M396.65 366.41H408.84V378.6H396.65z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 418.605,
            MsTransformOrigin: 418.605,
            transformOrigin: 418.605,
          }}
          fill="#E34160"
          d="M412.51 366.41H424.7V378.6H412.51z"
          className="animable"
        ></path>
        <g>
          <g
            style={{
              WebkitTransformOrigin: 418.605,
              MsTransformOrigin: 418.605,
              transformOrigin: 418.605,
            }}
            className="animable"
            opacity="0.5"
          >
            <path
              style={{
                WebkitTransformOrigin: 418.605,
                MsTransformOrigin: 418.605,
                transformOrigin: 418.605,
              }}
              fill="#FFF"
              d="M412.51 366.41H424.7V378.6H412.51z"
              className="animable"
            ></path>
          </g>
        </g>
        <path
          style={{
            WebkitTransformOrigin: 434.455,
            MsTransformOrigin: 434.455,
            transformOrigin: 434.455,
          }}
          fill="#E34160"
          d="M428.36 366.41H440.55V378.6H428.36z"
          className="animable"
        ></path>
        <g>
          <g
            style={{
              WebkitTransformOrigin: 434.455,
              MsTransformOrigin: 434.455,
              transformOrigin: 434.455,
            }}
            className="animable"
            opacity="0.3"
          >
            <path
              className="animable"
              style={{
                WebkitTransformOrigin: 434.455,
                MsTransformOrigin: 434.455,
                transformOrigin: 434.455,
              }}
              d="M428.36 366.41H440.55V378.6H428.36z"
            ></path>
          </g>
        </g>
        <path
          style={{
            WebkitTransformOrigin: 450.315,
            MsTransformOrigin: 450.315,
            transformOrigin: 450.315,
          }}
          fill="#E34160"
          d="M444.22 366.41H456.41V378.6H444.22z"
          className="animable"
        ></path>
        <g>
          <g
            style={{
              WebkitTransformOrigin: 450.315,
              MsTransformOrigin: 450.315,
              transformOrigin: 450.315,
            }}
            className="animable"
            opacity="0.7"
          >
            <path
              className="animable"
              style={{
                WebkitTransformOrigin: 450.315,
                MsTransformOrigin: 450.315,
                transformOrigin: 450.315,
              }}
              d="M444.22 366.41H456.41V378.6H444.22z"
            ></path>
          </g>
        </g>
        <path
          d="M465.47 391.27v-.86-2.48c0-2.19 0-5.39-.05-9.53 0-8.26-.06-20.23-.1-35.09l.18.18-123.34.08.26-.26v48l-.25-.25 88.38.11 25.69.07h9.28-9.17l-25.63.07-88.55.12h-.25v-.25-48.26h.27l123.34.08h.18v.18c0 14.91-.07 26.91-.09 35.2 0 4.12 0 7.31-.05 9.49v2.45c0 .57-.1.95-.1.95z"
          style={{
            WebkitTransformOrigin: 403.815,
            MsTransformOrigin: 403.815,
            transformOrigin: 403.815,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 120.415,
            MsTransformOrigin: 120.415,
            transformOrigin: 120.415,
          }}
          fill="#FFF"
          d="M63.15 65.36H177.68V149H63.15z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 120.415,
            MsTransformOrigin: 120.415,
            transformOrigin: 120.415,
          }}
          fill="#E34160"
          d="M63.15 65.36H177.68V75.81H63.15z"
          className="animable"
        ></path>
        <path
          d="M69.37 70.88a1.2 1.2 0 11-2.39 0 1.2 1.2 0 112.39 0z"
          style={{
            WebkitTransformOrigin: 68.175,
            MsTransformOrigin: 68.175,
            transformOrigin: 68.175,
          }}
          fill="#FFF"
          className="animable"
        ></path>
        <path
          d="M74.22 70.88A1.2 1.2 0 1173 69.57a1.26 1.26 0 011.22 1.31z"
          style={{
            WebkitTransformOrigin: 73.0231,
            MsTransformOrigin: 73.0231,
            transformOrigin: 73.0231,
          }}
          fill="#FFF"
          className="animable"
        ></path>
        <path
          d="M79.06 70.88a1.2 1.2 0 11-2.39 0 1.2 1.2 0 112.39 0z"
          style={{
            WebkitTransformOrigin: 77.865,
            MsTransformOrigin: 77.865,
            transformOrigin: 77.865,
          }}
          fill="#FFF"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 82.2,
            MsTransformOrigin: 82.2,
            transformOrigin: 82.2,
          }}
          fill="#E34160"
          d="M74.04 86.05H90.36000000000001V127.11H74.04z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 107.37,
            MsTransformOrigin: 107.37,
            transformOrigin: 107.37,
          }}
          fill="#EBEBEB"
          d="M99.21 86.05H115.53V127.11H99.21z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 132.54,
            MsTransformOrigin: 132.54,
            transformOrigin: 132.54,
          }}
          fill="#FAFAFA"
          d="M124.38 86.05H140.7V127.11H124.38z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 157.71,
            MsTransformOrigin: 157.71,
            transformOrigin: 157.71,
          }}
          fill="#E0E0E0"
          d="M149.55 86.37H165.87V127.43H149.55z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 121.615,
            MsTransformOrigin: 121.615,
            transformOrigin: 121.615,
          }}
          fill="#E34160"
          d="M72.15 131.65H171.08V141.03H72.15z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 168.315,
            MsTransformOrigin: 168.315,
            transformOrigin: 168.315,
          }}
          fill="#263238"
          d="M163.56 139.11L165.69 150.41 167.44 147.25 171.19 152.88 173.07 151.62 169.32 145.99 172.77 145.46 163.56 139.11z"
          className="animable"
        ></path>
        <path
          d="M177.68 149v-1.53-4.44c0-3.9 0-9.6-.06-16.89 0-14.57-.06-35.45-.11-60.77l.21.21-114.52.05.26-.26V149l-.24-.23 82.61.12 23.51.06h8.41-8.31l-23.45.06-82.77.12h-.23V149 65.1h.27l114.52.05h.22v.21c-.05 25.38-.09 46.31-.12 60.9 0 7.27 0 13-.05 16.84v4.4c0 1-.15 1.5-.15 1.5z"
          style={{
            WebkitTransformOrigin: 120.495,
            MsTransformOrigin: 120.495,
            transformOrigin: 120.495,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 122.87,
            MsTransformOrigin: 122.87,
            transformOrigin: 122.87,
          }}
          fill="#FFF"
          d="M64.56 390.45H181.18V435.78999999999996H64.56z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 122.87,
            MsTransformOrigin: 122.87,
            transformOrigin: 122.87,
          }}
          fill="#E34160"
          d="M64.56 390.45H181.18V400.17H64.56z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 84.145,
            MsTransformOrigin: 84.145,
            transformOrigin: 84.145,
          }}
          fill="#E34160"
          d="M78.42 407.57H89.87V419.02H78.42z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 91.085,
            MsTransformOrigin: 91.085,
            transformOrigin: 91.085,
          }}
          fill="#E34160"
          d="M85.36 414.51H96.81V425.96H85.36z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 111.445,
            MsTransformOrigin: 111.445,
            transformOrigin: 111.445,
          }}
          fill="#E34160"
          d="M105.72 407.57H117.17V419.02H105.72z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 118.385,
            MsTransformOrigin: 118.385,
            transformOrigin: 118.385,
          }}
          fill="#FFF"
          d="M112.66 414.51H124.11V425.96H112.66z"
          className="animable"
        ></path>
        <path
          d="M124.11 426a6.42 6.42 0 010-.87c0-.61 0-1.4-.06-2.35 0-2-.09-4.84-.12-8.23l.23.23h-11.45l.26-.26v11.4l-.22-.22c3.38 0 6.22.07 8.23.11l2.35.06h.87a3.51 3.51 0 01-.78.06l-2.3.05c-2 .05-4.89.1-8.37.12h-.23v-.1-5.4-6.07l.26-.26h11.68v.22c0 3.49-.07 6.38-.11 8.38 0 .93 0 1.69-.06 2.3a4.13 4.13 0 01-.18.83z"
          style={{
            WebkitTransformOrigin: 118.49,
            MsTransformOrigin: 118.49,
            transformOrigin: 118.49,
          }}
          fill="#E34160"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 136.915,
            MsTransformOrigin: 136.915,
            transformOrigin: 136.915,
          }}
          fill="#FFF"
          d="M131.19 407.57H142.64V419.02H131.19z"
          className="animable"
        ></path>
        <path
          d="M142.64 419v-.87c0-.55 0-1.4-.06-2.35 0-2-.09-4.85-.11-8.24l.22.23h-11.45l.26-.25v11.47l-.23-.23c3.39 0 6.22.07 8.23.12l2.36.06a4.68 4.68 0 01.86 0 5.76 5.76 0 01-.77.05l-2.3.06c-2 0-4.89.09-8.38.11H131V419v-5.41-5.99c-.23.23.32-.32.26-.27h11.68v.22c0 3.49-.07 6.38-.11 8.38 0 .93 0 1.7-.06 2.3a3.74 3.74 0 01-.13.77z"
          style={{
            WebkitTransformOrigin: 136.942,
            MsTransformOrigin: 136.942,
            transformOrigin: 136.942,
          }}
          fill="#E34160"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 143.855,
            MsTransformOrigin: 143.855,
            transformOrigin: 143.855,
          }}
          fill="#FFF"
          d="M138.13 414.51H149.57999999999998V425.96H138.13z"
          className="animable"
        ></path>
        <path
          d="M149.58 426a6.42 6.42 0 01-.05-.87c0-.61 0-1.4-.06-2.35 0-2-.09-4.84-.11-8.23l.22.23h-11.45l.26-.26v11.47l-.22-.22c3.38 0 6.22.07 8.23.11l2.35.06h.87a3.51 3.51 0 01-.78.06l-2.3.05c-2 .05-4.89.1-8.37.12h-.23V426v-5.4-6.07l.26-.26h11.68v.22c0 3.49-.08 6.38-.12 8.38 0 .93 0 1.69-.06 2.3a4.13 4.13 0 01-.12.83z"
          style={{
            WebkitTransformOrigin: 143.91,
            MsTransformOrigin: 143.91,
            transformOrigin: 143.91,
          }}
          fill="#E34160"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 140.39,
            MsTransformOrigin: 140.39,
            transformOrigin: 140.39,
          }}
          fill="#E34160"
          d="M138.13 414.51H142.65V419.03H138.13z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 160.335,
            MsTransformOrigin: 160.335,
            transformOrigin: 160.335,
          }}
          fill="#E34160"
          d="M154.61 407.57H166.06V419.02H154.61z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 167.275,
            MsTransformOrigin: 167.275,
            transformOrigin: 167.275,
          }}
          fill="#E34160"
          d="M161.55 414.51H173V425.96H161.55z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 163.81,
            MsTransformOrigin: 163.81,
            transformOrigin: 163.81,
          }}
          fill="#FAFAFA"
          d="M161.55 414.51H166.07000000000002V419.03H161.55z"
          className="animable"
        ></path>
        <path
          d="M181.18 435.79v-.81-2.35c0-2.07 0-5.1-.05-9 0-7.81 0-19.12-.1-33.17l.18.18-116.61.08.26-.26v45.34l-.25-.25 83.56.12 24.28.07h8.78-8.66l-24.23.07-83.78.19h-.25v-.25-45.6h.27l116.61.08h.19v.18c0 14.09-.07 25.44-.09 33.28 0 3.89 0 6.91-.05 9V435c0 .53-.06.79-.06.79z"
          style={{
            WebkitTransformOrigin: 122.845,
            MsTransformOrigin: 122.845,
            transformOrigin: 122.845,
          }}
          fill="#263238"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 129.83,
            MsTransformOrigin: 129.83,
            transformOrigin: 129.83,
          }}
          fill="#FFF"
          d="M89.37 220.15H170.29000000000002V358.95000000000005H89.37z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 129.83,
            MsTransformOrigin: 129.83,
            transformOrigin: 129.83,
          }}
          fill="#E34160"
          d="M89.37 220.15H170.29000000000002V231.85H89.37z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 114.14,
            MsTransformOrigin: 114.14,
            transformOrigin: 114.14,
          }}
          fill="#E34160"
          d="M108.81 246.44L109.79 259.39 113.42 254.67 119.47 254.42 108.81 246.44z"
          className="animable"
        ></path>
        <path
          d="M143.79 246.44a1 1 0 01.19.13l.58.42 2.21 1.61 7.76 5.7.34.25h-.43l-5.84.25h-.21l.12-.07-3.63 4.72-.24.32v-.39c-.25-3.74-.46-7-.62-9.39-.07-1.07-.12-2-.17-2.66v-.7a1.14 1.14 0 010-.22v.27c0 .21 0 .45.07.73.06.72.14 1.62.24 2.71l.78 9.23-.26-.08 3.61-4.73h.33l5.84-.23-.08.27-7.54-5.76-2.21-1.7-.6-.47z"
          style={{
            WebkitTransformOrigin: 149.33,
            MsTransformOrigin: 149.33,
            transformOrigin: 149.33,
          }}
          fill="#E34160"
          className="animable"
        ></path>
        <path
          d="M106.46 302.82c-.06-.06 4-4.33 9.05-9.54s9.22-9.38 9.28-9.32-4 4.33-9.05 9.54-9.22 9.38-9.28 9.32z"
          style={{
            WebkitTransformOrigin: 115.625,
            MsTransformOrigin: 115.625,
            transformOrigin: 115.625,
          }}
          fill="#E34160"
          className="animable"
        ></path>
        <path
          d="M162.64 293.26a20.06 20.06 0 00-.41-2.89 12.11 12.11 0 00-4.22-6.56 11.3 11.3 0 00-11.09-1.73 10.77 10.77 0 00-2.94 1.66 11 11 0 00-2.42 2.54 12.13 12.13 0 000 14 11.14 11.14 0 002.43 2.55 10.73 10.73 0 002.94 1.65 11.39 11.39 0 006.17.55 11.59 11.59 0 004.92-2.27 12.15 12.15 0 004.22-6.56 20.26 20.26 0 00.41-2.89v.76a7 7 0 01-.08.92 8.6 8.6 0 01-.22 1.24 12 12 0 01-4.19 6.7 11.5 11.5 0 01-11.33 1.82 10.85 10.85 0 01-3-1.68 11.5 11.5 0 01-2.5-2.61 12.43 12.43 0 010-14.31 11.41 11.41 0 012.49-2.6 11.15 11.15 0 013-1.69 11.54 11.54 0 0111.32 1.82 12.05 12.05 0 014.2 6.71 8.6 8.6 0 01.22 1.24 7 7 0 01.08.92c.01.45 0 .71 0 .71z"
          style={{
            WebkitTransformOrigin: 150.857,
            MsTransformOrigin: 150.857,
            transformOrigin: 150.857,
          }}
          fill="#E34160"
          className="animable"
        ></path>
        <path
          d="M152.16 324.09l.29.22c.22.2.5.43.83.71l3.17 2.75.07.06-.07.07-13.61 15.6v.05h-.06l-1.64.25-2.94.45h-.22v-.22c.37-1.86.72-3.65 1.06-5.35 3.68-4.34 6.89-8 9.21-10.62s3.79-4 3.87-3.93a2.42 2.42 0 01-.28.25c-.17.17-.43.43-.75.78-.65.69-1.56 1.72-2.7 3-2.28 2.61-5.46 6.33-9.12 10.68v-.06l-1 5.35-.18-.18 2.95-.45 1.63-.24-.09.05 13.7-15.52v.13l-3.07-2.79-.81-.75a2.84 2.84 0 01-.24-.29z"
          style={{
            WebkitTransformOrigin: 147.25,
            MsTransformOrigin: 147.25,
            transformOrigin: 147.25,
          }}
          fill="#E34160"
          className="animable"
        ></path>
        <path
          d="M122.56 343V341.57v-4c0-3.46 0-8.31-.07-14.1l.14.13h-19l.15-.15v19.5l-.13-.13 13.71.06h5.23a1.91 1.91 0 01-.33 0h-4.84l-13.8.06h-.14V333.8v-10.3l.16-.16h19.14v.14c0 5.83-.06 10.71-.07 14.19v4.95a2 2 0 01-.15.38z"
          style={{
            WebkitTransformOrigin: 113.13,
            MsTransformOrigin: 113.13,
            transformOrigin: 113.13,
          }}
          fill="#E34160"
          className="animable"
        ></path>
        <path
          d="M106.46 323.5a28.78 28.78 0 01-4.11.15 28.78 28.78 0 01-4.11-.15 26.29 26.29 0 014.11-.16 26.29 26.29 0 014.11.16z"
          style={{
            WebkitTransformOrigin: 102.35,
            MsTransformOrigin: 102.35,
            transformOrigin: 102.35,
          }}
          fill="#E34160"
          className="animable"
        ></path>
        <path
          d="M103.59 327.25a67.07 67.07 0 010-9.26 71.53 71.53 0 010 9.26z"
          style={{
            WebkitTransformOrigin: 103.585,
            MsTransformOrigin: 103.585,
            transformOrigin: 103.585,
          }}
          fill="#E34160"
          className="animable"
        ></path>
        <path
          d="M170.29 358.94v-.68-2-7.64c0-6.68 0-16.4-.07-28.67 0-24.52-.06-59.18-.11-99.83l.24.24H89.44l.26-.26c0 52.94 0 101.51-.06 138.79l-.2-.2 59 .11h21.9-5.62l-16.15.06-59.15.1h-.2v-.2c0-37.28 0-85.85-.06-138.79v-.27H170.58v.25c-.05 40.71-.09 75.43-.12 100 0 12.25 0 21.95-.06 28.61v9.59c0 .5-.11.79-.11.79z"
          style={{
            WebkitTransformOrigin: 129.87,
            MsTransformOrigin: 129.87,
            transformOrigin: 129.87,
          }}
          fill="#263238"
          className="animable"
        ></path>
      </g>
      <defs>
        <filter id="active" height="200%">
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="2"
            result="DILATED"
          ></feMorphology>
          <feFlood
            floodColor="#32DFEC"
            floodOpacity="1"
            result="PINK"
          ></feFlood>
          <feComposite
            in="PINK"
            in2="DILATED"
            operator="in"
            result="OUTLINE"
          ></feComposite>
          <feMerge>
            <feMergeNode in="OUTLINE"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
        <filter id="hover" height="200%">
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="2"
            result="DILATED"
          ></feMorphology>
          <feFlood floodColor="red" floodOpacity="0.5" result="PINK"></feFlood>
          <feComposite
            in="PINK"
            in2="DILATED"
            operator="in"
            result="OUTLINE"
          ></feComposite>
          <feMerge>
            <feMergeNode in="OUTLINE"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
          <feColorMatrix values="0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
        </filter>
      </defs>
    </svg>
  );
}
