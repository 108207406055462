const experienceHeader = {
    title: "Education",
    subTitle: "Basic Qualification and Certifications",
    description: "I actively participate technology activities. Below are some of my major certifications."
}

const degrees = {
    degrees: [
        {
            title: "ITI - Information Technology Institute",
            subtitle: "Front-End and Cross-Platform Mobile",
            logo_path: "iti.png",
            alt_name: "iti",
            duration: "Jan 2021 - Jun 2021",
            descriptions: [
                "⚡ OOP Concepts using C#",
                "⚡ JS Fundamentals and Advanced",
                "⚡ ES Next",
                "⚡ Node.js Fundamentals",
                "⚡ HTML5 and CSS3",
                "⚡ Responsive Web Fundamentals",
                "⚡ Bootstrap",
                "⚡ Angular",
                "⚡ React.js",
                "⚡ UX Design Principles for Mobile Apps",
                "⚡ Flutter",
                "⚡ React Native",
                "⚡ Version Control",
                "⚡ JS Packaging and Bundling Tools",
                "⚡ JS Unit Test Frameworks"
            ],
            website_link: "https://www.iti.gov.eg/",
        },
        {
            title: "ITI - Information Technology Institute",
            subtitle: "Software Engineering Fundamentals",
            logo_path: "iti.png",
            alt_name: "iti",
            duration: "Sep 2020 - Dec 2020",
            descriptions: [
                "⚡ Operating Systems",
                "⚡ Computer Networks",
                "⚡ Windows/Linux OS",
                "⚡ Flowcharts and Pseudocode",
                "⚡ C",
                "⚡ C++",
                "⚡ OOP using C++",
                "⚡ Database Fundamentals",
                "⚡ Data Structures",
                "⚡ HTML & CSS",
                "⚡ JavaScript",
                "⚡ Bootstrap",
                "⚡ PHP",
                "⚡ WordPress",
                "⚡ Cloud Computing",
                "⚡ Freelance Skills",
                "⚡ Soft and Presentation Skills"
            ],
            website_link: "https://www.iti.gov.eg/",
        },
    ],
};

const certifications = {
    certifications: [
        {
            title: "Using React Hooks",
            subtitle: "Plurasight",
            logo_path: "plurasight.png",
            certificate_link:
                "https://drive.google.com/file/d/1xizCvWtIcSAHNOzFw-5OfW5Lios_jQNA/view?usp=sharing",
            alt_name: "Using React Hooks",
            color_code: "#F09E40",
        },
        {
            title: "JavaScript Developer",
            subtitle: "Mahara-Tech",
            logo_path: "maharahtech.png",
            certificate_link:
                "https://drive.google.com/file/d/18g9jlnSxWk3vN9cno9Vpy9mw7fNF0844/view?usp=sharing",
            alt_name: "JavaScript Developer",
            color_code: "#2c2f33",
        },
        {
            title: "Building Applications with React and Redux",
            subtitle: "Plurasight",
            logo_path: "plurasight.png",
            certificate_link:
                "https://drive.google.com/file/d/1T-1ZswnAspDGRgQJRpm28zjqKZL1Ly8e/view?usp=sharing",
            alt_name: "Building Applications with React and Redux",
            color_code: "#F09E40",
        },
        {
            title: "Software Engineering Fundamentals",
            subtitle: "ITI - Information Technology Institute",
            logo_path: "iti_logo.png",
            certificate_link:
                "https://drive.google.com/file/d/1lLytNrIIsQewOMXLtViI1CXdn4DaILZf/view?usp=sharing",
            alt_name: "Software Engineering Fundamentals",
            color_code: "#b6bfbc",
        },
        {
            title: "Web Development Professional",
            subtitle: "Udacity Nanodegree Program Graduate",
            logo_path: "udacity.png",
            certificate_link:
                "https://graduation.udacity.com/confirm/WKWDQNKM",
            alt_name: "Web Development Professional",
            color_code: "#2E3945",
        },
        {
            title: "Become a Software Developer",
            subtitle: "LinkedIn Learning",
            logo_path: "linkedin-learning.png",
            certificate_link:
                "https://drive.google.com/file/d/1a9kdBHBeAi7AWYuStK0DIOs3-hka1bxV/view?usp=sharing",
            alt_name: "Become a Software Developer",
            color_code: "#c0e97b",
        },
        {
            title: "Foundations of User Experience (UX) Design",
            subtitle: "Coursera - authorized by Google",
            logo_path: "google.png",
            certificate_link:
                "https://www.coursera.org/account/accomplishments/verify/MUSN7FB4REDZ",
            alt_name: "Foundations of User Experience (UX) Design",
            color_code: "#22986099",
        },
        {
            title: "Web Development Challenger",
            subtitle: "FWD Powerd By Udacity",
            logo_path: "udacity.png",
            certificate_link:
                "https://drive.google.com/file/d/1aN7iwHQJNO035P7ipmydC1d25P1h4mju/view?usp=sharing",
            alt_name: "Web Development Challenger",
            color_code: "#2E3945",
        },
        {
            title: "React.JS",
            subtitle: "Mahara-Tech",
            logo_path: "maharahtech.png",
            certificate_link:
                "https://drive.google.com/file/d/1JUc33JKlf0t_ilNiwltZU8fJv1a2EXmA/view?usp=sharing",
            alt_name: "React.JS",
            color_code: "#2c2f33",
        },
        {
            title: "M001: MongoDB Basics",
            subtitle: "MongoDB University",
            logo_path: "mongo.png",
            certificate_link:
                "https://drive.google.com/file/d/1bs-EWCP7StgrTcNuNkA_XsVj1D7_1Z7v/view?usp=sharing",
            alt_name: "M001: MongoDB Basics",
            color_code: "#47A048",
        },
        {
            title: "Introduction to mongoDB",
            subtitle: "Mahara-Tech",
            logo_path: "maharahtech.png",
            certificate_link:
                "https://drive.google.com/file/d/1C5aOgdoq-UEfPScO3a_L5ZrKqz1hRenU/view?usp=sharing",
            alt_name: "Introduction to mongoDB",
            color_code: "#2c2f33",
        },

        {
            title: "JavaScript Essential Training (2017)",
            subtitle: "LinkedIn Learning",
            logo_path: "linkedin-learning.png",
            certificate_link:
                "https://drive.google.com/file/d/1ZF7Mw5dJArSlTZ9elwhA2qKcTaZ-Fpjp/view?usp=sharing",
            alt_name: "JavaScript Essential Training (2017)",
            color_code: "#c0e97b",
        },
        {
            title: "Front End Development Track",
            subtitle: "Udacity - 1 Million Arab Coders Initiative",
            logo_path: "udacity.png",
            certificate_link:
                "https://drive.google.com/file/d/1SL4XzJbBwkoDz4RZSQnr6C3XMyq--kGJ/view?usp=sharing",
            alt_name: "Web Development Professional",
            color_code: "#2E3945",
        },
        {
            title: "Intro to Coding",
            subtitle: "IBM",
            logo_path: "codingIntro.png",
            certificate_link:
                "https://www.credly.com/badges/00be2ecd-1801-47ad-bc85-b654fc56afd3",
            alt_name: "Intro to Coding",
            color_code: "#211e43",
        },
        {
            title: "Create Your Own Web Page",
            subtitle: "IBM",
            logo_path: "Create_Web_Page_Innovator_Award.png",
            certificate_link:
                "https://www.credly.com/badges/1f33227b-57b4-4ed8-a9bb-7fb8c8869283",
            alt_name: "Create Your Own Web Page",
            color_code: "#211e43",
        },
        {
            title: "Programming Foundations: Web Security",
            subtitle: "LinkedIn Learning",
            logo_path: "linkedin-learning.png",
            certificate_link:
                "https://drive.google.com/file/d/1MBNvok8FCY1WYO1iV9psbUHvKLStT8Kj/view?usp=sharing",
            alt_name: "Programming Foundations: Web Security",
            color_code: "#c0e97b",
        },
        {
            title: "Digital Marketing Challenger",
            subtitle: "FWD Powerd By Udacity",
            logo_path: "udacity.png",
            certificate_link:
                "https://drive.google.com/file/d/15pd-nYuhsJENJ5ZS22jLZjQfAsDYy3Zx/view?usp=sharing",
            alt_name: "Digital Marketing Challenger",
            color_code: "#2E3945",
        },
        {
            title: "Learning SQL Programming",
            subtitle: "LinkedIn Learning",
            logo_path: "linkedin-learning.png",
            certificate_link:
                "https://drive.google.com/file/d/1UQ5WNU0nTqpldi-lUWoQFT2wjDAx_UBF/view?usp=sharing",
            alt_name: "Learning SQL Programming",
            color_code: "#c0e97b",
        },
        {
            title: "Modern JavaScript: ES6 and beyond",
            subtitle: "Mahara-Tech",
            logo_path: "maharahtech.png",
            certificate_link:
                "https://drive.google.com/file/d/1gTeq3ljh3zFC1ZNWKQ5AaLY3L7AenR2F/view?usp=sharing",
            alt_name: "Modern JavaScript: ES6 and beyond",
            color_code: "#2c2f33",
        },
        {
            title: "Programming Foundations: Fundamentals",
            subtitle: "LinkedIn Learning",
            logo_path: "linkedin-learning.png",
            certificate_link:
                "https://drive.google.com/file/d/1KuDwhT2BXUGqaFGmrCH6EXGonFe5m-R1/view?usp=sharing",
            alt_name: "Programming Foundations: Fundamentals",
            color_code: "#c0e97b",
        },
        {
            title: "Maharat Min Google",
            subtitle: "Google",
            logo_path: "google.png",
            certificate_link:
                "https://drive.google.com/file/d/1-O6dsUaXhKnVFAqNn9K-oesBVXfaUQHk/view?usp=sharing",
            alt_name: "Maharat Min Google",
            color_code: "#22986099",
        },
        {
            title: "JavaScript",
            subtitle: "Mahara-Tech",
            logo_path: "maharahtech.png",
            certificate_link:
                "https://drive.google.com/file/d/1Dy4sz0SbfHuTyaEFETYCjmyQpQM1e046/view?usp=sharing",
            alt_name: "JavaScript",
            color_code: "#2c2f33",
        },
        {
            title: "Database Fundamentals",
            subtitle: "Mahara-Tech",
            logo_path: "maharahtech.png",
            certificate_link:
                "https://drive.google.com/file/d/1-dsx31c9IzvfmoVJ71MTvJFS8m4Z49xz/view?usp=sharing",
            alt_name: "Database Fundamentals",
            color_code: "#2c2f33",
        },
        {
            title: "Computer Network Fundamentals",
            subtitle: "Mahara-Tech",
            logo_path: "maharahtech.png",
            certificate_link:
                "https://drive.google.com/file/d/1oDoHvSAklZSWC9hPW46TRRr8aEJcHOCa/view?usp=sharing",
            alt_name: "Computer Network Fundamentals",
            color_code: "#2c2f33",
        },
    ],
};

export { experienceHeader, degrees, certifications }