import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Educations from "../../containers/education/Educations";
import Certifications from "../../containers/certifications/Certifications";
import { experienceHeader } from "../../Models/education";
import { Fade } from "react-reveal";
import EducationImg from "./EducationImg";
import "../../App.css";
import "./EducationComponent.css";

function Education({ theme, setTheme }) {
    return (
        <div className="page-main">
            <Header theme={theme} setTheme={setTheme} />
            <div className="basic-education">
                <Fade bottom duration={2000} distance="40px">
                    <div className="heading-div">
                        <div className="heading-img-div">
                            <EducationImg theme={theme} />
                        </div>
                        <div className="heading-text-div">
                            <h1 className="heading-text" style={{ color: theme.text }}>
                                {experienceHeader.title}
                            </h1>
                            <h3 className="heading-sub-text" style={{ color: theme.text }}>
                                {experienceHeader.subTitle}
                            </h3>
                            <p
                                className="experience-header-detail-text subTitle"
                                style={{ color: theme.secondaryText }}
                            >
                                {experienceHeader.description}
                            </p>
                        </div>
                    </div>
                </Fade>
                <Educations theme={theme} />
                <Certifications theme={theme} />
            </div>
            <Footer theme={theme} />
        </div>
    );
}

export default Education;
