import React from "react";
import { Route, Switch, HashRouter } from "react-router-dom";
import Home from "./pages/home/Home";
import Splash from "./pages/splash/Splash";
import Education from "./pages/education/EducationComponent";
import Experience from "./pages/experience/Experience";
import Contact from "./pages/contact/ContactComponent";
import Portfolio from "./pages/portfolio/Portfolio";
import settings from "./settings";
import Admin from "./pages/admin/Admin";

export default function Main({ theme, setTheme }) {
    return (
        <>
            <HashRouter basename="/">
                <Switch>
                    <Route
                        path="/"
                        exact
                        render={(props) => (
                            <Splash {...props} theme={theme} setTheme={setTheme} />
                        )}
                    />
                    <Route
                        path="/admin"
                        exact
                        render={(props) => (
                            <Admin {...props} theme={theme} setTheme={setTheme} />
                        )}
                    />
                    <Route
                        path="/home"
                        render={(props) => (
                            <Home {...props} theme={theme} setTheme={setTheme} />
                        )}
                    />
                    <Route
                        path="/experience"
                        exact
                        render={(props) => (
                            <Experience {...props} theme={theme} setTheme={setTheme} />
                        )}
                    />
                    <Route
                        path="/education"
                        render={(props) => (
                            <Education {...props} theme={theme} setTheme={setTheme} />
                        )}
                    />
                    <Route
                        path="/contact"
                        render={(props) => (
                            <Contact {...props} theme={theme} setTheme={setTheme} />
                        )}
                    />
                    {settings.isSplash && (
                        <Route
                            path="/splash"
                            render={(props) => (
                                <Splash {...props} theme={theme} setTheme={setTheme} />
                            )}
                        />
                    )}
                    <Route
                        path="/projects"
                        render={(props) => (
                            <Portfolio {...props} theme={theme} setTheme={setTheme} />
                        )}
                    />
                </Switch>
            </HashRouter>
        </>

    );
}
