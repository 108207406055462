const greeting = {
    title: "Hello",
    icon: " 👋.",
    full_name: "Mostafa Abdelhamid",
    subTitle:
        "Frontend, Mobile Cross-Platform and WordPress Developer. Always learning.",
    resumeLink:
        "https://drive.google.com/file/d/1EPX-L5HQdT8nmPeKiU4zL_nK7kSf0Ic7/view?usp=sharing",
    mail: "mailto:info@mostafa-abdelhamid.com",
};

const socialMediaLinks = {
    github: "https://github.com/mhsmustafa84",
    linkedin: "https://www.linkedin.com/in/mhsmustafa84/",
    facebook: "https://www.facebook.com/mhsmustafa84",
    twitter: "https://twitter.com/mhsmustafa84"
};

const skills = {
    data: [
        {
            title: "Front-End Web Development",
            fileName: "FrontImg",
            skills: [
                "⚡ Develop highly interactive Front-End / User Interfaces for your web.",
                "⚡ Building responsive websites & develop it in this manner from the early stages.",
                "⚡ Ensure that web pages are optimized for optimum speed and scalability.",
                "⚡ Integration of third party services such as Firebase.",
                "⚡ Experience hosting and managing websites",
            ],
            softwareSkills: [
                {
                    skillName: "HTML5",
                    dataIcon: "simple-icons:html5",
                    style: {
                        color: "#E34F26",
                    },
                },
                {
                    skillName: "CSS3",
                    dataIcon: "simple-icons:css3",
                    style: {
                        color: "#1572B6",
                    },
                },
                {
                    skillName: "Bootstrap",
                    dataIcon: "simple-icons:bootstrap",
                    style: {
                        color: "#533B78",
                    },
                },
                {
                    skillName: "JavaScript",
                    dataIcon: "simple-icons:javascript",
                    style: {
                        color: "#EFD81D",
                    },
                },
                {
                    skillName: "jQuery",
                    dataIcon: "simple-icons:jquery",
                    style: {
                        color: "#0865A6",
                    },
                },
                {
                    skillName: "React.JS",
                    dataIcon: "simple-icons:react",
                    style: {
                        color: "#61DAFB",
                    },
                },
                {
                    skillName: "Redux",
                    dataIcon: "simple-icons:redux",
                    style: {
                        color: "#7248B6",
                    },
                },
                {
                    skillName: "TypeScript",
                    dataIcon: "simple-icons:typescript",
                    style: {
                        color: "#2F74C0",
                    },
                },
                {
                    skillName: "Angular",
                    dataIcon: "simple-icons:angular",
                    style: {
                        color: "#C3002F",
                    },
                },
                {
                    skillName: "NPM",
                    faClassName: "fab fa-npm",
                    style: {
                        color: "#CB3837",
                    },
                },
                {
                    skillName: "Firebase",
                    dataIcon: "simple-icons:firebase",
                    style: {
                        color: "#FFCA28",
                    },
                },
                {
                    skillName: "MongoDB",
                    dataIcon: "simple-icons:mongodb",
                    style: {
                        color: "#439743",
                    },
                },
                {
                    skillName: "MS SQL Server",
                    dataIcon: "simple-icons:microsoftsqlserver",
                    style: {
                        color: "#AA171C",
                    },
                },
                {
                    skillName: "Git",
                    dataIcon: "simple-icons:git",
                    style: {
                        color: "#E94E32",
                    },
                },
                {
                    skillName: "GitHub",
                    faClassName: "fab fa-github",
                    style: {
                        backgroundColor: "#fff",
                        borderRadius: "50%",
                        color: "#000",
                    },
                },
            ],
        },
        {
            title: "Cross-Platform Mobile Development",
            fileName: "MobileImg",
            skills: [
                "⚡ Creating apps across any platform for any device.",
                "⚡ Building a high quality User Interface (UI).",
                "⚡ Aware of the user's needs to create the applications they want to use.",
                "⚡ Recognize various technical problems and understand how to resolve them.",
                "⚡ Thinking creatively about how users incorporate their mobile devices into their lives, and then build applications that help them complete various tasks.",
            ],
            softwareSkills: [
                {
                    skillName: "Dart",
                    dataIcon: "simple-icons:dart",
                    style: {
                        color: "#29B0EE",
                    },
                },
                {
                    skillName: "Flutter",
                    dataIcon: "simple-icons:flutter",
                    style: {
                        color: "#02569B",
                    },
                },
                {
                    skillName: "JavaScript",
                    dataIcon: "simple-icons:javascript",
                    style: {
                        color: "#EFD81D",
                    },
                },
                {
                    skillName: "React Native",
                    dataIcon: "simple-icons:react",
                    style: {
                        color: "#61DAFB",
                    },
                },
                {
                    skillName: "Firebase",
                    dataIcon: "simple-icons:firebase",
                    style: {
                        color: "#FFCA28",
                    },
                },
                {
                    skillName: "MongoDB",
                    dataIcon: "simple-icons:mongodb",
                    style: {
                        color: "#47A248",
                    },
                },
            ],
        },
        {
            title: 'CMS Development "WordPress"',
            fileName: "CMSImg",
            skills: [
                "⚡ Making websites using WordPress features like themes and plugins.",
                "⚡ Using plugins to add custom functionality and advanced features.",
                "⚡ Flexibility enough to make different types of websites.",
                "⚡ Ability to split tasks into digestible parts and stick to schedules and deadlines.",
                "⚡ Customize website as needs and transform it into a completely new platform.",
            ],
            softwareSkills: [
                {
                    skillName: "HTML5",
                    dataIcon: "simple-icons:html5",
                    style: {
                        color: "#E34F26",
                    },
                },
                {
                    skillName: "CSS3",
                    dataIcon: "simple-icons:css3",
                    style: {
                        color: "#1572B6",
                    },
                },
                {
                    skillName: "Bootstrap",
                    dataIcon: "simple-icons:bootstrap",
                    style: {
                        color: "#533B78",
                    },
                },
                {
                    skillName: "JavaScript",
                    dataIcon: "simple-icons:javascript",
                    style: {
                        color: "#EFD81D",
                    },
                },
                {
                    skillName: "jQuery",
                    dataIcon: "simple-icons:jquery",
                    style: {
                        color: "#0865A6",
                    },
                },
                {
                    skillName: "React.JS",
                    dataIcon: "simple-icons:react",
                    style: {
                        color: "#61DAFB",
                    },
                },
                {
                    skillName: "Redux",
                    dataIcon: "simple-icons:redux",
                    style: {
                        color: "#7248B6",
                    },
                },
                {
                    skillName: "PHP",
                    dataIcon: "simple-icons:php",
                    style: {
                        color: "#777BB3",
                    },
                },
                {
                    skillName: "WordPress",
                    dataIcon: "simple-icons:wordpress",
                    style: {
                        color: "#21759B",
                    },
                },
                {
                    skillName: "MySQL",
                    dataIcon: "simple-icons:mysql",
                    style: {
                        color: "#4479A1",
                    },
                },
                {
                    skillName: "Apache",
                    dataIcon: "simple-icons:apache",
                    style: {
                        color: "#CA1A22",
                    },
                },
                {
                    skillName: "Elementor",
                    dataIcon: "fa-brands:elementor",
                    style: {
                        color: "#0497ae",
                    },
                },
                {
                    skillName: "WooCommerce",
                    dataIcon: "simple-icons:woo",
                    style: {
                        color: "#7B51AD",
                    },
                },
                {
                    skillName: "Git",
                    dataIcon: "simple-icons:git",
                    style: {
                        color: "#E94E32",
                    },
                },
                {
                    skillName: "GitHub",
                    faClassName: "fab fa-github",
                    style: {
                        backgroundColor: "#fff",
                        borderRadius: "50%",
                        color: "#000",
                    },
                },
            ],
        },
    ],
};

export { greeting, skills, socialMediaLinks }