const experience = {
    title: 'Experience',
    subtitle: 'Work and Internship',
    description:
        "I've completed one internship. I've mostly done projects on my own and I am actively looking for job opportunity. I love organizing workshops to share my knowledge with others.",
    header_image_path: 'experience.svg',
    sections: [
        {
            title: 'Work Experience',
            experiences: [
                {
                    title: 'Front-End Developer',
                    company: 'Genovity',
                    company_url: 'http://genovity.com',
                    logo_path: 'genovity.png',
                    duration: 'Oct 2021 - Present',
                    location: 'Office',
                    description:
                        'Collaborate with other software developers, business analysts, and software architects to deliver, plan, design, develop, test, and maintain web applications. Analyze and resolve technical and application problems. Adhere to high-quality development principles while delivering solutions on time. Translate designs and wireframes into high-quality code.',
                    color: '#0071C5',
                },
                {
                    title: 'ICT Instructor',
                    company: 'Dandara AlEbda3 - TIEC Operator',
                    company_url: 'https://www.facebook.com/Dandara.AlEbda3',
                    logo_path: 'dandara.png',
                    duration: 'Aug 2021 - Nov 2021',
                    location: 'Part Time',
                    description:
                        "Providing instruction in basic ICT skills to students at all levels of education. Planning lessons that correspond to the student's ability level. Facilitating class activities, providing feedback to students about their work and progress in ICT.",
                    color: '#0071C5',
                },
                {
                    title: 'Co-Founder and Software Developer',
                    company: 'A3M',
                    company_url: 'https://a3m-dev.com',
                    logo_path: 'a3m.png',
                    duration: 'Dec 2020 - Sep 2021',
                    location: 'Office',
                    description:
                        'An IT startup company, specialized in developing websites and mobile applications.',
                    color: '#0071C5',
                },
                {
                    title: 'Cross-Platform Mobile Developer',
                    company: 'Upwork',
                    company_url: 'https://www.upwork.com',
                    logo_path: 'upworkfav.svg',
                    duration: 'Jun 2021 - Present',
                    location: 'Freelancer',
                    description:
                        'Experienced Cross-Platform Mobile App Developer. I am mainly focusing on Flutter and React Native. I have experience in the creation of the project, from project planning to publish in the store. My work style and quality save your money and time.',
                    color: '#0071C5',
                },
                {
                    title: 'WordPress Developer',
                    company: 'Upwork',
                    company_url: 'https://www.upwork.com',
                    logo_path: 'upworkfav.svg',
                    duration: 'Dec 2020 - Present',
                    location: 'Freelancer',
                    description:
                        "I am a highly skilled, Experienced WordPress Developer with developing and customizing WordPress themes and plugins. I specialize in developing web sites, Online Stores and web applications with WordPress. I always maintain good communication, deliver quality work in a timely manner, and hence, exceed the customer's expectations",
                    color: '#0071C5',
                },
                {
                    title: 'Front-End Developer',
                    company: 'Upwork',
                    company_url: 'https://www.upwork.com',
                    logo_path: 'upworkfav.svg',
                    duration: 'Jun 2020 - Present',
                    location: 'Freelancer',
                    description:
                        'I am a Frontend UI Developer with extensive experience in building high-quality and fully-responsive sites. I can provide you with a creative website application with an awesome design to increase your customers, sales, and definitely your earnings.',
                    color: '#0071C5',
                },
                {
                    title: 'Front-End Developer (Internship)',
                    company: 'EraaSoft',
                    company_url: 'https://eraasoft.com',
                    logo_path: 'eraa.png',
                    duration: 'Jan 2020 - Mar 2020',
                    location: 'Office',
                    description:
                        'It was a great opportunity that helped me to develop my skills and increase my experience.',
                    color: '#0071C5',
                },
            ],
        },
    ],
};

export default experience;
