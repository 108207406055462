import React from "react";
import { socialMediaLinks } from "../../Models/home";
import "./SocialMedia.css";

export default function socialMedia() {
    return (
        <div className="social-media-div">
            <a
                href={socialMediaLinks.linkedin}
                className="icon-button linkedin"
                target="_blank"
                rel="noopener noreferrer"
            >
                <i className="fab fa-linkedin-in"></i>
                <span></span>
            </a>
            <a
                href={socialMediaLinks.github}
                className="icon-button github"
                target="_blank"
                rel="noopener noreferrer"
            >
                <i className="fab fa-github"></i>
                <span></span>
            </a>
            <a
                href={socialMediaLinks.twitter}
                className="icon-button twitter"
                target="_blank"
                rel="noopener noreferrer"
            >
                <i className="fab fa-twitter"></i>
                <span></span>
            </a>
            <a
                href={socialMediaLinks.facebook}
                className="icon-button linkedin"
                target="_blank"
                rel="noopener noreferrer"
            >
                <i className="fab fa-facebook-f"></i>
                <span></span>
            </a>
        </div>
    );
}
