const prevWorks = {
    title: "Previous Works",
    data: [
        {
            id: "0",
            name: "Shomoos",
            url: "https://www.shomoos-sa.com/",
            img: "shomoos.png",
            description: "Saudi arabian leading information technology company.",
            languages: [
                {
                    skillName: "HTML5",
                    dataIcon: "simple-icons:html5",
                    style: {
                        color: "#E34F26",
                    },
                },
                {
                    skillName: "CSS3",
                    dataIcon: "simple-icons:css3",
                    style: {
                        color: "#1572B6",
                    },
                },
                {
                    skillName: "JavaScript",
                    dataIcon: "simple-icons:javascript",
                    style: {
                        color: "#EFD81D",
                    },
                },
                {
                    skillName: "PHP",
                    dataIcon: "simple-icons:php",
                    style: {
                        color: "#777BB3",
                    },
                },
                {
                    skillName: "WordPress",
                    dataIcon: "simple-icons:wordpress",
                    style: {
                        color: "#21759B",
                    },
                },
                {
                    skillName: "MySQL",
                    dataIcon: "simple-icons:mysql",
                    style: {
                        color: "#4479A1",
                    },
                },
                {
                    skillName: "Elementor",
                    dataIcon: "fa-brands:elementor",
                    style: {
                        color: "#0497ae",
                    },
                },
                {
                    skillName: "Contact Form 7",
                    dataIcon: "fa-brands:wpforms",
                    style: {
                        color: "#33C6F4",
                    },
                },
                {
                    skillName: "Slider Revolution",
                    dataIcon: "fa-solid:sync",
                    style: {
                        color: "#fff",
                        backgroundColor: "#5E35B1",
                        padding: "5px",
                        borderRadius: "10px",
                    },
                },
                {
                    skillName: "TranslatePress",
                    dataIcon: "simple-icons:googletranslate",
                    style: {
                        color: "#EE5D64",
                    },
                },
                {
                    skillName: "WooCommerce",
                    dataIcon: "simple-icons:woo",
                    style: {
                        color: "#7B51AD",
                    },
                },
            ],
        },
        {
            id: "1",
            name: "Policy Bazaar",
            url: "https://www.policybazaar.ae/",
            img: "policybazaar.png",
            description:
                "Policybazaar.com Group holds India's leading insurtech brand and its first overseas fintech venture, Policybazaar.ae.",
            languages: [
                {
                    skillName: "HTML5",
                    dataIcon: "simple-icons:html5",
                    style: {
                        color: "#E34F26",
                    },
                },
                {
                    skillName: "CSS3",
                    dataIcon: "simple-icons:css3",
                    style: {
                        color: "#1572B6",
                    },
                },
                {
                    skillName: "Bootstrap",
                    dataIcon: "simple-icons:bootstrap",
                    style: {
                        color: "#533B78",
                    },
                },
                {
                    skillName: "JavaScript",
                    dataIcon: "simple-icons:javascript",
                    style: {
                        color: "#EFD81D",
                    },
                },
                {
                    skillName: "React.JS",
                    dataIcon: "simple-icons:react",
                    style: {
                        color: "#61DAFB",
                    },
                },
                {
                    skillName: "MongoDB",
                    dataIcon: "simple-icons:mongodb",
                    style: {
                        color: "#439743",
                    },
                },
                {
                    skillName: "Webpack",
                    dataIcon: "logos-webpack",
                    style: {
                        color: "#5f6368",
                    },
                },
                {
                    skillName: "Express.JS",
                    dataIcon: "simple-icons:express",
                    style: {
                        color: "#5f6368",
                    },
                },
                {
                    skillName: "Node.JS",
                    dataIcon: "simple-icons:nodejs",
                    style: {
                        color: "#6AA05C",
                    },
                },
                {
                    skillName: "Google Fonts",
                    dataIcon: "simple-icons:googlefonts",
                    style: {
                        color: "#F3B604",
                    },
                },
            ],
        },
        {
            id: "2",
            name: "T and T",
            url: "https://t-and-t.agency/",
            img: "tandt.png",
            description:
                "Translation company in Egypt offer the most professional, efficient and reliable services.",
            languages: [
                {
                    skillName: "HTML5",
                    dataIcon: "simple-icons:html5",
                    style: {
                        color: "#E34F26",
                    },
                },
                {
                    skillName: "CSS3",
                    dataIcon: "simple-icons:css3",
                    style: {
                        color: "#1572B6",
                    },
                },
                {
                    skillName: "JavaScript",
                    dataIcon: "simple-icons:javascript",
                    style: {
                        color: "#EFD81D",
                    },
                },
                {
                    skillName: "PHP",
                    dataIcon: "simple-icons:php",
                    style: {
                        color: "#777BB3",
                    },
                },
                {
                    skillName: "WordPress",
                    dataIcon: "simple-icons:wordpress",
                    style: {
                        color: "#21759B",
                    },
                },
                {
                    skillName: "MySQL",
                    dataIcon: "simple-icons:mysql",
                    style: {
                        color: "#4479A1",
                    },
                },
                {
                    skillName: "Elementor",
                    dataIcon: "fa-brands:elementor",
                    style: {
                        color: "#0497ae",
                    },
                },
                {
                    skillName: "Contact Form 7",
                    dataIcon: "fa-brands:wpforms",
                    style: {
                        color: "#33C6F4",
                    },
                },
                {
                    skillName: "Slider Revolution",
                    dataIcon: "fa-solid:sync",
                    style: {
                        color: "#fff",
                        backgroundColor: "#5E35B1",
                        padding: "5px",
                        borderRadius: "10px",
                    },
                },

            ],
        },
        {
            id: "3",
            name: "CATS",
            url: "https://cats.id/",
            img: "catslogo.png",
            description:
                "CATS stands for the Certified Arborist Tree Service. A company bearing the CATS Certification has presented proof of their qualifications.",
            languages: [
                {
                    skillName: "HTML5",
                    dataIcon: "simple-icons:html5",
                    style: {
                        color: "#E34F26",
                    },
                },
                {
                    skillName: "CSS3",
                    dataIcon: "simple-icons:css3",
                    style: {
                        color: "#1572B6",
                    },
                },
                {
                    skillName: "JavaScript",
                    dataIcon: "simple-icons:javascript",
                    style: {
                        color: "#EFD81D",
                    },
                },
                {
                    skillName: "jQuery",
                    dataIcon: "simple-icons:jquery",
                    style: {
                        color: "#0865A6",
                    },
                },
                {
                    skillName: "PHP",
                    dataIcon: "simple-icons:php",
                    style: {
                        color: "#777BB3",
                    },
                },
                {
                    skillName: "WordPress",
                    dataIcon: "simple-icons:wordpress",
                    style: {
                        color: "#21759B",
                    },
                },
                {
                    skillName: "MySQL",
                    dataIcon: "simple-icons:mysql",
                    style: {
                        color: "#4479A1",
                    },
                },
                {
                    skillName: "Elementor",
                    dataIcon: "fa-brands:elementor",
                    style: {
                        color: "#0497ae",
                    },
                },
                {
                    skillName: "Contact Form 7",
                    dataIcon: "fa-brands:wpforms",
                    style: {
                        color: "#33C6F4",
                    },
                },
                {
                    skillName: "Yoast",
                    dataIcon: "simple-icons:yoast",
                    style: {
                        color: "#A22768",
                    },
                },
                {
                    skillName: "Stripe",
                    dataIcon: "simple-icons:stripe",
                    style: {
                        color: "#635BFF",
                    },
                },
                {
                    skillName: "Google Fonts",
                    dataIcon: "simple-icons:googlefonts",
                    style: {
                        color: "#F3B604",
                    },
                },
            ],
        },
    ],
};


const projects = {
    data: [
        {

        },
    ],
};


const protfolioHeader = {
    title: "Previous Works & Projects",
    description:
        "My projects make use of a vast variety of latest technology tools. My best experience is to create React Project. Below are some of my projects. Note that not all of the mentioned projects are on GitHub yet.",
    avatar_image_path: "projects_image.svg",
};


export { prevWorks, projects, protfolioHeader };


