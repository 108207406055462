import React from 'react';
import './SoftwareSkill.css';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function SoftwareSkill({ logos }) {
    return (
        <div>
            <div
                className='software-skills-main-div'
                style={{ margin: '0 50px' }}>
                <ul className='dev-icons'>
                    {logos.map(logo => {
                        return (
                            <OverlayTrigger
                                key={logo.skillName}
                                placement={'top'}
                                overlay={
                                    <Tooltip id={`tooltip-top`}>
                                        <strong>{logo.skillName}</strong>
                                    </Tooltip>
                                }>
                                <li
                                    className='software-skill-inline'
                                    name={logo.skillName}>
                                    {logo.faClassName && (
                                        <span>
                                            <i
                                                className={logo.faClassName}
                                                style={logo.style}></i>
                                        </span>
                                    )}
                                    {logo.dataIcon && (
                                        <span
                                            className='iconify'
                                            data-icon={logo.dataIcon}
                                            style={logo.style}
                                            data-inline='false'></span>
                                    )}
                                </li>
                            </OverlayTrigger>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
}

export default SoftwareSkill;
