const contact = {
    contactSection: {
        title: "Contact Me",
        profile_image_path: "mostafa.png",
        description:
            "You can contact me at the places mentioned below. I will try to get back to you as fast as I can. ",
    },
    blogSection: {
        title: "Blogs",
        subtitle:
            "I don't blog frequently but when I do something awesome, I do try to document it so it can be helpful to others. I write on Twitter.",
        link: "https://twitter.com/",
        avatar_image_path: "blogs_image.svg",
    },
};

export default contact;