import React from "react";
import { Timeline } from "rsuite";
import TimelineItem from "rsuite/esm/Timeline/TimelineItem";
import ExperienceCard from "../../components/experienceCard/ExperienceCard.js";
import "./ExperienceTimeline.css";

function ExperienceTimeline({ experience, theme }) {
    return (
        <div className="experience-timeline">
            <Timeline className="custom-timeline">
                {
                    experience["experiences"].map(
                        (item, index) =>
                            <TimelineItem title="">
                                {/* <ExperienceCard key={index} experience={item} theme={theme} /> */}
                                <h1>sdfsdf</h1>
                                <h1>sdfsdf</h1>
                                <h1>sdfsdf</h1>
                            </TimelineItem>
                    )
                }
            </Timeline>
        </div>
    )
}

export default ExperienceTimeline;