import React from "react";
import SoftwareSkill from "../../components/softwareSkills/SoftwareSkill";
import { Fade } from "react-reveal";
import FrontImg from "./FrontImg";
import MobileImg from './MobileImg';
import CMSImg from './CMSImg';
import "./Skills.css";
import { skills } from "../../Models/home";

function SkillSection({ theme }) {
    return (
        <div>
            {
                skills.data.map((skill, index) => {
                    if (index === 0) {
                        return (
                            <div className="skills-main-div" key={index}>
                                <Fade left duration={2000}>
                                    <div className="skills-image-div">
                                        <FrontImg theme={theme} />
                                    </div>
                                </Fade>

                                <div className="skills-text-div" >
                                    <Fade right duration={2000}>
                                        <h1 className="skills-heading" style={{ color: theme.text }}>
                                            {skill.title}
                                        </h1>
                                    </Fade>
                                    <Fade right duration={1500}>
                                        <SoftwareSkill logos={skill.softwareSkills} />
                                    </Fade>
                                    <Fade right duration={2000}>
                                        <div>
                                            {
                                                skill.skills.map((skillSentence, index) => {
                                                    return (
                                                        <p
                                                            className="subTitle skills-text"
                                                            style={{ color: theme.secondaryText }}
                                                            key={index}
                                                        >
                                                            {skillSentence}
                                                        </p>
                                                    );
                                                })
                                            }
                                        </div>
                                    </Fade>
                                </div>
                            </div>
                        );
                    } else if (index === 1) {
                        return (
                            <div className="skills-main-div" key={index}>
                                <div className="skills-text-div">
                                    <Fade left duration={2000}>
                                        <h1 className="skills-heading" style={{ color: theme.text }}>
                                            {skill.title}
                                        </h1>
                                    </Fade>
                                    <Fade left duration={1500}>
                                        <SoftwareSkill logos={skill.softwareSkills} />
                                    </Fade>
                                    <Fade left duration={2000}>
                                        <div>
                                            {skill.skills.map((skillSentence, index) => {
                                                return (
                                                    <p
                                                        className="subTitle skills-text"
                                                        style={{ color: theme.secondaryText }}
                                                        key={index}
                                                    >
                                                        {skillSentence}
                                                    </p>
                                                );
                                            })}
                                        </div>
                                    </Fade>
                                </div>
                                <Fade right duration={2000}>
                                    <div className="skills-image-div">
                                        <MobileImg />
                                    </div>
                                </Fade>
                            </div>
                        );
                    } else {
                        return (
                            <div className="skills-main-div" key={index}>
                                <Fade left duration={2000}>
                                    <div className="skills-image-div">
                                        <CMSImg />
                                    </div>
                                </Fade>

                                <div className="skills-text-div"  >
                                    <Fade right duration={2000}>
                                        <h1 className="skills-heading" style={{ color: theme.text }}>
                                            {skill.title}
                                        </h1>
                                    </Fade>
                                    <Fade right duration={1500}>
                                        <SoftwareSkill logos={skill.softwareSkills} />
                                    </Fade>
                                    <Fade right duration={2000}>
                                        <div  >
                                            {skill.skills.map((skillSentence, index) => {
                                                return (
                                                    <p
                                                        className="subTitle skills-text"
                                                        style={{ color: theme.secondaryText }}
                                                        key={index}
                                                    >
                                                        {skillSentence}
                                                    </p>
                                                );
                                            })}
                                        </div>
                                    </Fade>
                                </div>
                            </div>
                        );
                    }
                })}
        </div>
    );
}

export default SkillSection;
