import React, { useState } from "react";
import Main from "./Main";
import { ThemeProvider } from "styled-components";
import { themes } from "./theme";
import { GlobalStyles } from "./global";
import { CursorProvider } from "react-cursor-custom";
import settings from './settings';
import "./App.css";

function App() {

    const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");
    const useCursor = settings.useCustomCursor;

    return (
        <ThemeProvider theme={themes[theme]}>
            <>
                <GlobalStyles />
                <div>
                    {
                        useCursor
                            ?
                            <CursorProvider
                                color={themes[theme].secondaryText}
                                ringSize={25}
                                transitionTime={75}
                            >
                                <Main theme={themes[theme]} setTheme={setTheme} />
                            </CursorProvider>
                            :
                            <Main theme={themes[theme]} setTheme={setTheme} />
                    }
                </div>
            </>
        </ThemeProvider>
    );
}

export default App;
