import React from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import ExperienceTimeline from '../../containers/experienceTimeline/ExperienceTimeline.js';
import experience from './../../Models/experience';
import { Fade } from 'react-reveal';
import ExperienceImg from './ExperienceImg';
import '../../App.css';
import './Experience.css';
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

function Experience({ theme, setTheme, onToggle }) {
    return (
        <div className='page-main'>
            <Header theme={theme} setTheme={setTheme} />
            <div className='basic-experience'>
                <Fade bottom duration={2000} distance='40px'>
                    <div className='experience-heading-div'>
                        <div className='experience-heading-img-div'>
                            <ExperienceImg theme={theme} />
                        </div>
                        <div className='experience-heading-text-div'>
                            <h1
                                className='experience-heading-text'
                                style={{ color: theme.text }}
                            >
                                {experience.title}
                            </h1>
                            {/* <h3
                                className="experience-heading-sub-text"
                                style={{ color: theme.text }}
                            >
                                {experience["subtitle"]}
                            </h3> */}
                            <p
                                className='experience-header-detail-text subTitle'
                                style={{ color: theme.secondaryText }}
                            >
                                {experience['description']}
                            </p>
                        </div>
                    </div>
                </Fade>
            </div>
            {/* <ExperienceTimeline
                experience={experience['sections'][0]}
                theme={theme}
            /> */}
            <VerticalTimeline lineColor={theme.text}>
                <VerticalTimelineElement
                    className='vertical-timeline-element--work'
                    contentStyle={{
                        background: 'rgb(227, 65, 96)',
                        color: theme.text,
                    }}
                    iconStyle={{
                        background: 'rgb(227, 65, 96)',
                        color: theme.text,
                        borderColor: theme.text,
                    }}
                    icon={<WorkIcon />}
                    date='Oct 2021 - present'
                >
                    <h3 className='vertical-timeline-element-title'>
                        Frontend Developer
                    </h3>
                    <h4 className='vertical-timeline-element-subtitle'>
                        @Genovity
                    </h4>
                    <ul>
                        <li>
                            Collaborate with other software developers, business
                            analysts, and software architects to deliver, plan,
                            design, develop, test, and maintain web
                            applications.
                        </li>
                        <li>
                            Analyze and resolve technical and application
                            problems.
                        </li>
                        <li>
                            Adhere to high-quality development principles while
                            delivering solutions on time.
                        </li>
                        <li>
                            Translate designs and wireframes into high-quality
                            code.
                        </li>
                    </ul>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className='vertical-timeline-element--work'
                    contentStyle={{
                        background: 'rgb(227, 65, 96)',
                        color: theme.text,
                    }}
                    iconStyle={{
                        background: 'rgb(227, 65, 96)',
                        color: theme.text,
                        borderColor: theme.text,
                    }}
                    icon={<WorkIcon />}
                    date='Dec 2021 - present'
                >
                    <h3 className='vertical-timeline-element-title'>
                        Frontend Mentor
                    </h3>
                    <h4 className='vertical-timeline-element-subtitle'>
                        @Coding Coach
                    </h4>
                    <ul>
                        <li>
                            Assist others with less experience or knowledge in
                            frontend technologies.
                        </li>
                        <li>Support and guide them to grow their skills.</li>
                    </ul>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className='vertical-timeline-element--work'
                    contentStyle={{
                        background: 'rgb(227, 65, 96)',
                        color: theme.text,
                    }}
                    iconStyle={{
                        background: 'rgb(227, 65, 96)',
                        color: theme.text,
                        borderColor: theme.text,
                    }}
                    icon={<WorkIcon />}
                    date='Aug 2021 - Nov 2021'
                >
                    <h3 className='vertical-timeline-element-title'>
                        ICT Instructor
                    </h3>
                    <h4 className='vertical-timeline-element-subtitle'>
                        @Dandara Al-Ebdaa
                    </h4>
                    <ul>
                        <li>
                            Providing instruction in basic ICT skills to
                            students at all levels of education.
                        </li>
                        <li>
                            Planning lessons that correspond to the student's
                            ability level.
                        </li>
                        <li>
                            Facilitating class activities, providing feedback to
                            students about their work and progress in ICT.
                        </li>
                    </ul>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className='vertical-timeline-element--work'
                    contentStyle={{
                        background: 'rgb(227, 65, 96)',
                        color: theme.text,
                    }}
                    iconStyle={{
                        background: 'rgb(227, 65, 96)',
                        color: theme.text,
                        borderColor: theme.text,
                    }}
                    icon={<WorkIcon />}
                    date='Dec 2020 - Sep 2021'
                >
                    <h3 className='vertical-timeline-element-title'>
                        Software Developer
                    </h3>
                    <h4 className='vertical-timeline-element-subtitle'>@A3M</h4>
                    <ul>
                        <li>
                            An IT startup company specializing in developing
                            websites and mobile applications.
                        </li>
                    </ul>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className='vertical-timeline-element--work'
                    contentStyle={{
                        background: 'rgb(227, 65, 96)',
                        color: theme.text,
                    }}
                    iconStyle={{
                        background: 'rgb(227, 65, 96)',
                        color: theme.text,
                        borderColor: theme.text,
                    }}
                    icon={<WorkIcon />}
                    date='Apr 2020 - Present'
                >
                    <h3 className='vertical-timeline-element-title'>
                        Software Developer
                    </h3>
                    <h4 className='vertical-timeline-element-subtitle'>
                        @Freelancer on Upwork
                    </h4>
                    <ul>
                        <li>I got a lot of jobs on Upwork platform.</li>
                    </ul>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className='vertical-timeline-element--work'
                    contentStyle={{
                        background: 'rgb(227, 65, 96)',
                        color: theme.text,
                    }}
                    iconStyle={{
                        background: 'rgb(227, 65, 96)',
                        color: theme.text,
                        borderColor: theme.text,
                    }}
                    icon={<WorkIcon />}
                    date='Jan 2020 - Mar 2020'
                >
                    <h3 className='vertical-timeline-element-title'>
                        Frontend Developer (Internship)
                    </h3>
                    <h4 className='vertical-timeline-element-subtitle'>
                        @EraaSoft
                    </h4>
                    <ul>
                        <li>
                            We have trained in HTML, CSS, and JavaScript and
                            created more than one template based on the
                            information and skills that we have gained. It was a
                            great opportunity.
                        </li>
                    </ul>
                </VerticalTimelineElement>
            </VerticalTimeline>
            <Footer theme={theme} onToggle={onToggle} />
        </div>
    );
}

export default Experience;

const WorkIcon = () => {
    return (
        <div
            style={{
                textAlign: 'center',
                marginTop: '12px',
            }}
        >
            <i className='fa fa-briefcase fa-2x'></i>
        </div>
    );
};
