import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { Fade } from "react-reveal";
import ProjectsImg from "./PortfolioImg";
import { style } from "glamor";
import { protfolioHeader, prevWorks } from '../../Models/portfolio';
import "../../App.css";
import "./portfolio.css";

function Portfolio({ theme, setTheme, onToggle }) {

    const styles = style({
        backgroundColor: `${theme.accentBright}`,
        ":hover": {
            boxShadow: `0 5px 15px ${theme.accentBright}`,
        },
    });

    return (
        <div className="page-main">
            <Header theme={theme} setTheme={setTheme} />
            <div className="basic-projects">
                <Fade bottom duration={2000} distance="40px">
                    <div className="projects-heading-div">
                        <div className="projects-heading-img-div">
                            <ProjectsImg theme={theme} />
                        </div>
                        <div className="projects-heading-text-div">
                            <h1
                                className="projects-heading-text"
                                style={{ color: theme.text }}
                            >
                                {protfolioHeader.title}
                            </h1>
                            <p
                                className="projects-header-detail-text subTitle"
                                style={{ color: theme.secondaryText }}
                            >
                                {protfolioHeader["description"]}
                            </p>
                        </div>
                    </div>
                </Fade>
            </div>
            <div style={{ marginTop: "100px" }}>
                <Fade bottom duration={2000} distance="20px">
                    <h1 className="portfolio-header" style={{ color: theme.text }}>
                        {prevWorks.title}
                    </h1>
                </Fade>
            </div>
            <div className="repo-cards-div-main">
                <h3 style={{ textAlign: "center" }}>This section is under maintenance now, will be available soon. You can check my GitHub account till be available.</h3>
                {/* {prevWorks.data.map((repo, index) => <ProjectCard repo={repo} theme={theme} key={index} />)} */}
            </div>
            <div style={{ textAlign: "center" }}>
                <a
                    {...styles}
                    className="general-btn"
                    href="https://github.com/mhsmustafa84"
                >
                    More Projects (Github)
                </a>
            </div>
            <br />
            <br />
            <Footer theme={theme} onToggle={onToggle} />
        </div>
    );
}

export default Portfolio;