import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import "./Splash.css";


export const AnimatedSplash = () => {
    return (
        <div className="logo_wrapper">
            <div className="loading">
                <div class="ball"></div>
                <div class="ball"></div>
                <div class="ball"></div>
                <div class="ball"></div>
                <div class="ball"></div>
                <div class="ball"></div>
                <div class="ball"></div>
            </div>
        </div>
    )
}

export default function Splash(props) {

    const [redirect, setRedirect] = useState(false);
    setTimeout(() => setRedirect(true), 3000);

    return redirect
        ? <Redirect to="/home" />
        : <AnimatedSplash theme={props.theme} />
}