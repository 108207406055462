import React, { useState } from "react";
import { Fade } from "react-reveal";
import { NavLink, Link } from "react-router-dom";
import { CgSun } from "react-icons/cg/";
import { HiMoon } from "react-icons/hi";
import settings from './../../settings';
import { style } from "glamor";
import logo from "../../assests//images/logo.png";
import logo2 from "../../assests//images/logo2.png";
import "./Header.css";

function Header({ theme, setTheme }) {

    const styles = style({
        cursor: "pointer",
        height: "45px",
        width: "45px",
        marginRight: "5px",
        marginLeft: "15px",
        paddingTop: "5px",
        borderRadius: "50%",
        border: "none",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.name === "light" ? "#7CD1F7" : "#292C3F",
        outline: "none",
        transition: "all 2s ease-in-out",
        ":hover": {
            boxShadow: `0 3px 8px ${theme.name === "light" ? "#F7D774" : "#646464"
                }`,
        },
    });

    const link = settings.isSplash ? "/splash" : "home";

    const [currTheme, setCurrTheme] = useState(theme);

    function changeTheme() {
        if (currTheme === "light") {
            setTheme("dark");
            localStorage.setItem("theme", "dark");
            setCurrTheme("dark");
        } else {
            setTheme("light");
            localStorage.setItem("theme", "light");
            setCurrTheme("light");
        }
    }

    const icon =
        theme.name === "dark" ?
            <HiMoon
                strokeWidth={1}
                size={20}
                color={theme.name === "light" ? "#F9D784" : "#A7A7A7"}
            />
            :
            <CgSun
                strokeWidth={1}
                size={20}
                color={theme.name === "light" ? "#F9D784" : "#A7A7A7"}
            />
        ;

    return (
        <Fade top duration={2000} distance="20px">
            <header className="header">
                <NavLink to={link} tag={Link} className="logo" >
                    <img style={{ width: "100%", height: "100%" }} src={theme.name === "light" ? logo2 : logo} alt="logo" />
                </NavLink>
                <input className="menu-btn" type="checkbox" id="menu-btn" />
                <label className="menu-icon" htmlFor="menu-btn">
                    <span className="navicon"></span>
                </label>
                <ul className="menu">
                    <li>
                        <NavLink
                            className="homei"
                            to="/home"
                            tag={Link}
                            activeStyle={{ fontWeight: "bold", boxShadow: " 0 2px 10px #2ab0ee" }}
                            style={{ borderRadius: 5, color: theme.text }}
                        >
                            Home
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className="xp"
                            to="/experience"
                            tag={Link}
                            activeStyle={{ fontWeight: "bold", boxShadow: "0 2px 10px #f7b908" }}
                            style={{ borderRadius: 5, color: theme.text }}
                        >
                            Experience
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className="ec"
                            to="/education"
                            tag={Link}
                            activeStyle={{ fontWeight: "bold", boxShadow: "0 2px 10px #eb6559" }}
                            style={{ borderRadius: 5, color: theme.text }}
                        >
                            Education
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className="projects"
                            to="/projects"
                            tag={Link}
                            activeStyle={{ fontWeight: "bold", boxShadow: "0 2px 10px #e44160" }}
                            style={{ borderRadius: 5, color: theme.text }}
                        >
                            Portfolio
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className="cr"
                            to="/contact"
                            tag={Link}
                            activeStyle={{ fontWeight: "bold", boxShadow: "0 1px 10px #47a148" }}
                            style={{ borderRadius: 5, color: theme.text }}
                        >
                            Contact
                        </NavLink>
                    </li>
                    <button {...styles} onClick={changeTheme}>
                        {icon}
                    </button>
                </ul>
            </header>
        </Fade>
    );
}

export default Header;
