import React from "react";
import { useHistory } from "react-router-dom";
import { Fade } from "react-reveal";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import { greeting } from "../../Models/home";
import GreetingImg from "./GreetingImg";
import { style } from "glamor";
import "./Greeting.css";

export default function Greeting({ theme }) {
	const history = useHistory();
	const styles = style({
		backgroundColor: `${theme.accentBright}`,
		":hover": {
			boxShadow: `0 5px 15px ${theme.accentBright}`,
		},
	});

	return (
		<Fade bottom duration={2000} distance="40px">
			<div className="greet-main" id="greeting">
				<div className="greeting-main">
					<div className="greeting-text-div">
						<div>
							<h1 className="greeting-text">
								{greeting.title}
								<span className="greeting-icon">{greeting.icon}</span>
							</h1>
							<p
								className="greeting-text-p subTitle"
								style={{ color: theme.secondaryText }}
							>
								<span>I'm </span>
								<span style={{ color: theme.accentColor }}>
									{greeting.full_name}.{" "}
								</span>
								{greeting.subTitle}
							</p>
							<SocialMedia />
							<div className="portfolio-repo-btn-div">
								<button
									{...styles}
									className="button"
									onClick={() => {
										history.push("/contact");
									}}
								>
									Contact Me
								</button>
							</div>
						</div>
					</div>
					<div className="greeting-image-div">
						<GreetingImg theme={theme} />
					</div>
				</div>
			</div>
		</Fade >
	);
}
