import React from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import SocialMedia from '../../components/socialMedia/SocialMedia';
import { Fade } from 'react-reveal';
import contact from './../../Models/contact';
import { greeting } from '../../Models/home';
// import BlogsImg from "./BlogsImg";
import { style } from 'glamor';
import '../../App.css';
import './ContactComponent.css';

const ContactData = contact.contactSection;
// const blogSection = contact.blogSection;

const Contact = ({ theme, setTheme, onToggle }) => {
    const styles = style({
        backgroundColor: `${theme.accentBright}`,
        ':hover': {
            boxShadow: `0 5px 15px ${theme.accentBright}`,
        },
    });

    return (
        <div className='page-main'>
            <Header theme={theme} setTheme={setTheme} />
            <div className='basic-contact'>
                <Fade bottom duration={2000} distance='40px'>
                    <div className='contact-heading-div'>
                        <div className='contact-heading-img-div'>
                            <img
                                className='profile-pic'
                                src={require(`../../assests/images/${ContactData['profile_image_path']}`)}
                                alt=''
                            />
                        </div>
                        <div className='contact-heading-text-div'>
                            <h1
                                className='contact-heading-text'
                                style={{ color: theme.text }}>
                                {ContactData['title']}
                            </h1>
                            <p
                                className='contact-header-detail-text subTitle'
                                style={{ color: theme.secondaryText }}>
                                {ContactData['description']}
                            </p>
                            <SocialMedia />
                            <br />
                            <br />
                            <a
                                {...styles}
                                className='general-btn'
                                rel='noreferrer'
                                target='_blank'
                                href={greeting.resumeLink}>
                                See my CV
                            </a>
                        </div>
                    </div>
                </Fade>
                {/* <Fade bottom duration={2000} distance="40px">
          <div className="blog-heading-div">
            <div className="blog-heading-text-div">
              <h1 className="blog-heading-text" style={{ color: theme.text }}>
                {blogSection["title"]}
              </h1>
              <p
                className="blog-header-detail-text subTitle"
                style={{ color: theme.secondaryText }}
              >
                {blogSection["subtitle"]}
              </p>
              <div className="blogsite-btn-div">
                <a {...styles} className="general-btn" href={blogSection.link}>
                  My Twitter Profile
                </a>
              </div>
            </div>
            <div className="blog-heading-img-div">
              <BlogsImg theme={theme} />
            </div>
          </div>
        </Fade> */}
            </div>
            <Footer theme={theme} onToggle={onToggle} />
        </div>
    );
};

export default Contact;
