import React from "react";
import { Fade } from "react-reveal";
import "../../App.css";

export default function Admin({ theme, setTheme }) {
    return (
        <Fade duration={2000} distance="40px">
            <div className="page-main">
                <ul>
                    <li>a</li>
                    <li>a</li>
                    <li>a</li>
                    <li>a</li>
                    <li>a</li>
                    <li>a</li>
                    <li>a</li>
                </ul>
            </div>
        </Fade>
    );
}
